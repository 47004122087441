import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import type { Book } from '../data/books';
import { allBooks, BookStatus } from '../data/books';
import { getUid } from '../services/authentication';

import { setBookCache } from './use-book';
import queryClient from '../query-client';

const cacheKey = (uid: string): string[] => {
  return ['books', 'all', uid];
};

export default function useBooks(): UseQueryResult<Book[], Error> {
  const uid = getUid();
  return useQuery(cacheKey(uid), () => allBooks(uid), {});
}

export function useBooksGrouped(): {
  refetch: () => void;
  allBooks: Book[];
  booksByStatus: { [s in BookStatus]: Book[] };
  isLoading: boolean;
  error: Error | null;
} {
  const { data: books, refetch, isLoading, error } = useBooks();
  const result = {
    refetch,
    isLoading,
    error,
    allBooks: books || [],
    booksByStatus: {
      [BookStatus.Abandoned]: [] as Book[],
      [BookStatus.Finished]: [] as Book[],
      [BookStatus.Reading]: [] as Book[],
      [BookStatus.Shelved]: [] as Book[],
      [BookStatus.Unread]: [] as Book[],

      // Legacy (used to be Wish List)
      // Remove sometime in the future
      want: [] as Book[],
    },
  };

  for (const book of books || []) {
    result.booksByStatus[book.status].push(book);
  }

  return result;
}

export function setBooksCache(books: Book[]): void {
  const uid = getUid();
  queryClient.setQueryData(cacheKey(uid), books);
  books.map(setBookCache); // Update singles too
}

export async function invalidateBooksCache(): Promise<void> {
  const uid = getUid();
  await queryClient.invalidateQueries(cacheKey(uid), { exact: true });
}
