import type { UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

import { deleteBookList } from '../data/book-lists';
import queryClient from '../query-client';

export default function useDeleteBookListMutation(id: string): UseMutationResult<void, Error, void, void> {
  return useMutation<void, Error, void, void>(async () => {
    await deleteBookList(id);

    // Need to invalidate cache when deleting last book since react-query
    // does not handle overwriting cache entries with empty list. Will fail
    // to update after deleting the last book. Note, we're only going to
    // invalidate the list query (exact = true), not the individual books.
    queryClient.invalidateQueries(['book_lists'], { exact: true }).catch(console.error);
  });
}
