import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
  isPlatform,
} from '@ionic/react';

import { chevronBack } from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import { Carousel } from '../components/general/Carousel';
import type { CarouselRef } from '../components/general/Carousel';

import Apple from '../components/icons/Apple';
import Email from '../components/icons/Email';
import Google from '../components/icons/Google';
import Person from '../components/icons/Person';
import { AnalyticsEvent, logEvent } from '../services/analytics';
import { loginAnonymously, signUpWithApple, signUpWithGoogle } from '../services/authentication';
import { URLs } from '../urls';
import './WelcomePage.scss';

const WelcomePage: React.FC = () => {
  const [clickedSignIn, setClickedSignIn] = useState<boolean>(false);
  const [showSkipAlert, setShowSkipAlert] = useState<boolean>(false);
  const sliderRef = useRef<CarouselRef>(null);

  const handleSignIn = () => {
    setClickedSignIn(true);
    sliderRef.current?.scrollNext();
  };

  const handleSignUp = () => {
    setClickedSignIn(false);
    sliderRef.current?.scrollNext();
  };

  return (
    <IonPage id="welcome-page">
      <IonAlert
        header="Skip account setup?"
        subHeader="Create an account later to ensure you never lose your reading lists"
        isOpen={showSkipAlert}
        onDidDismiss={() => setShowSkipAlert(false)}
        buttons={[
          { text: 'Cancel', role: 'cancel' },
          {
            text: 'Skip',
            handler: async () => {
              await loginAnonymously();
              logEvent(AnalyticsEvent.AuthSkipSetup);
              setTimeout(() => {
                window.location.assign(URLs.root());
              }, 1000);
            },
          },
        ]}
      />
      <IonContent fullscreen={false}>
        <div className="img-wrapper">
          <Person />
        </div>
        <div className="ion-margin ion-text-center">
          <h1>
            Welcome{' '}
            <span role="img" aria-label="waving hand">
              👋
            </span>
          </h1>
          <p>Keep track of the books in your past, present, and future.</p>
        </div>
      </IonContent>

      <IonFooter className="ion-padding-horizontal ion-no-border">
        <Carousel ref={sliderRef}>
          <div>
            <IonButton expand="block" className="ion-margin-bottom" onClick={() => handleSignUp()}>
              Create Account
            </IonButton>
            <IonGrid className="ion-no-padding">
              <IonRow>
                <IonCol>
                  <IonButton fill="outline" expand="block" onClick={() => handleSignIn()}>
                    Sign In
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton fill="clear" expand="block" onClick={async () => setShowSkipAlert(true)}>
                    Skip
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <div>
            {!(isPlatform('android') && isPlatform('capacitor')) && (
              <IonButton
                expand="block"
                color="dark"
                className="btn-apple ion-margin-bottom"
                onClick={async () => {
                  if (await signUpWithApple()) {
                    logEvent(AnalyticsEvent.AuthLogin, { provider: 'apple' });
                    window.location.replace(URLs.root());
                  }
                }}
              >
                <div slot="start">
                  <Apple />
                </div>
                <span>{clickedSignIn ? 'Sign in' : 'Sign up'} with Apple</span>
              </IonButton>
            )}
            <IonButton
              expand="block"
              className="btn-google ion-margin-bottom"
              onClick={async () => {
                if (await signUpWithGoogle()) {
                  logEvent(AnalyticsEvent.AuthLogin, { provider: 'google' });
                  window.location.replace(URLs.root());
                }
              }}
            >
              <div slot="start">
                <Google />
              </div>
              <span>{clickedSignIn ? 'Sign in' : 'Sign up'} with Google</span>
            </IonButton>
            {clickedSignIn ? (
              <IonButton
                className="btn-email ion-margin-bottom"
                color="danger"
                expand="block"
                routerLink={URLs.login()}
              >
                <div slot="start">
                  <Email />
                </div>
                <span>Sign in with Email</span>
              </IonButton>
            ) : (
              <IonButton
                className="btn-email ion-margin-bottom"
                color="danger"
                expand="block"
                routerLink={URLs.signup()}
              >
                <div slot="start">
                  <Email />
                </div>
                <span>Sign up with Email</span>
              </IonButton>
            )}
            <IonButton className="btn-back" fill="clear" color="dark" onClick={() => sliderRef.current?.scrollPrev()}>
              <IonIcon icon={chevronBack} /> Go Back
            </IonButton>
          </div>
        </Carousel>
      </IonFooter>
    </IonPage>
  );
};

export default WelcomePage;
