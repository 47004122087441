import { IonBackButton, IonButton, IonButtons, IonContent, IonPage, IonSpinner } from '@ionic/react';
import type { SyntheticEvent } from 'react';
import React, { useState } from 'react';

import Header from '../components/ui/Header';
import { URLs } from '../urls';
import './GoodReadsImportPage.scss';
import useIdToken from '../hooks/use-id-token';

const GoodReadsImportPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [fileSelected, setFileSelected] = useState<boolean>(false);
  const [successText, setSuccessText] = useState<string>('');
  const { data: idToken } = useIdToken();
  const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const body = new FormData(e.currentTarget);
      const importUrl = 'https://us-central1-readrally.cloudfunctions.net/importGoodReads';
      const resp = await fetch(importUrl, { method: 'POST', body: body });
      const { message } = await resp.json();
      setSuccessText(message);
    } catch (err) {
      console.log('Error importing from goodreads', err);
    }
    setLoading(false);
  };

  const handleFileChange = (e: SyntheticEvent<HTMLInputElement>) => {
    setFileSelected(!!e.currentTarget.files?.length);
  };

  return (
    <IonPage id="goodreads-import-page">
      <Header
        screenName="GoodreadsImport"
        title="Goodreads Import"
        startSlot={
          <IonButtons slot="start">
            <IonBackButton defaultHref={URLs.root()} />
          </IonButtons>
        }
      />
      <IonContent fullscreen={false}>
        <p className="ion-margin ion-padding-bottom">
          Export your Goodreads data by navigating to{' '}
          <a href="https://www.goodreads.com/review/import" target="_blank" rel="noopener noreferrer">
            <strong>My Books → Import and export</strong>
          </a>
          .
        </p>
        <form onSubmit={handleSubmit} className="ion-margin">
          <h2>Import from Goodreads</h2>
          <input
            id="csv-file"
            type="file"
            name="file"
            onChange={handleFileChange}
            accept="text/csv"
            className="ion-margin-vertical"
          />
          <input type="hidden" name="token" value={idToken || 'n/a'} />
          {fileSelected && (
            <IonButton type="submit" disabled={loading} color="tertiary" expand="block" className="ion-margin-top">
              {loading ? (
                <>
                  Importing <IonSpinner name="lines-small" />
                </>
              ) : (
                'Import Books'
              )}
            </IonButton>
          )}
        </form>
        <div className="ion-margin">
          {successText && (
            <>
              <p>{successText}</p>
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default GoodReadsImportPage;
