export default class Debouncer {
  private readonly millis: number;
  private callback: () => any;
  private _timeout?: NodeJS.Timeout;

  constructor(millis = 300, callback?: () => any) {
    this.millis = millis;
    this.callback = callback || (() => null);
  }

  call(): void {
    if (this._timeout) {
      clearTimeout(this._timeout);
    }

    this._timeout = setTimeout(this.callback, this.millis);
  }

  callWith(callback: () => any): void {
    this.callback = callback;
    this.call();
  }
}
