import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonPage,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/react';
import React from 'react';
import './AllBooksPage.scss';
import { useHistory, useParams } from 'react-router';

import AddDropdown from '../components/ui/AddDropdown';
import BookCollection from '../components/ui/BookCollection';
import Header from '../components/ui/Header';
import useBooks from '../hooks/use-books';
import { TabRoot, tabRootName, URLs } from '../urls';
import minimumTime from '../util/minimum-time';

interface Props {
  router: HTMLIonRouterOutletElement | null;
}

const AllBooksPage: React.FC<Props> = ({ router }) => {
  const params = useParams<{ tab: TabRoot }>();
  const history = useHistory();
  const { data: allBooks, isLoading, refetch, error } = useBooks();

  const refresh = async (e: CustomEvent) => {
    await minimumTime(() => {
      refetch();
    }, 1000);
    e.detail.complete();
  };

  return (
    <IonPage id="book-page">
      <Header
        screenName="AllBooks"
        title="All Books"
        startSlot={
          <IonButtons slot="start">
            <IonBackButton defaultHref={URLs.tabLibrary()} text={tabRootName(params.tab)} />
          </IonButtons>
        }
        endSlot={<AddDropdown showBookItems router={router} />}
      />
      {isLoading && <IonProgressBar type="indeterminate" slot="fixed" />}

      <IonContent fullscreen={false}>
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent />
        </IonRefresher>

        {error && <p>Error: {error.message}</p>}

        {allBooks && (
          <BookCollection
            deletable
            books={allBooks}
            onClick={(book) => history.push(URLs.booksDetails(TabRoot.Library, book.id))}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default AllBooksPage;
