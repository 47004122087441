import useCustomerInfo from './use-customer-info';
import useIsDev from './use-is-dev';

export default function useCanAccessPremium(): boolean {
  const isDev = useIsDev();
  const info = useCustomerInfo();

  if (isDev) {
    return true;
  }

  if (!info) {
    return false;
  }

  return info?.isActive;
}
