import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonNote,
  IonPage,
  IonProgressBar,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonViewWillLeave,
} from '@ionic/react';
import type { FormEvent } from 'react';
import React, { useMemo, useRef, useState } from 'react';

import './SearchModal.scss';

import BookListItem from '../components/ui/BookListItem';
import type { Book } from '../data/books';
import { SearchFilter } from '../data/search';
import useBooks from '../hooks/use-books';
import useKeyboard from '../hooks/use-keyboard';
import useSearchBooks from '../hooks/use-search-books';
import { AnalyticsEvent, logEvent } from '../services/analytics';
import SearchBookDetailsModal from './SearchBookDetailsModal';

let debounceTimeout: NodeJS.Timeout;
const DEBOUNCE_MILLIS = isPlatform('mobile') ? 1200 : 700; // Mobile type slower

interface Props {
  /** Change this value to force the search bar to gain focus */
  router: HTMLIonRouterOutletElement | null;
  isOpen: boolean;
  onDismiss: () => void;
}

const SearchModal: React.FC<Props> = ({ router, onDismiss, isOpen }) => {
  const didKeyboardOpen = useKeyboard();
  const inputRef: React.Ref<HTMLIonSearchbarElement> = useRef(null);

  // Focus input when focus key changes
  const focusInput = () => {
    if (!inputRef.current) return;
    inputRef.current.setFocus().catch(console.error);
  };

  const { data: allBooks } = useBooks();
  const [showBook, setShowBook] = useState<Book | null>(null);
  const [filter, setFilter] = useState<SearchFilter>(SearchFilter.All);
  const [terms, setTerms] = useState<string>('');
  const { isError, error, isLoading, data: booksFromSearch } = useSearchBooks(terms, filter);

  const performSearch = (text: string) => {
    setTerms(text);
    logEvent(AnalyticsEvent.BooksSearch, { q: text, filter });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    clearTimeout(debounceTimeout);
    performSearch(inputRef.current ? inputRef.current.value ?? '' : '');
    await maybeBlurInput();
  };

  useIonViewWillLeave(() => {
    clearTimeout(debounceTimeout);
  });

  // Debounce a search when text changes
  const handleSearchTextChange = (e: CustomEvent) => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(
      () => {
        performSearch(e.detail.value);
      },
      e.detail.value ? DEBOUNCE_MILLIS : 0 // Exec empty search instantly
    );
  };

  const clickUnownedBook = async (book: Book) => {
    await maybeBlurInput();
    setShowBook(book);
  };

  const maybeBlurInput = async () => {
    const el = await inputRef.current?.getInputElement();
    if (didKeyboardOpen && el instanceof HTMLInputElement) {
      // Only blur (close keyboard) if a software keyboard was detected
      el.blur();
    }
  };

  const ownedBookGids: Record<string, boolean> = useMemo(() => {
    const ownedIds: Record<string, boolean> = {};
    for (const b of allBooks ?? []) {
      ownedIds[b.gid] = true;
    }
    return ownedIds;
  }, [allBooks?.length ?? 0]);

  const pageRef = useRef<HTMLElement>(null);
  return (
    <IonModal
      isOpen={isOpen}
      presentingElement={router ?? undefined}
      onDidDismiss={onDismiss} // Called when swiped away
      onDidPresent={() => {
        // HACK: Add delay for android or the keyboard closes right after focus for some reason
        if (isPlatform('android')) setTimeout(focusInput, 500);
        else focusInput();
      }}
    >
      <IonPage ref={pageRef}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Add Book</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={onDismiss}>Done</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen scrollEvents onIonScrollStart={() => maybeBlurInput()}>
          {isLoading && <IonProgressBar type="indeterminate" slot="fixed" />}
          <form onSubmit={handleSubmit}>
            <div className="ion-margin-horizontal ion-margin-top">
              <IonSearchbar
                ref={inputRef}
                placeholder="Title, author, or ISBN"
                onIonChange={handleSearchTextChange}
                enterkeyhint="search"
                className="ion-no-padding"
                debounce={0}
              />
            </div>

            {!isLoading && (
              <div className="ion-padding-horizontal ion-padding-bottom">
                <IonSegment value={filter} onIonChange={(e) => setFilter(e.detail.value as SearchFilter)}>
                  <IonSegmentButton value={SearchFilter.All}>
                    <IonLabel>Flexible</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value={SearchFilter.Title}>
                    <IonLabel>Title</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value={SearchFilter.Author}>
                    <IonLabel>Author</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
              </div>
            )}
          </form>

          {isError && <p className="ion-padding">Error: {error?.message}</p>}
          {terms && !isLoading && booksFromSearch && booksFromSearch.length === 0 && (
            <IonItem lines="none">
              <IonNote>Nothing found for "{terms}"</IonNote>
            </IonItem>
          )}

          {!isLoading && !terms && !booksFromSearch?.length && (
            <div className="ion-text-center ion-margin">
              <IonNote className="ion-margin">Search for a book to add to your library</IonNote>
            </div>
          )}

          {!isLoading && booksFromSearch && booksFromSearch.length > 0 && (
            <>
              <IonList onScroll={() => maybeBlurInput()}>
                {booksFromSearch.map((b) => (
                  <BookListItem
                    hideStatus
                    showAddButton={!ownedBookGids[b.gid]}
                    disabled={ownedBookGids[b.gid]}
                    key={b.gid}
                    book={b}
                    size="small"
                    onInnerClick={clickUnownedBook}
                  />
                ))}
              </IonList>
            </>
          )}
        </IonContent>

        <SearchBookDetailsModal
          book={showBook}
          onDismiss={() => setShowBook(null)}
          isOpen={!!showBook}
          presentingElement={pageRef.current ?? undefined}
        />
      </IonPage>
    </IonModal>
  );
};

export default SearchModal;
