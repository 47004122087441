import type { ReactNode } from 'react';
import React from 'react';

interface State {
  error: null | Error;
}

interface Props {
  children: ReactNode,
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: any): State {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error: Error): void {
    this.setState({ error });
  }

  render(): ReactNode {
    if (this.state.error) {
      // You can render any custom fallback UI
      return (
        <div className="ion-margin">
          <h1>Something went wrong.</h1>
          <pre>
            {this.state.error?.stack}
          </pre>
        </div>
      );
    }

    return this.props.children;
  }
}


export default ErrorBoundary;
