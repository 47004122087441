import type { UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

import { deleteBook } from '../data/books';
import { invalidateBooksCache } from './use-books';

export default function useDeleteBookMutation(id: string): UseMutationResult<void, Error, void, void> {
  return useMutation(
    async () => {
      await deleteBook(id);
    },
    {
      onMutate: async () => {
        // Need to invalidate cache when deleting last book since react-query
        // does not handle overwriting cache entries with empty list. Will fail
        // to update after deleting the last book. Note, we're only going to
        // invalidate the list query (exact = true), not the individual books.
        await invalidateBooksCache();
      },
    }
  );
}
