import { Preferences } from '@capacitor/preferences';

export enum SharedPrefKey {
  SettingSystemDarkMode = 'settings/SystemDarkMode',
  SettingDarkMode = 'settings/DarkMode',
  SettingSendUsage = 'settings/Analytics',
  BooksStatusFilters = 'books/StatusFilters',
  BooksViewMode = 'books/ViewMode',
  BooksOrderBy = 'books/OrderBy',
}

const _cache: Record<string, any> = {};

export async function preloadPrefs(): Promise<void> {
  for (const key of Object.values(SharedPrefKey)) {
    _cache[key] = await getPref(key, undefined);
  }
}

export async function setPref<T>(key: SharedPrefKey, value: T): Promise<void> {
  console.log('[prefs] Set', key, value);
  _cache[key] = value;
  await Preferences.set({ key, value: JSON.stringify(value) });
}

export async function getPref<T>(key: SharedPrefKey, fallback: T): Promise<T> {
  const start = Date.now();
  if (_cache[key] !== undefined) {
    console.log('[prefs] Get', key, '(hit!)', Date.now() - start + 'ms');
    return _cache[key] as any;
  }

  console.log('[prefs] Get', key, fallback, Date.now() - start + 'ms');
  const { value } = await Preferences.get({ key });

  // If we didn't find value, store it so that it'll be quick to fetch from the
  // cache next time (Android storage is super slow);
  if (typeof value !== 'string' || value === 'undefined') {
    setTimeout(() => setPref(key, fallback), 0);
    return fallback;
  }

  try {
    return JSON.parse(value) as T;
  } catch (err) {
    console.log(`[prefs] Failed to parse value for ${key}: "${value}"`);
    return fallback;
  }
}
