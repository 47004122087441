import { useEffect, useState } from 'react';

/**
 * Set to true if the software keyboard is opened
 */
export default function useKeyboard(): boolean {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('ionKeyboardDidShow', () => {
      setIsOpen(true);
    });

    // This triggers false-positive when swiping text on iOS, otherwise
    // we could make this function simply reflect the visibility of the
    // keyboard.

    // window.addEventListener('ionKeyboardDidHide', (e) => {
    //   console.log('[keyboard] Close', e);
    //   setIsOpen(false);
    // });
  }, []);

  // Return false by default
  return isOpen;
}
