import { URLs } from '../urls';

import {
  IonBackButton,
  IonButton,
  IonContent,
  IonFooter,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSpinner,
  IonText,
} from '@ionic/react';
import type { SyntheticEvent } from 'react';
import React, { useState } from 'react';
import './SignUpPage.scss';

import Header from '../components/ui/Header';
import { AnalyticsEvent, logEvent } from '../services/analytics';
import { signUp } from '../services/authentication';
import minimumTime from '../util/minimum-time';

const SignUpPage: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const isFormValid = password && passwordConfirm && email;

  const handleFormSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMessage('');

    if (passwordConfirm !== password) {
      setErrorMessage('Passwords did not match');
      return;
    }

    let message;
    setIsSubmitting(true);
    await minimumTime(async () => {
      message = await signUp(email, password);
    }, 1000);

    setIsSubmitting(false);
    if (message) {
      setErrorMessage(message);
    } else {
      logEvent(AnalyticsEvent.AuthSignup, { provider: 'password' });
      window.location.replace(URLs.tabReading());
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <IonPage id="signup-page">
        <Header screenName="Signup" startSlot={<IonBackButton defaultHref={URLs.welcome()} text="Welcome" />} />
        <IonContent fullscreen={false}>
          <div className="ion-margin ion-padding-vertical">
            <h1>Let's get started</h1>
            <IonText color="medium">Create a Kabook! account to take your reading lists wherever you go.</IonText>
          </div>

          {errorMessage && (
            <div className="ion-margin">
              <IonText color="danger">{errorMessage}</IonText>
            </div>
          )}

          <IonList className="ion-padding-horizontal">
            <IonItem className="ion-no-padding ion-margin-vertical">
              <IonLabel position="stacked">Email Address</IonLabel>
              <IonInput
                required
                placeholder="email@example.com"
                type="email"
                value={email}
                onIonChange={(e) => setEmail(e.detail.value ?? email)}
              />
            </IonItem>

            <IonItem className="ion-no-padding ion-margin-vertical">
              <IonLabel position="stacked">Password</IonLabel>
              <IonInput
                minlength={6}
                type="password"
                placeholder="••••••••••••"
                required
                value={password}
                onIonChange={(e) => setPassword(e.detail.value ?? password)}
              />
            </IonItem>

            <IonItem className="ion-no-padding ion-margin-vertical">
              <IonLabel position="floating">Confirm Password</IonLabel>
              <IonInput
                type="password"
                placeholder="••••••••••••"
                required
                value={passwordConfirm}
                onIonChange={(e) => setPasswordConfirm(e.detail.value ?? passwordConfirm)}
              />
            </IonItem>
            <div className="ion-hide">
              <input type="submit" />
            </div>
          </IonList>
        </IonContent>
        <IonFooter translucent={false} className="ion-padding-horizontal ion-no-border">
          <IonButton expand="block" type="submit" disabled={!isFormValid || isSubmitting}>
            {isSubmitting ? <IonSpinner /> : 'Create Account'}
          </IonButton>
        </IonFooter>
      </IonPage>
    </form>
  );
};

export default SignUpPage;
