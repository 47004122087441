import { useEffect, useState } from 'react';
import isDev from '../util/is-dev';

export default function useIsDev(): boolean | null {
  const [dev, setDev] = useState<boolean | null>(null);
  useEffect(() => {
    isDev().then(setDev);
  }, []);
  return dev;
}
