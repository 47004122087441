import type { ReactNode } from 'react';
import React from 'react';
import './DevOnly.scss';
import useIsDev from '../../hooks/use-is-dev';

interface DevOnlyProps {
  render?: () => ReactNode;
  children?: ReactNode;
}

const DevOnly: React.FC<DevOnlyProps> = ({ children, render }) => {
  const isDev = useIsDev();

  // Loading
  if (isDev === null) {
    return null;
  }

  // Not dev
  if (isDev === false) {
    return null;
  }

  if (render) {
    return <span className="dev-only">{render()}</span>;
  }

  return <span className="dev-only">{children}</span>;
};

export default DevOnly;
