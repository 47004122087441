import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { Preferences } from '@capacitor/preferences';
import { subscribeToBookLists } from './data/book-lists';
import { allBooks, subscribeToBooks } from './data/books';
import { subscribeToUserChange } from './data/users';
import { setBookListsCache } from './hooks/use-book-lists';
import { setBooksCache } from './hooks/use-books';
import * as serviceWorker from './serviceWorker';
import { disableAnalytics, initAnalytics, setUserId } from './services/analytics';
import initAppRate from './services/app-rate';
import { getUid, initAuth, isAuthenticated } from './services/authentication';
import { initBilling, setBillingUserId } from './services/billing';
import { initDarkMode } from './util/dark-mode';
import reportWebVitals from './reportWebVitals';
import queryClient from './query-client';
import { QueryClientProvider } from 'react-query';
import ErrorBoundary from './components/general/ErrorBoundary';
import { preloadPrefs } from './util/shared-prefs';
import './index.scss';

console.log('[index] Initializing app');

(async () => {
  await Preferences.migrate(); // Can remove in a few releases
  await initDarkMode();
  await initAuth();
  await initAnalytics();
  await initBilling();
  await preloadPrefs();

  console.log('[index] Initialization complete');

  // Subscribe to updated books and prefetch for cache
  let unsubscribeFromBooks: (() => void) | null = null;
  let unsubscribeFromBookLists: (() => void) | null = null;
  await subscribeToUserChange(async (user) => {
    if (user?.admin) {
      await disableAnalytics('no-track for admin');
    }

    if (user) {
      await setUserId(user.id);
      setBillingUserId(user.id);
      console.log('[index] Subscribing to models for', user.id);
      unsubscribeFromBooks = await subscribeToBooks(user.id, setBooksCache);
      unsubscribeFromBookLists = await subscribeToBookLists(user.id, setBookListsCache);
    } else {
      setBillingUserId(null);
      console.log('[index] Unsubscribing to models');
      unsubscribeFromBooks?.();
      unsubscribeFromBookLists?.();
    }
  });

  if (isAuthenticated()) {
    setBooksCache(await allBooks(getUid()));
  }

  console.log('[index] Rendering app');
  const el = document.getElementById('root');
  if (el === null) throw new Error('Failed to find root element');
  const root = createRoot(el);
  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </ErrorBoundary>
    </React.StrictMode>
  );

  // Prompt user to rate the app
  setTimeout(async () => {
    await initAppRate();
  }, 2000);

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals(() => null);
})().catch((err) => console.log('[index] Failed to initialize', err));
