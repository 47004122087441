import React from 'react';

import './BookStatusPicker.scss';
import type { Book} from '../../data/books';
import { BookStatus, statusColor, statusLabel, updateBook } from '../../data/books';
import { AnalyticsEvent, logEvent } from '../../services/analytics';

import ButtonSelect from './ButtonSelect';

import { formatDistanceToNow } from 'date-fns';

interface BookStatusPickerProps {
  book: Book;
  expand?: 'block',
  size?: 'large' | 'default' | 'small',
}

export const BookStatusPicker: React.FC<BookStatusPickerProps> = ({ book, expand, size }) => {
  const color = statusColor(book.status);

  const updateStatus = async (status: BookStatus) => {
    logEvent(AnalyticsEvent.BooksChangeStatus, { status });
    await updateBook(book.id, { status });
  };

  return (
    <ButtonSelect
      closeOnSelect
      expand={expand}
      color={color}
      size={size}
      header="Change Status"
      options={Object.values(BookStatus).map(s => ({
        label: statusLabel(s),
        value: s,
        checked: book.status === s,
        name: 'status',
        type: 'radio',
        handler: () => updateStatus(s),
      }))}
    >
      {statusLabel(book.status)}
      {book.status === BookStatus.Finished && book.finishedAt && (
        ` ${formatDistanceToNow(book.finishedAt.toDate())} ago`
      )}
    </ButtonSelect>
  );
};

export default BookStatusPicker;
