import React from 'react';

export const CheckMark: React.FC = () => (
  <svg width="100%" height="100%" viewBox="0 0 12 12" version="1.1">
    <circle cx="6" cy="6" r="6" className="fill-bg"/>
    <path className="fill-fg" d="M9.022,3.728C8.917,3.622 8.774,3.563 8.625,3.563C8.469,3.563 8.319,3.629 8.212,3.744L5.608,7.063L4.038,5.492C3.934,5.395 3.797,5.341 3.655,5.341C3.346,5.341 3.092,5.595 3.092,5.904C3.092,6.046 3.146,6.183 3.243,6.287L5.228,8.272C5.333,8.378 5.476,8.437 5.625,8.437C5.781,8.437 5.93,8.372 6.037,8.258L9.031,4.515C9.242,4.296 9.238,3.943 9.023,3.727L9.022,3.728Z"/>
  </svg>
);

export default CheckMark;
