import type { Unsubscribe, User as FirebaseUser } from 'firebase/auth';
import {
  browserLocalPersistence,
  browserPopupRedirectResolver,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getRedirectResult,
  GoogleAuthProvider,
  indexedDBLocalPersistence,
  initializeAuth,
  linkWithCredential,
  OAuthProvider,
  onAuthStateChanged as _onAuthStateChanged,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signOut,
} from 'firebase/auth';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple';
import { isPlatform } from '@ionic/react';

import { getUser } from '../data/users';
import { URLs } from '../urls';

import { firebaseApp } from './firebase';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { clearFirestoreCache } from './firestore';

export const firebaseAuth = initializeAuth(firebaseApp(), {
  popupRedirectResolver: isPlatform('capacitor') ? undefined : browserPopupRedirectResolver,
  persistence: [indexedDBLocalPersistence, browserLocalPersistence, browserSessionPersistence],
});

export async function initAuth(): Promise<void> {
  console.log('[auth] Initializing auth');

  // Check redirect result on web
  if (!isPlatform('capacitor')) {
    const redirectResult = await getRedirectResult(firebaseAuth);
    if (redirectResult?.user) {
      console.log('[auth] Detected auth redirect result');
      window.location.assign(URLs.root());
    }
  }

  return new Promise((resolve) => {
    console.log('[auth] Waiting for Firebase user...');
    const unsub = onAuthStateChanged(async (user: FirebaseUser | null) => {
      console.log('[auth] Initialized with user', user?.uid);
      resolve();
      unsub();
    });
  });
}

export function onAuthStateChanged(callback: (user: FirebaseUser | null) => Promise<void>): Unsubscribe {
  return _onAuthStateChanged(firebaseAuth, callback, (error) => {
    console.log('[auth] Error monitoring state change', error);
  });
}

export function getUid(): string {
  const firebaseUser = getFirebaseUser();
  if (!firebaseUser) {
    throw new Error('User not authenticated');
  }
  return firebaseUser.uid;
}

export function getIdToken(): Promise<string> {
  const user = getFirebaseUser();
  if (!user) {
    return Promise.resolve('');
  }

  return user.getIdToken();
}

export function currentFirebaseUser(): FirebaseUser | null {
  return firebaseAuth.currentUser;
}

export function isAuthenticated(): boolean {
  const user = currentFirebaseUser();
  return user != null && !user.isAnonymous;
}

export function getUserDisplayName(): string {
  const user = currentFirebaseUser();
  if (!user) return '';
  return user.displayName || '';
}

export function isUserAnonymous(): boolean {
  const user = currentFirebaseUser();
  if (!user) return true;
  return user.isAnonymous;
}

export async function logUserOut(): Promise<void> {
  console.log('[auth] Signed out');
  await firebaseAuth.signOut();

  // IMPORTANT! Must clear cache after signing out or weird hanging-forever
  // queries will ensue after.
  await clearFirestoreCache();
}

export async function signUpWithGoogle(): Promise<boolean> {
  if (isPlatform('capacitor')) return _signUpGoogleNative();
  else return _signUpGoogleWeb();
}

export async function signUpWithApple(): Promise<boolean> {
  if (isPlatform('capacitor') && !isPlatform('android')) return _signUpAppleNativeIOS();
  else if (isPlatform('capacitor') && isPlatform('android')) return _signUpAppleNativeAndroid();
  else return _signUpAppleWeb();
}

export function getFirebaseUser(): FirebaseUser | null {
  const user = currentFirebaseUser();
  if (!user) {
    // For now, don't allow anonymous users. Redirect to signup
    const isLogin = window.location.pathname === URLs.login();
    const isSignup = window.location.pathname === URLs.signup();
    const isWelcome = window.location.pathname === URLs.welcome();
    const isForgot = window.location.pathname === URLs.forgotPassword();
    if (!(isLogin || isSignup || isWelcome || isForgot)) {
      window.location.assign(URLs.welcome());
    }

    // const r = await firebase.auth().signInAnonymously();
    // return r.user as firebase.User; // Should always exist
    return null;
  }

  return user;
}

export async function resetPassword(email: string): Promise<null | string> {
  await sendPasswordResetEmail(firebaseAuth, email);
  return null;
}

export async function loginAnonymously(): Promise<void> {
  await signInAnonymously(firebaseAuth);
}

export async function login(email: string, password: string): Promise<null | string> {
  const firebaseUser = currentFirebaseUser();
  if (firebaseUser?.isAnonymous) {
    await firebaseUser.delete();
  }

  try {
    const result = await signInWithEmailAndPassword(firebaseAuth, email, password);
    console.log('[auth] Login succeeded', result);
    return null;
  } catch (err) {
    console.log('[auth] Login error', err);
    if ((err as any).code === 'auth/user-not-found') {
      return 'Username or password incorrect';
    } else if ((err as any).code === 'auth/wrong-password') {
      return 'Username or password incorrect';
    }
    return 'An unknown error occurred';
  }
}

export async function _signUpGoogleNative(): Promise<boolean> {
  try {
    const googleUser = await GoogleAuth.signIn();
    const credential = GoogleAuthProvider.credential(googleUser.authentication.idToken);
    await signInWithCredential(firebaseAuth, credential);
    return true;
  } catch (err) {
    console.log('[auth] Failed Google', err);
    return false;
  }
}

export async function _signUpGoogleWeb(): Promise<boolean> {
  const provider = new GoogleAuthProvider();
  provider.addScope('profile');
  provider.addScope('email');
  provider.setCustomParameters({ prompt: 'select_account' }); // Allow selecting account
  await signInWithRedirect(firebaseAuth, provider);
  return true;
}

export async function _signUpAppleNativeAndroid(): Promise<boolean> {
  const provider = new OAuthProvider('apple.com');
  provider.addScope('email');
  provider.addScope('name');
  await signInWithRedirect(firebaseAuth, provider);
  return true;
}

export async function _signUpAppleNativeIOS(): Promise<boolean> {
  try {
    const result = await SignInWithApple.signin({
      requestedScopes: [0, 1], // email, name
    });
    const provider = new OAuthProvider('apple.com');
    const credential = provider.credential({ idToken: result.identityToken });
    await signInWithCredential(firebaseAuth, credential);
    return true;
  } catch (err) {
    console.log('[auth] Failed Google', err);
    return false;
  }
}

export async function _signUpAppleWeb(): Promise<boolean> {
  const provider = new OAuthProvider('apple.com');
  await signInWithRedirect(firebaseAuth, provider);
  return true;
}

export async function signUp(email: string, password: string): Promise<null | string> {
  let firebaseUser = currentFirebaseUser();

  // If user is not anonymous, sign out first
  if (firebaseUser && !firebaseUser.isAnonymous) {
    await signOut(firebaseAuth);
    firebaseUser = null;
  }

  try {
    let result;
    if (firebaseUser) {
      console.log('[auth] Upgrading anonymous user', firebaseUser);
      const credential = EmailAuthProvider.credential(email, password);
      result = await linkWithCredential(firebaseUser, credential);
    } else {
      // Should never actually get here if we're creating anonymous users correctly
      console.log('[auth] Signup up new user');
      result = await createUserWithEmailAndPassword(firebaseAuth, email, password);
    }
    console.log('[auth] Signup succeeded', result);

    // kind of a hack to trigger the user data to be updated accordingly
    await getUser();

    return null;
  } catch (err) {
    console.log('[auth] Signup error', err);
    if ((err as any).code === 'auth/user-not-found') {
      return 'Username or password incorrect';
    } else if ((err as any).code === 'auth/wrong-password') {
      return 'Username or password incorrect';
    } else if ((err as any).code === 'auth/email-already-in-use') {
      return 'Email already in use';
    }
    return 'An unknown error occurred';
  }
}
