/**
 * Call a function and delay if it finishes too early
 *
 * @param handler - function to call to do the work
 * @param millis - minimum time to delay for
 */
export default async function minimumTime(handler: () => void | Promise<void>, millis: number): Promise<void> {
  const start = Date.now();

  // Call the function to do work
  await handler();

  // Make it feel like something more substantial is happening
  const elapsed = Date.now() - start;
  const delayFor = Math.max(0, millis - elapsed);
  await new Promise((resolve) => setTimeout(resolve, delayFor));
}
