import { AppRate, AppRateReviewTypeAndroid, AppRateReviewTypeIos } from '@ionic-native/app-rate';
import { InAppBrowser } from '@ionic-native/in-app-browser';

import { AnalyticsEvent, logEvent } from './analytics';
import { isAuthenticated } from './authentication';

export default async function initAppRate(): Promise<void> {
  // Only try prompting for rating if authenticated
  if (!isAuthenticated()) {
    console.log('[rate] Skipping because not authenticated');
    return;
  }

  console.log('[rate] Initializing rating plugin');
  AppRate.preferences = {
    usesUntilPrompt: 3,
    promptAgainForEachNewVersion: false,
    showPromptForInAppReview: true,
    reviewType: {
      ios: AppRateReviewTypeIos.InAppReview,
      android: AppRateReviewTypeAndroid.InAppReview,
    },
    storeAppURL: {
      ios: '1541265058',
      android: 'market://details?id=co.schier.readrally',
    },
    callbacks: {
      handleNegativeFeedback: () => {
        logEvent(AnalyticsEvent.RateNegative);
        _actuallyOpenUrl(
          'mailto:greg+kabook@schier.co' +
            `?subject=${encodeURIComponent('Kabook Question/Comment')}` +
            `&body=${encodeURIComponent('Hi Greg,\n\n')}`
        );
      },
      onButtonClicked: (index: number) => {
        logEvent(AnalyticsEvent.RateButton, { index });
      },
      onRateDialogShow: () => {
        logEvent(AnalyticsEvent.RateShow);
      },
    },
    displayAppName: 'Kabook!', // Needed for customLocale templating
    customLocale: {
      title: 'Would you mind rating %@?',
      message: 'It only takes a few taps and really helps!',
      cancelButtonLabel: 'No Thanks',
      laterButtonLabel: 'Remind Me Later',
      rateButtonLabel: 'Rate It Now',
      yesButtonLabel: 'Yes',
      noButtonLabel: 'No',
      appRatePromptTitle: 'Enjoying %@?',
      feedbackPromptTitle: 'Would you like to help by explaining why?',
    },
    openUrl: (url: string) => {
      console.log('[rate] Opening URL', url);
      _actuallyOpenUrl(url);
    },
  };

  AppRate.promptForRating(false);
}

function _actuallyOpenUrl(url: string): void {
  InAppBrowser.create(url, '_system', {
    location: 'yes',
  });
}
