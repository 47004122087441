import { IonBackButton, IonContent, IonPage } from '@ionic/react';
import type { FC } from 'react';
import React from 'react';
import './BookCreatePage.scss';
import { useHistory } from 'react-router';

import EditBookForm from '../components/ui/EditBookForm';
import Header from '../components/ui/Header';
import { newBook } from '../data/books';
import { TabRoot, URLs } from '../urls';

interface Props {
  router: HTMLIonRouterOutletElement | null;
}

const BookCreatePage: FC<Props> = ({ router }) => {
  const history = useHistory();
  const book = newBook({});
  return (
    <IonPage id="create-book-page">
      <Header
        screenName="CreateBook"
        title="Create Book"
        startSlot={<IonBackButton defaultHref={URLs.tabLibrary()} />}
      />
      <IonContent fullscreen={false}>
        <EditBookForm router={router} book={book} onFinish={(id) => history.replace(URLs.booksDetails(TabRoot.Library, id))} />
      </IonContent>
    </IonPage>
  );
};

export default BookCreatePage;
