import type {
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  QueryConstraint,
  QuerySnapshot,
  Unsubscribe,
  UpdateData,
  WithFieldValue,
} from 'firebase/firestore';
import {
  addDoc as _addDoc,
  CACHE_SIZE_UNLIMITED,
  collection as _collection,
  deleteDoc as _deleteDoc,
  doc as _doc,
  getDoc as _getDoc,
  getDocs as _getDocs,
  initializeFirestore,
  onSnapshot,
  query,
  setDoc as _setDoc,
  Timestamp,
  updateDoc as _updateDoc,
} from 'firebase/firestore';

import type { BaseModel } from '../data/models';

import { firebaseApp } from './firebase';

export const firestore = initializeFirestore(firebaseApp(), {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
});

// NOTE: This seems to cause problems when switching users in the app,
//  and I couldn't get it to work reliably. Queries just hang sometimes
//  on app launch.
// enableMultiTabIndexedDbPersistence(firestore).catch((err) => {
//   console.log('[firestore] Failed to enable persistence', err);
// });

export function collection(name: string): CollectionReference {
  return _collection(firestore, name);
}

export async function clearFirestoreCache(): Promise<void> {
  // NOTE: Disabled until I figure out how to do persistence above
  // console.log('[firestore] Clearing persistence');
  // await terminate(firestore);
  // await clearIndexedDbPersistence(firestore);
}

export function deleteDoc(collection: CollectionReference, path: string): Promise<void> {
  return _deleteDoc(_doc(collection, path));
}

export function getDoc<T>(
  collection: CollectionReference<T>,
  path?: string
): {
  doc: DocumentReference<T>;
  get: () => Promise<DocumentSnapshot<T>>;
  set: (data: WithFieldValue<T>) => Promise<void>;
  update: (patch: UpdateData<T>) => Promise<void>;
  delete: () => Promise<void>;
} {
  // For some reason, passing `undefined` here doesn't work
  const d = path ? _doc(collection, path) : _doc(collection);
  return {
    doc: d,
    get: () => _getDoc<T>(d),
    set: (data: WithFieldValue<T>) => _setDoc<T>(d, data),
    update: (patch: UpdateData<T>) => _updateDoc<T>(d, patch),
    delete: () => _deleteDoc(d),
  };
}

export function newDoc<T>(collection: CollectionReference<T>): DocumentReference<T> {
  return _doc(collection);
}

export function setDoc<T>(doc: DocumentReference<T>, data: WithFieldValue<T>): Promise<void> {
  return _setDoc<T>(doc, data);
}

export function addDoc<T>(collection: CollectionReference<T>, data: WithFieldValue<T>): Promise<DocumentReference<T>> {
  return _addDoc<T>(collection, data);
}

export function findDocs(
  collection: CollectionReference,
  ...queryConstraints: QueryConstraint[]
): Promise<QuerySnapshot> {
  return _getDocs(query(collection, ...queryConstraints));
}

export function subscribeDocs(
  collection: CollectionReference,
  observer: (snapshot: QuerySnapshot) => void,
  ...queryConstraints: QueryConstraint[]
): Unsubscribe {
  return onSnapshot(query(collection, ...queryConstraints), observer);
}

export function restoreFirestoreTimestamps<T extends BaseModel>(doc: T): T {
  if (!doc) {
    return doc;
  }

  for (const key of Object.keys(doc)) {
    const value = (doc as any)[key];
    if (value && typeof value.seconds === 'number' && typeof value.nanoseconds === 'number' && !value.toDate) {
      (doc as any)[key] = new Timestamp(value.seconds, value.nanoseconds);
    }
  }
  return doc;
}
