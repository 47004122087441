export default async function cropImg(
  dataUri: string,
  x: number,
  y: number,
  width: number,
  height: number
): Promise<string> {
  return new Promise((resolve) => {
    const img = document.createElement('img');

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (ctx === null) {
        throw new Error('Failed to initialize image crop 2D context');
      }

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, -x, -y);
      const dataURI = canvas.toDataURL();

      resolve(dataURI);
    };

    img.src = dataUri;
  });
}
