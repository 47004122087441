import type { BookStatus } from './data/books';

export enum TabRoot {
  Reading = 'reading',
  Library = 'library',
  Settings = 'settings',
  Lists = 'lists',
  None = 'x',
}

export function tabRootName(tab: TabRoot): string {
  switch (tab) {
    case TabRoot.Reading:
      return 'Reading';
    case TabRoot.Lists:
      return 'Lists';
    case TabRoot.Library:
      return 'Library';
    case TabRoot.Settings:
      return 'Settings';
    default:
      return '';
  }
}

export const URLs = {
  // Root pages
  root: (): string => '/',
  tabs: (): string => '/main',

  // Tab pages
  tabReading: (): string => `${URLs.tabs()}/${TabRoot.Reading}`,
  tabLibrary: (): string => `${URLs.tabs()}/${TabRoot.Library}`,
  tabSettings: (): string => `${URLs.tabs()}/${TabRoot.Settings}`,
  tabLists: (): string => `${URLs.tabs()}/${TabRoot.Lists}`,

  // Book pages
  booksAll: (tab: TabRoot | ':tab'): string => `${URLs.tabs()}/${tab}/books/any`,
  booksByStatus: (tab: TabRoot | ':tab', status: BookStatus | ':status'): string =>
    `${URLs.tabs()}/${tab}/books/status/${status || ''}`,
  booksByList: (tab: TabRoot | ':tab', list: string | ':listId'): string =>
    `${URLs.tabs()}/${tab}/books/list/${list || ''}`,
  booksDetails: (tab: TabRoot | ':tab', id: string): string => `${URLs.tabs()}/${tab}/book/${id}`,
  booksCreate: (): string => `/books/new`,
  booksEdit: (id: string): string => `/books/edit/${id}`,

  // Auth pages
  welcome: (): string => '/welcome',
  login: (): string => '/auth/login',
  signup: (): string => '/auth/signup',
  forgotPassword: (): string => '/auth/forgot-password',

  // Billing pages
  subscribe: (): string => '/billing/subscribe',

  // Other Pages
  importFromGoodReads: (): string => '/import/goodreads',
};
