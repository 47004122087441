import { useIonViewDidEnter } from '@ionic/react';
import { useState } from 'react';

import type { SharedPrefKey } from '../util/shared-prefs';
import { getPref, setPref } from '../util/shared-prefs';

export default function useSharedPref<T>(
  pref: SharedPrefKey,
  initial: T,
  setDelayMillis = 0
): [T, (v: T) => void, boolean] {
  const [v, setV] = useState<T>(initial);
  const [loading, setLoading] = useState<boolean>(true);

  const setter = (newV: T) => {
    if (newV === v) {
      return;
    }

    setPref(pref, newV).catch((err) => console.log('[useSharedPref] Failed', err));
    setTimeout(() => setV(newV), setDelayMillis);
  };

  // Refetch if the view entered
  useIonViewDidEnter(() => {
    getPref(pref, v).then((v) => {
      setV(v);
      setLoading(false);
    });
  }, [pref]);

  return [v, setter, loading];
}
