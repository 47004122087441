import React from 'react';

import type { Book } from '../../data/books';

import BookCover from './BookCover';

import './BookCoverStack.scss';
import classnames from 'classnames';

interface BookCoverStackProps {
  books: Book[],
  max?: number,
  slot?: string,
  size?: 'small',
}

const BookCoverStack: React.FC<BookCoverStackProps> = ({ books, slot, max, size }) => {
  max = max || 3;

  const booksWithBlanks = [ ...books.slice(0, max) ];
  for (let i = 0; i < max; i++) {
    booksWithBlanks.push({ id: i + '' } as Book);
  }

  return (
    <div className={classnames('book-cover-stack', { small: size === 'small' })}>
      {booksWithBlanks.slice(0, max).map(b => (
        <BookCover key={b.id} book={b} slot={slot} hideStatusLabel/>
      ))}
    </div>
  );
};

export default BookCoverStack;
