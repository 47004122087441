import type { FirebaseApp } from 'firebase/app';
import { initializeApp } from 'firebase/app';

export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyB-hlT6semluf4v1miNm1QDhCGLorlScV0',
  authDomain: 'readrally.firebaseapp.com',
  databaseURL: 'https://readrally.firebaseio.com',
  projectId: 'readrally',
  storageBucket: 'readrally.appspot.com',
  messagingSenderId: '721660915949',
  appId: '1:721660915949:web:0af05baab133c634799ce4',
  measurementId: 'G-B9JVVCWY6G',
};

let _app: FirebaseApp;
export const firebaseApp = (): FirebaseApp =>{
  if (!_app) {
    _app = initializeApp(FIREBASE_CONFIG);
    // setLogLevel("debug");
  }
  return _app;
}
