import { IonNote } from '@ionic/react';
import React from 'react';

import './BookShowcase.scss';
import type { Book } from '../../data/books';
import { truncateText } from '../../util/truncate-text';

import BookCover from './BookCover';
import BookStatusPicker from './BookStatusPicker';

import classnames from 'classnames';

interface BookShowcaseProps {
  book: Book;
  onClick: (book: Book) => any;
  button?: boolean,
  className?: string,
  buttonSlot?: React.ReactNode,
}

export const BookShowcase: React.FC<BookShowcaseProps> = ({ button, buttonSlot, className, book, onClick }) => {
  return (
    <div className={classnames('book-showcase', className, { button: button })} onClick={() => onClick(book)}>
      <div className="grid">
        <div className="book-cover-container">
          <BookCover hideStatusLabel book={book}/>
        </div>
        <div className="details">
          <h2 className="title">{truncateText(book.title, 48)}</h2>
          <IonNote className="authors">{book.authors.join(', ')}</IonNote>
          <div className="tags">
            {buttonSlot || <BookStatusPicker book={book} size="small" expand="block"/>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookShowcase;
