import { Directory, Filesystem } from '@capacitor/filesystem';
import type { HttpDownloadFileOptions, HttpDownloadFileResult } from '@capacitor-community/http';
import { Http } from '@capacitor-community/http';
import { useEffect, useState } from 'react';
import md5 from 'md5';
import { Capacitor } from '@capacitor/core';

export default function useCachedImageDataUri(src: string): string | null {
  // ----------------------------------------------------------- //
  // Disabled for now until we have offline support for data too //
  // ----------------------------------------------------------- //
  console.log('[imgcach] PERMANENTLY DISABLED');

  const isDisabled = true; // !isPlatform('capacitor');

  const [imgSrc, setImgSrc] = useState<string | null>(src);

  if (!src) {
    return null;
  }

  useEffect(() => {
    (async () => {
      if (isDisabled) {
        setImgSrc(src);
        return;
      }

      const directory = Directory.Cache;
      const path = `images:${md5(src)}`;

      const uriResult = await Filesystem.getUri({ directory, path });
      const cachedUri = Capacitor.convertFileSrc(uriResult.uri);

      const cacheHit = await fileExists({ directory, path });
      if (cacheHit) {
        setImgSrc(cachedUri);
        return;
      }

      const downloaded = await downloadFile({ directory, path, src });
      if (!downloaded) {
        setImgSrc(src);
        return;
      }

      setImgSrc(cachedUri);
    })();
  }, [src]);

  return imgSrc;
}

async function downloadFile({
  src,
  path,
  directory,
}: {
  src: string;
  path: string;
  directory: Directory;
}): Promise<boolean> {
  try {
    const options: HttpDownloadFileOptions = {
      url: src,
      filePath: path,
      fileDirectory: directory,
      method: 'GET',
    };

    // Writes to local filesystem
    const response: HttpDownloadFileResult = await Http.downloadFile(options);
    return !!response.path;
  } catch (err) {
    console.log('[imgcache] Failed to download file for image cache', err);
    return false;
  }
}

async function fileExists({ directory, path }: { directory: Directory; path: string }): Promise<boolean> {
  try {
    await Filesystem.stat({ directory, path });
    return true;
  } catch (err) {
    return false;
  }
}
