import type { QuerySnapshot } from 'firebase/firestore';
import { arrayRemove, arrayUnion, where, Timestamp } from 'firebase/firestore';

import { getUid } from '../services/authentication';
import { collection, findDocs, restoreFirestoreTimestamps, getDoc, subscribeDocs } from '../services/firestore';

import type { Book } from './books';
import { newBook } from './books';
import type { BaseModel } from './models';

const _firestoreCollection = () => collection('book_lists');
const _firestoreNewBookList = () => getDoc(_firestoreCollection());
const _firestoreGetBookList = (id: string) => getDoc(_firestoreCollection(), id);
const _firestoreAllBookLists = (uid: string) => findDocs(_firestoreCollection(), where('uid', '==', uid));
const _firestoreSubAllBookLists = (uid: string, fn: (snapshot: QuerySnapshot) => void) =>
  subscribeDocs(_firestoreCollection(), fn, where('uid', '==', uid));

type SchemaVersion = 4;

export enum BookListIcon {
  Book = 'book',
  List = 'list',
}

export enum BookListColor {
  Default = '__default__',
}

export interface BookList extends BaseModel {
  v: SchemaVersion;
  uid: string;
  name: string;
  description: string;
  icon: BookListIcon;
  color: BookListColor;
  filter: {
    bookIds?: string[];
  };
}

export async function getBookList(id: string): Promise<BookList> {
  switch (id) {
    case 'all':
      return _newBookList('all', {
        name: 'All Books',
        filter: {
          bookIds: undefined, // No filter at all
        },
      });
  }

  const doc = await _firestoreGetBookList(id).get();
  if (!doc.exists()) {
    throw new Error(`BookList does not exist id=${id}`);
  }

  return _migrateBookList(doc.data() as BookList);
}

export async function allBookLists(uid: string): Promise<BookList[]> {
  const results = await _firestoreAllBookLists(uid);
  return results.docs.map((d) => _migrateBookList(d.data() as BookList));
}

export async function countBookLists(uid: string): Promise<number> {
  const result = await _firestoreAllBookLists(uid);
  return result.size;
}

export async function subscribeToBookLists(
  uid: string,
  callback: (bookLists: BookList[]) => void
): Promise<() => void> {
  let initialResults = true;
  return new Promise((resolve) => {
    const unsubscribe = _firestoreSubAllBookLists(uid, async (snapshot) => {
      console.log('[booklists] Snapshot change');

      callback(snapshot.docs.map((d) => _migrateBookList(d.data() as BookList)));

      if (initialResults) {
        resolve(unsubscribe);
        initialResults = false;
      }
    });
  });
}

export async function createBookList(patch: Partial<BookList>): Promise<BookList> {
  const ref = _firestoreNewBookList();
  const list = _newBookList(ref.doc.id, patch);
  await ref.set(list);
  console.log('[booklists] Created', list.id);
  return list;
}

export async function deleteBookList(id: string): Promise<void> {
  console.log('[booklists] Delete', id);
  await _firestoreGetBookList(id).delete();
}

export async function updateBookList(id: string, patch: { 'filter.bookIds': string[] }): Promise<void> {
  console.log('[booklists] Update', id);
  await _firestoreGetBookList(id).update(patch);
}

export async function toggleBookOnBookListFilter(id: string, bookId: string, enabled: boolean): Promise<void> {
  const fieldValue = enabled ? arrayUnion(bookId) : arrayRemove(bookId);
  await _firestoreGetBookList(id).update({ 'filter.bookIds': fieldValue });
  console.log('[booklists] Toggle book', id, bookId, enabled);
}

async function _replaceBookList(bl: BookList): Promise<void> {
  console.log('[booklists] Replace', bl.id);
  await _firestoreGetBookList(bl.id).set(bl);
}

export function _migrateBookList(bl: BookList, dirty = false): BookList {
  const schemaVersion = (bl.v || 1) as number;
  if (schemaVersion === 1) {
    // This one never existed
  } else if (schemaVersion === 2) {
    return _migrateBookList({ ...bl, v: 3 as SchemaVersion, filter: { bookIds: [] } }, true);
  } else if (schemaVersion === 3) {
    return _migrateBookList({ ...bl, v: 4, description: '' }, true);
  }

  if (dirty) {
    // Save migration (in background so it doesn't block anything)
    console.log('[booklists] Updating after migration', bl.id, '-->', bl.v);
    _replaceBookList(bl).catch((err) => console.log('[booklists] Migrate failed to save', err));
  }

  return restoreFirestoreTimestamps(bl);
}

function _newBookList(id: string, patch: Partial<BookList>): BookList {
  return {
    color: BookListColor.Default,
    icon: BookListIcon.List,
    name: 'My Book List',
    description: '',
    filter: { bookIds: [] },

    // User-specified properties
    ...patch,

    // Unmodifiable properties
    uid: getUid(),
    v: 4,
    id: id,
    createdAt: Timestamp.now(),
    modifiedAt: Timestamp.now(),
  };
}

export function dummyBookLists(): { list: BookList; books: Book[] }[] {
  const books = [
    // School books
    _dummyBook(
      'dummy-1',
      'https://books.google.com/books/content?id=hFfhrCWiLSMC&printsec=frontcover&img=1&zoom=1&edge=none&source=gbs_api'
    ),
    _dummyBook(
      'dummy-2',
      'https://books.google.com/books/content?id=YZRqAAAAQBAJ&printsec=frontcover&img=1&zoom=1&edge=none&source=gbs_api'
    ),
    _dummyBook(
      'dummy-3',
      'https://books.google.com/books/content?id=PGR2AwAAQBAJ&printsec=frontcover&img=1&zoom=1&edge=none&source=gbs_api'
    ),

    // Business books
    _dummyBook(
      'dummy-4',
      'https://books.google.com/books/content?id=xQ1_z5_kj6sC&printsec=frontcover&img=1&zoom=1&edge=none&source=gbs_api'
    ),
    _dummyBook(
      'dummy-5',
      'https://books.google.com/books/content?id=r9x-OXdzpPcC&printsec=frontcover&img=1&zoom=1&edge=none&source=gbs_api'
    ),
    _dummyBook(
      'dummy-6',
      'https://books.google.com/books/content?id=U77um_h_dgcC&printsec=frontcover&img=1&zoom=1&edge=none&source=gbs_api'
    ),
  ];

  return [
    {
      list: _newBookList('dummy-1', {
        name: 'School Books',
        filter: {
          bookIds: [
            books[0].id,
            books[1].id,
            books[2].id,
            books[3].id,
            books[4].id,
            books[5].id,
            books[5].id,
            books[5].id,
            books[5].id,
            books[5].id,
            books[5].id,
            books[5].id,
            books[5].id,
            books[5].id,
          ],
        },
      }),
      books: [
        books[0],
        books[1],
        books[2],
        books[3],
        books[4],
        books[5],
        books[5],
        books[5],
        books[5],
        books[5],
        books[5],
        books[5],
        books[5],
        books[5],
      ],
    },
    {
      list: _newBookList('dummy-2', {
        name: 'Business',
        filter: {
          bookIds: [books[3].id, books[4].id, books[5].id, books[0].id, books[1].id],
        },
      }),
      books: [books[3], books[4], books[5], books[0], books[1]],
    },
  ];
}

function _dummyBook(id: string, image: string): Book {
  return newBook({
    id,
    image,
  });
}
