import {
  IonActionSheet,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

import './SearchBookDetailsModal.scss';
import BookShowcase from '../components/ui/BookShowcase';
import type { Book } from '../data/books';
import { BookStatus, createBook, statusLabel } from '../data/books';

import React, { useState } from 'react';

import { AnalyticsEvent, logEvent } from '../services/analytics';
import BookDetailsTable from '../components/general/BookDetailsTable';

interface Props {
  book: Book | null;
  onDismiss: () => void;
  isOpen: boolean;
  presentingElement?: HTMLElement;
}

const SearchBookDetailsModal: React.FC<Props> = ({ book, onDismiss, isOpen, presentingElement }) => {
  const [actionSheetOpen, setActionSheetOpen] = useState<boolean>(false);

  const addBook = async (status: BookStatus) => {
    if (!book) return;

    onDismiss();
    await createBook({ ...book, status });
    logEvent(AnalyticsEvent.BooksAdd, { title: book.title, status });
  };

  return (
    <IonModal isOpen={isOpen} presentingElement={presentingElement} onDidDismiss={onDismiss}>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Add Book</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={onDismiss}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen={false}>
          <IonActionSheet
            isOpen={actionSheetOpen}
            onDidDismiss={() => setActionSheetOpen(false)}
            header="Reading Status"
            buttons={[
              ...Object.values(BookStatus).map((s) => ({
                text: statusLabel(s),
                handler: () => addBook(s),
              })),
              { text: 'Cancel', role: 'cancel' },
            ]}
          />
          {book && (
            <>
              <BookShowcase
                book={book}
                onClick={() => null}
                className="ion-margin-vertical ion-padding-vertical"
                buttonSlot={<IonButton onClick={() => setActionSheetOpen(true)}>Add Book</IonButton>}
              />
              <div className="ion-margin">
                <h2>Details</h2>
                <BookDetailsTable book={book} />

                <h2>Description</h2>
                <p>{book.description || 'No description'}</p>
              </div>
            </>
          )}
        </IonContent>
      </IonPage>
    </IonModal>
  );
};

export default SearchBookDetailsModal;
