import { IonAlert, IonChip } from '@ionic/react';
import React, { useState } from 'react';

import './BookListPicker.scss';
import type { BookList} from '../../data/book-lists';
import { toggleBookOnBookListFilter } from '../../data/book-lists';
import type { Book } from '../../data/books';
import useBookLists from '../../hooks/use-book-lists';
import { AnalyticsEvent, logEvent } from '../../services/analytics';

interface BookListPickerProps {
  book: Book;
}

export const BookListPicker: React.FC<BookListPickerProps> = ({ book }) => {
  const { data: allBookLists } = useBookLists();
  const bookLists = allBookLists
    ? allBookLists.filter((bl: BookList) => bl.filter.bookIds?.includes(book.id))
    : [];
  const [showAlert, setShowAlert] = useState<boolean>(false);

  return (
    <>
      {bookLists.map((bl: BookList) => (
        <IonChip color="primary" key={bl.id}>
          {bl.name}
        </IonChip>
      ))}
      <IonChip outline color="dark" onClick={() => setShowAlert(true)}>
        Choose Lists
      </IonChip>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        cssClass="book-list-picker"
        header="Select Lists"
        inputs={allBookLists?.map((bl: BookList) => ({
          name: 'booklists',
          type: 'checkbox',
          label: bl.name,
          value: bl,
          checked: bookLists.includes(bl),
        }))}
        buttons={[
          { text: 'Cancel', role: 'cancel' },
          {
            text: 'Save',
            handler: async (enabled: BookList[]) => {
              for (const bl of [...bookLists, ...enabled]) {
                const inEnabled = enabled.find((v: BookList) => v.id === bl.id);
                const inExisting = bookLists.find((v: BookList) => v.id === bl.id);
                const shouldAdd = !inExisting && inEnabled;
                const shouldRemove = inExisting && !inEnabled;

                if (shouldRemove) await toggleBookOnBookListFilter(bl.id, book.id, false);
                else if (shouldAdd) await toggleBookOnBookListFilter(bl.id, book.id, true);
              }
              logEvent(AnalyticsEvent.BooksChangeLists);
            },
          },
        ]}
      />
    </>
  );
};

export default BookListPicker;
