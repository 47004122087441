import { IonButtons, IonHeader, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import React from 'react';
import './Header.scss';

import { trackScreen } from '../../services/analytics';
import { Helmet } from 'react-helmet';

interface HeaderProps {
  screenName: string;
  title?: string;
  endSlot?: React.ReactNode;
  startSlot?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({ screenName, title, startSlot, endSlot }) => {
  const handleScrollTop = async () => {
    // Select the active page (the content inside ion-page that isn't hidden)
    const content: HTMLIonContentElement | null = document.querySelector(
      '.ion-page:not(.ion-page-hidden) > ion-content'
    );
    await content?.scrollToTop(300);
  };

  useIonViewDidEnter(async () => {
    await trackScreen(screenName);
  });

  return (
    <IonHeader>
      <Helmet>
        <title>{title || 'Kabook!'}</title>
      </Helmet>
      <IonToolbar onClick={handleScrollTop}>
        {title && <IonTitle>{title}</IonTitle>}
        {startSlot && <IonButtons slot="start">{startSlot}</IonButtons>}
        {endSlot && <IonButtons slot="end">{endSlot}</IonButtons>}
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
