import type { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel-react';
import useEmblaCarousel from 'embla-carousel-react';
import type { ReactNode } from 'react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import './Carousel.scss';

interface Props {
  children: ReactNode[];
  dots?: boolean;
}

export interface CarouselRef {
  scrollPrev: () => void;
  scrollNext: () => void;
}

export const Carousel = forwardRef<CarouselRef, Props>(({ children, dots }, ref) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ duration: 20 });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      scrollPrev: () => emblaApi?.scrollPrev(),
      scrollNext: () => emblaApi?.scrollNext(),
    }),
    [emblaApi]
  );

  useEffect(() => {
    if (!emblaApi) return;
    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  return (
    <div className="carousel" ref={emblaRef}>
      <div className="carousel__container">
        {children.map((slide, index) => (
          <div key={index} className="carousel__slide">
            {slide}
          </div>
        ))}
      </div>
      {dots && (
        <ul className="carousel__dots" aria-hidden>
          {children.map((_, index) => (
            <li key={index} className={selectedIndex === index ? 'carousel__dots--active' : ''}>
              <button onClick={() => emblaApi?.scrollTo(index)} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
});
