import { Purchases } from '@awesome-cordova-plugins/purchases';
import type { CustomerInfo } from '@awesome-cordova-plugins/purchases';
import { isPlatform } from '@ionic/react';
import axios from 'axios';

import type { CustomerInfoSlim } from '../hooks/use-customer-info';

import { AnalyticsEvent, logEvent } from './analytics';
import { getUid } from './authentication';

const REVENUECAT_TOKEN = 'hGiMoSbPShIetlcowsZWwRYtOePUKmrh';

export async function initBilling(): Promise<void> {
  console.log('[billing] Initializing!');

  if (!isPlatform('capacitor')) {
    console.log('[billing] Unsupported platform');
    return;
  }

  Purchases.setDebugLogsEnabled(false);
  Purchases.configureWith({ apiKey: REVENUECAT_TOKEN });
}

export function setBillingUserId(uid: string | null): void {
  if (uid === null) {
    Purchases.logOut().catch((err) => console.log('[billing] Failed to logOut', err));
  } else {
    Purchases.logIn(uid).catch((err) => console.log('[billing] Failed to logIn', err));
  }
}

export async function manageSubscription(purchaserInfo: CustomerInfoSlim): Promise<void> {
  logEvent(AnalyticsEvent.BillingManage, {});

  const isAndroid = isPlatform('capacitor') && isPlatform('android');
  const isIOS = isPlatform('capacitor') && isPlatform('ios');
  const purchaseAndroid = purchaserInfo.store === 'PLAY_STORE';
  const purchaseIOS = purchaserInfo.store === 'APP_STORE';

  console.log('[billing] Manage Subscription', purchaserInfo.managementURL);

  if (isIOS && !isAndroid) {
    // Web just opens the management URL no matter what store
    window.location.assign(purchaserInfo?.managementURL || '');
  } else if (isAndroid && purchaseAndroid) {
    window.location.assign(purchaserInfo?.managementURL || '');
  } else if (isIOS && purchaseIOS) {
    window.location.assign(purchaserInfo?.managementURL || '');
  } else if (purchaseIOS) {
    window.alert('Subscription is managed via the Apple App Store');
  } else if (purchaseAndroid) {
    window.alert('Subscription is managed via the Google Play Store');
  }
}

export async function getSubscriberWeb(): Promise<CustomerInfoSlim> {
  const { data } = await axios.get(`https://api.revenuecat.com/v1/subscribers/${getUid()}`, {
    headers: { Authorization: `Bearer ${REVENUECAT_TOKEN}` },
  });

  // NOTE: When user not found, API returns the same things as if not subscribed (i.e. not a 404)
  const expiry = data.subscriber.entitlements?.plus?.expires_date;
  const expiryDate = expiry ? new Date(expiry) : new Date(0);
  const expired = Date.now() > expiryDate.getTime();
  return {
    managementURL: data.subscriber.management_url,
    isActive: !expired,
    store: 'unknown'.toUpperCase(),
  };
}

export async function getCustomerInfo(customerInfo?: CustomerInfo): Promise<CustomerInfoSlim> {
  if (!isPlatform('capacitor')) {
    return getSubscriberWeb();
  }

  const info = customerInfo || (await Purchases.getCustomerInfo());
  return {
    managementURL: info.managementURL,
    isActive: !!info.entitlements.active?.plus,
    store: info.entitlements.active?.plus?.store.toUpperCase(),
  };
}
