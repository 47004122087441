import { App } from '@capacitor/app';
import { isPlatform, useIonRouter, useIonViewWillEnter } from '@ionic/react';
import React from 'react';

interface BackButtonProps {
  children: React.ReactNode;
}

/**
 * Component to handle listening to back button and exiting app.
 * NOTE: It must be used inside <IonReactRouter> or it won't be
 *   able to extract the router from the context.
 */
const BackButton: React.FC<BackButtonProps> = ({ children }) => {
  const router = useIonRouter();
  useIonViewWillEnter(() => {
    if (!isPlatform('android')) return;

    App.addListener('backButton', () => {
      // Exit the app if we can't go back anymore
      if (!router.canGoBack()) {
        App.exitApp();
      }
    });
  }, []);

  return <>{children}</>;
};

export default BackButton;
