import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import type { Crop } from 'react-image-crop';
import ReactCrop from 'react-image-crop';
import cropImg from '../util/crop-img';
import { IonButton, IonButtons, IonContent, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import Debouncer from '../util/debounce';
import type { ResizeImageResult } from '../util/resize-img';

interface Props {
  onDone: (uri: string) => void;
  onCancel: () => void;
  img: ResizeImageResult;
  ready: boolean;
}

const _cropDebounce = new Debouncer(500);

const BookCoverEditModal: FC<Props> = ({ onDone, onCancel, img, ready }) => {
  const ref = useRef<HTMLIonContentElement>(null);
  const [coverCrop, setCoverCrop] = useState<Crop>();
  const [croppedCoverImageUri, setCroppedCoverImageUri] = useState<string>(img.uri);

  useEffect(() => {
    if (!ref.current || !ready) return;
    setCoverCrop(getDefaultCrop(img, ref.current?.clientWidth ?? 0, ref.current?.clientHeight ?? 0));
  }, [img.uri, img.width, img.height, ref.current?.clientWidth, ref.current?.clientHeight, ready]);

  const handleChangeCrop = (v: Crop) => {
    setCoverCrop(v);
    _cropDebounce.callWith(async () => {
      const uri = await cropImg(img.uri, v.x || 0, v.y || 0, v.width || 0, v.height || 0);
      setCroppedCoverImageUri(uri);
    });
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Edit Cover</IonTitle>
          {croppedCoverImageUri && (
            <IonButtons slot="end">
              <IonButton onClick={() => onDone(croppedCoverImageUri)}>Save</IonButton>
            </IonButtons>
          )}
          <IonButtons slot="start">
            <IonButton onClick={() => onCancel()}>Cancel</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-text-center" ref={ref}>
        {coverCrop && ref.current && (
          <ReactCrop src={img.uri} onChange={handleChangeCrop} crop={coverCrop} keepSelection />
        )}
      </IonContent>
    </>
  );
};

function getDefaultCrop(img: ResizeImageResult, clientWidth: number, clientHeight: number): Crop {
  const aspect = 0.6;
  const padding = 20;

  const imgAspect = img.width / img.height;
  const imgWidthMax = Math.min(img.width, clientWidth);
  const imgHeightMax = Math.min(img.height, clientHeight);

  const imgWidth = imgHeightMax * imgAspect < clientWidth ? imgHeightMax * imgAspect : imgWidthMax;
  const imgHeight = imgWidthMax / imgAspect < clientHeight ? imgWidthMax / imgAspect : imgHeightMax;

  const width = (imgHeight * aspect < imgWidth ? imgHeight * aspect : imgWidth) - padding;
  const height = (imgWidth / aspect < imgHeight ? imgWidth / aspect : imgHeight) - padding;

  const x = (imgWidth - width) / 2;
  const y = (imgHeight - height) / 2;

  return { aspect, width, height, x, y, unit: 'px' };
}

export default BookCoverEditModal;
