import isProd from '../util/is-prod';
import { getPref, SharedPrefKey } from '../util/shared-prefs';

export enum AnalyticsEvent {
  // Book events
  BooksAdd = 'books_create',
  BooksDelete = 'books_delete',
  BooksSearch = 'books_search',
  BooksFilterByStatus = 'books_filter_by_status',
  BooksSelectView = 'books_select_view',
  BooksSelectSort = 'books_select_sort',
  BooksChangeStatus = 'books_change_status',
  BooksChangeNotes = 'books_change_notes',
  BooksChangeLists = 'books_change_list',

  // BookList event
  BookListsDelete = 'booklists_delete',
  BookListsAdd = 'booklists_create',

  // Auth events
  AuthLogin = 'auth_login',
  AuthSignup = 'auth_signup',
  AuthSkipSetup = 'auth_skip',
  AuthLogout = 'auth_logout',
  AuthResetPassword = 'auth_resetpassword',

  // App Rate
  RateShow = 'rate_show',
  RateNegative = 'rate_negative',
  RateButton = 'rate_button',

  // Settings events
  SettingsDarkMode = 'settings_dark',
  SettingsSystemDarkMode = 'settings_system_dark',

  // Money
  BillingUpsell = 'billing_upsell',
  BillingRestore = 'billing_restore',
  BillingManage = 'billing_manage',

  // Announcements
  AnnouncementNameChange = 'announcement_name_change',
}

export async function initAnalytics(): Promise<void> {
  console.log("[analytics] Initializing analytics")
  const enabled = await isProd();
  console.log('[analytics] Enabled:', enabled ? 'enabled' : 'disabled');

  // Check if user has disabled analytics
  const sendUsageStatistics = await getPref(SharedPrefKey.SettingSendUsage, true);
  if (!sendUsageStatistics) {
    await disableAnalytics('disabled in settings');
  }
}

export async function setUserId(userId: string): Promise<void> {
  console.log('[analytics] set uid', userId);
}

export async function trackScreen(name: string): Promise<void> {
  console.log('[analytics] track screen', name);
}

export async function disableAnalytics(message: string): Promise<void> {
  console.log('[analytics] Disabling because:', message);
}

const _logDebounceTimeouts: { [key: string]: NodeJS.Timeout } = {};

export function logEvent(name: AnalyticsEvent, eventParams: Record<string, any> = {}): void {
  const timeoutKey = `${name}:${Object.values(eventParams).sort().join('|')}`;

  // To prevent the component to have to worry about double-tracking events, we
  // debounce the tracking based on the event parameters
  clearTimeout(_logDebounceTimeouts[timeoutKey]);
  _logDebounceTimeouts[timeoutKey] = setTimeout(async () => {
    console.log('[analytics] log event', name, JSON.stringify(eventParams));
  }, 200);
}
