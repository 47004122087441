export interface ResizeImageResult {
  uri: string;
  width: number;
  height: number;
}

export default async function resizeImage(
  dataUri: string,
  wantedWidth: number,
  wantedHeight: number,
): Promise<ResizeImageResult> {
  return new Promise((resolve) => {
    const img = document.createElement('img');

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (ctx === null) {
        throw Error('Failed to initialize canvas context');
      }

      const aspect = img.width / img.height;

      if (wantedHeight * aspect < wantedWidth) {
        canvas.width = wantedHeight * aspect;
        canvas.height = wantedHeight;
      } else {
        canvas.width = wantedWidth;
        canvas.height = wantedWidth / aspect;
      }

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const dataURI = canvas.toDataURL();

      resolve({ uri: dataURI, width: canvas.width, height: canvas.height });
    };

    img.src = dataUri;
  });
}
