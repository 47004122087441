import useBookLists from './use-book-lists';
import useCustomerInfo from './use-customer-info';

export default function useCanAddLists(): boolean {
  const { data: bookLists } = useBookLists();
  const purchaserInfo = useCustomerInfo();

  // Return true if we haven't loaded yet
  if (!bookLists || !purchaserInfo) {
    return true;
  }

  if (purchaserInfo.isActive) {
    return true;
  }

  return bookLists.length < 3;
}
