import classnames from 'classnames';
import type { ComponentProps } from 'react';
import React from 'react';
import './Notice.scss';
import { IonText } from '@ionic/react';

interface NoticeProps extends ComponentProps<any> {
  className?: string,
  color?: string,
}

const Notice: React.FC<NoticeProps> = ({ children, className, color }) => {
  color = color || 'medium';

  return (
    <div className={classnames('notice', className)} style={{
      borderColor: color && `rgba(var(--ion-color-${color}-rgb), 0.4)`,
      backgroundColor: color && `rgba(var(--ion-color-${color}-rgb), 0.01)`,
    }}>
      <IonText color={color}>
        {children}
      </IonText>
    </div>
  );
};

export default Notice;
