import {
  IonActionSheet,
  IonAlert,
  IonButton,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonToast,
} from '@ionic/react';
import type { ReactNode } from 'react';
import React, { memo, useState } from 'react';

import './BookListItem.scss';
import { useHistory } from 'react-router';

import type { Book } from '../../data/books';
import { BookStatus, createBook, statusLabel } from '../../data/books';
import useDeleteBookMutation from '../../hooks/use-delete-book-mutation';
import { AnalyticsEvent, logEvent } from '../../services/analytics';

import { add } from 'ionicons/icons';

import BookCover from './BookCover';
import StarRatingDisplay from '../general/StarRatingDisplay';
import { truncateText } from '../../util/truncate-text';

interface BookListItemProps {
  book: Book;
  onClick?: (book: Book) => any;
  onInnerClick?: (book: Book) => any;
  size?: 'small' | 'tiny';
  deletable?: boolean | ReactNode;
  disabled?: boolean;
  showAddButton?: boolean;
  hide?: boolean;
  meta?: ReactNode | string;
  hideStatus?: boolean;
}

export const BookListItem: React.FC<BookListItemProps> = ({
  deletable,
  book,
  onClick,
  onInnerClick,
  disabled,
  size,
  showAddButton,
  hide,
  meta,
  hideStatus,
}) => {
  const { mutate: deleteBook } = useDeleteBookMutation(book.id);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [actionSheetOpen, setActionSheetOpen] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>();
  const history = useHistory();

  if (deletable === true) {
    deletable = (
      <IonItemOptions side="end">
        <IonItemOption color="danger" onClick={() => setShowDeleteConfirm(true)}>
          Delete
        </IonItemOption>
      </IonItemOptions>
    );
  }

  const addBook = async (book: Book, status: BookStatus) => {
    await createBook({ ...book, status });
    logEvent(AnalyticsEvent.BooksAdd, { title: book.title, status });
    setToastMessage(`Added ${book.title}`);
  };

  return (
    <>
      <IonToast
        isOpen={toastMessage !== undefined}
        color="dark"
        onDidDismiss={() => setToastMessage(undefined)}
        message={toastMessage}
        duration={2000}
        position="bottom"
        buttons={[
          {
            side: 'end',
            text: 'Go Back',
            handler: () => {
              history.goBack();
            },
          },
        ]}
      />
      {!hide && (
        <IonItemSliding disabled={!deletable}>
          <IonActionSheet
            isOpen={actionSheetOpen}
            onDidDismiss={() => setActionSheetOpen(false)}
            header="Add Book"
            buttons={[
              ...Object.values(BookStatus).map((s) => ({
                text: statusLabel(s),
                handler: () => addBook(book, s),
              })),
              { text: 'Cancel', role: 'cancel' },
            ]}
          />
          <IonAlert
            header={`Really Delete "${truncateText(book.title ?? '', 30)}?"`}
            isOpen={showDeleteConfirm}
            onDidDismiss={() => setShowDeleteConfirm(false)}
            buttons={[
              { text: 'Cancel', role: 'cancel' },
              {
                text: 'Delete',
                handler: async () => {
                  await deleteBook();
                  const { title, status } = book || {};
                  logEvent(AnalyticsEvent.BooksDelete, { title, status, source: 'list_swipe' });
                },
              },
            ]}
          />
          <IonItem
            button
            lines="none"
            detail={!showAddButton} // No detail arrow if there's something in the end
            disabled={disabled}
            className={`book-list-item size-${size}`}
            onClick={() => onClick?.(book)}
            data-id={book.id}
            data-gid={book.gid}
          >
            <BookCover hideStatus={hideStatus} slot="start" book={book} onClick={() => onInnerClick?.(book)} />
            <IonLabel onClick={() => onInnerClick?.(book)}>
              <h2>{book.title}</h2>
              <h3>{book.authors.join(', ')}</h3>
              <div className="meta">
                {book.rating > 0 ? (
                  <StarRatingDisplay
                    rating={book.rating}
                    color="medium"
                    size={size === 'small' || size === 'tiny' ? 'small' : undefined}
                  />
                ) : (
                  size !== 'tiny' && (meta || book.description)
                )}
              </div>
            </IonLabel>
            {showAddButton && (
              <IonButton
                className="add-button"
                slot="end"
                fill="clear"
                color="medium"
                onClick={() => {
                  setActionSheetOpen(true);
                }}
              >
                <IonIcon icon={add} slot="icon-only" />
              </IonButton>
            )}
          </IonItem>
          {deletable}
        </IonItemSliding>
      )}
    </>
  );
};

export default BookListItem;

export const BookListItemMemo = memo(BookListItem);
