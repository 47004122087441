import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import type { Book } from '../data/books';
import { getBook } from '../data/books';
import queryClient from '../query-client';

export default function useBook(id: string): UseQueryResult<Book, Error> {
  return useQuery(['books', id], () => getBook(id), {});
}

export function setBookCache(book: Book): void {
  queryClient.setQueryData(['books', book.id], book);
}
