import type { Ref } from 'react';
import React, { useRef, useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonPage,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { formatDistanceToNow } from 'date-fns';

import './LibraryPage.scss';
import { ellipse } from 'ionicons/icons';
import { useHistory } from 'react-router';

import Notice from '../components/general/Notice';
import AddDropdown from '../components/ui/AddDropdown';
import BookCount from '../components/ui/BookCount';
import BookListItem from '../components/ui/BookListItem';
import Header from '../components/ui/Header';
import type { Book } from '../data/books';
import { BookStatus, fuzzySearchBooks, sortBooks, statusColor, statusLabel } from '../data/books';
import { SearchFilter } from '../data/search';
import { useBooksGrouped } from '../hooks/use-books';
import { TabRoot, tabRootName, URLs } from '../urls';
import minimumTime from '../util/minimum-time';

interface Props {
  router: HTMLIonRouterOutletElement | null;
}

const LibraryPage: React.FC<Props> = ({ router }) => {
  const history = useHistory();
  const { allBooks, booksByStatus, refetch, isLoading, error } = useBooksGrouped();
  const [searchText, setSearchText] = useState<string>('');

  const refresh = async (e: CustomEvent) => {
    await minimumTime(refetch, 1000);
    e.detail.complete();
  };

  const aFewDaysAgo = Date.now() / 1000 - 3600 * 24 * 3;
  const recentlyAddedBooks =
    sortBooks('-added', allBooks).filter((b) => {
      return b.createdAt.seconds > aFewDaysAgo;
    }) || [];

  const searchedBookResults = fuzzySearchBooks(allBooks, searchText, SearchFilter.All);
  const searchbarRef: Ref<HTMLIonSearchbarElement> = useRef(null);

  const blurSearchbar = async () => {
    if (!searchbarRef.current) return;
    const el = await searchbarRef.current.getInputElement();
    el.blur();
  };

  const handleClickBookFromSearch = async (b: Book) => {
    history.push(URLs.booksDetails(TabRoot.Library, b.id));

    // Clear search text so, when the user goes back, they see
    // the initial library view.
    // NOTE: Do it after a delay so it doesn't disappear before
    //   the page changes
    setTimeout(() => {
      setSearchText('');
    }, 500);
  };

  return (
    <IonPage id="book-page">
      <Header
        screenName="Library"
        title={tabRootName(TabRoot.Library)}
        endSlot={<AddDropdown showBookItems router={router} />}
      />

      <IonContent fullscreen={false}>
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent />
        </IonRefresher>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{tabRootName(TabRoot.Library)}</IonTitle>
            {isLoading && <IonProgressBar type="indeterminate" slot="fixed" />}
          </IonToolbar>
        </IonHeader>
        {error && <p>Error: {error?.message}</p>}

        <form
          onSubmit={async (e) => {
            // Hide keyboard on submit
            e.preventDefault();
            await blurSearchbar();
          }}
        >
          <IonSearchbar
            className="ion-margin-top"
            showCancelButton={searchText ? 'always' : 'focus'}
            enterkeyhint="search"
            placeholder="Search your library"
            ref={searchbarRef}
            value={searchText}
            debounce={0}
            onIonChange={(e) => setSearchText(e.detail.value ?? searchText)}
            onIonClear={() => blurSearchbar()}
          />
        </form>

        {searchText && searchedBookResults.length > 0 && (
          <IonList>
            {searchedBookResults.map((b) => (
              <BookListItem key={b.id} onClick={handleClickBookFromSearch} size="small" book={b} />
            ))}
          </IonList>
        )}
        {searchText && searchedBookResults.length === 0 && (
          <Notice className="ion-margin">No results for "{searchText}"</Notice>
        )}

        {!searchText && !isLoading && (
          <IonList>
            <IonItem routerLink={URLs.booksAll(TabRoot.Library)} lines="none">
              <IonIcon icon={ellipse} />
              &nbsp;All Books
              <IonText color="medium" slot="end">
                <BookCount n={allBooks.length} className="italic" />
              </IonText>
            </IonItem>
            {Object.values(BookStatus)
              .filter((s) => booksByStatus[s].length)
              .map((s) => (
                <IonItem key={s} lines="none" routerLink={URLs.booksByStatus(TabRoot.Library, s)}>
                  <IonIcon icon={ellipse} color={statusColor(s)} />
                  &nbsp;{statusLabel(s)}
                  <IonText slot="end" color="medium">
                    <BookCount n={booksByStatus[s].length} size="small" className="italic" />
                  </IonText>
                </IonItem>
              ))}
          </IonList>
        )}
        {!searchText && recentlyAddedBooks.length > 0 && (
          <>
            <IonItem className="ion-margin-vertical">
              <h2 className="ion-no-margin">Recently Added</h2>
            </IonItem>
            <IonList className="ion-margin-bottom">
              {recentlyAddedBooks.map((b) => (
                <BookListItem
                  deletable
                  key={b.id}
                  book={b}
                  meta={`${formatDistanceToNow(b.createdAt.toDate())} ago`}
                  onClick={(b) => history.push(URLs.booksDetails(TabRoot.Library, b.id))}
                />
              ))}
            </IonList>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default LibraryPage;
