import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Slider from 'react-slick';
import { Carousel } from '../components/general/Carousel';
import Notice from '../components/general/Notice';
import AddDropdown from '../components/ui/AddDropdown';
import BookShowcase from '../components/ui/BookShowcase';
import Header from '../components/ui/Header';
import HomeBooksSection from '../components/ui/HomeBooksSection';
import type { Book } from '../data/books';
import { BookStatus, statusLabel } from '../data/books';
import { useBooksGrouped } from '../hooks/use-books';
import { TabRoot, tabRootName, URLs } from '../urls';
import minimumTime from '../util/minimum-time';
import useEmblaCarousel from 'embla-carousel-react';

import './ReadingPage.scss';

interface Props {
  router: HTMLIonRouterOutletElement | null;
}

const ReadingPage: React.FC<Props> = ({ router }) => {
  const history = useHistory();
  const { allBooks, booksByStatus, isLoading, error, refetch } = useBooksGrouped();
  const [showAddBook, setShowAddBook] = useState<boolean>(false);
  const [emblaRef] = useEmblaCarousel();

  const refresh = async (e: CustomEvent) => {
    await minimumTime(refetch, 1000);
    e.detail.complete();
  };

  const clickBook = (book: Book) => {
    history.push(URLs.booksDetails(TabRoot.Reading, book.id));
  };

  const booksReading = booksByStatus[BookStatus.Reading];

  return (
    <IonPage id="home-page">
      <Header
        screenName="Reading"
        title={tabRootName(TabRoot.Reading)}
        endSlot={
          <AddDropdown
            showBookItems
            showBookListItems
            forceOpen={showAddBook}
            router={router}
            onDismiss={() => setShowAddBook(false)}
          />
        }
      />
      <IonContent fullscreen={false}>
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent />
        </IonRefresher>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{tabRootName(TabRoot.Reading)}</IonTitle>
            {isLoading && <IonProgressBar type="indeterminate" slot="fixed" />}
          </IonToolbar>
        </IonHeader>
        {error && <p>Error: {error?.message}</p>}

        {allBooks.length === 0 && !isLoading && (
          <>
            <Notice className="ion-margin">You don't have any books</Notice>
            <IonButton onClick={() => setShowAddBook(true)} expand="block" className="ion-margin">
              Add a Book
            </IonButton>
            <IonButton fill="outline" routerLink={URLs.importFromGoodReads()} expand="block" className="ion-margin">
              Import from Goodreads
            </IonButton>
          </>
        )}

        {allBooks.length > 0 && !booksByStatus[BookStatus.Reading].length && (
          <Notice className="ion-margin">Books you're reading will show here</Notice>
        )}

        {booksReading.length > 0 && (
          <Carousel dots>
            {booksReading.map((b) => (
              <BookShowcase button book={b} onClick={() => clickBook(b)} className="ion-padding-vertical" />
            ))}
          </Carousel>
        )}
        <HomeBooksSection
          className="ion-margin-start"
          title={statusLabel(BookStatus.Unread)}
          books={booksByStatus[BookStatus.Unread]}
          maxItems={50}
          layout="scroll-x"
          startIndex={0}
          onBookClick={clickBook}
          to={URLs.booksByStatus(TabRoot.Reading, BookStatus.Unread)}
        />
      </IonContent>
    </IonPage>
  );
};

export default ReadingPage;
