import type { FC } from 'react';
import type { Book } from '../../data/books';
import React from 'react';

interface Props {
  book: Book;
}

const BookDetailsTable: FC<Props> = ({ book }) => {
  return (
    <table className="w-full">
      <tbody>
        {book.categories.length > 0 && (
          <tr>
            <td>Category</td>
            <td>{book.categories.join(', ')}</td>
          </tr>
        )}
        {book.publisher && (
          <tr>
            <td>Publisher</td>
            <td>{book.publisher}</td>
          </tr>
        )}
        {book.publishDate && (
          <tr>
            <td>Published</td>
            <td>{book.publishDate.toDate().getFullYear()}</td>
          </tr>
        )}
        {book.pageCount > 0 && (
          <tr>
            <td>Pages</td>
            <td>{book.pageCount}</td>
          </tr>
        )}
        {book.goodreadsRating > 0 && (
          <tr>
            <td>Goodreads Rating</td>
            <td>{book.goodreadsRating}/5</td>
          </tr>
        )}
        {book.googleRating > 0 && (
          <tr>
            <td>Google Rating</td>
            <td>{book.googleRating * 100}% liked</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default BookDetailsTable;
