import { IonAlert, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonNote } from '@ionic/react';
import React, { useState } from 'react';

import './BookListListItem.scss';
import type { BookList } from '../../data/book-lists';
import type { Book } from '../../data/books';
import useDeleteBookListMutation from '../../hooks/use-delete-book-list-mutation';
import { AnalyticsEvent, logEvent } from '../../services/analytics';

import BookCount from './BookCount';
import BookCoverStack from './BookCoverStack';

import classnames from 'classnames';
import { truncateText } from '../../util/truncate-text';

interface BookListListItemProps {
  list: BookList;
  books: Book[];
  routerLink?: string;
  size?: 'small';
  deletable?: boolean;
}

export const BookListListItem: React.FC<BookListListItemProps> = ({ list, deletable, routerLink, books, size }) => {
  const { mutate: deleteBookList } = useDeleteBookListMutation(list.id);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);

  const booksForList = books.filter((b) => list.filter.bookIds?.includes(b.id)) || [];

  return (
    <IonItemSliding className={classnames('book-list-list-item', { small: size === 'small' })}>
      <IonAlert
        header={`Really delete "${truncateText(list.name, 30)}?"`}
        isOpen={showDeleteConfirm}
        onDidDismiss={() => setShowDeleteConfirm(false)}
        buttons={[
          { text: 'Cancel', role: 'cancel' },
          {
            text: 'Delete',
            handler: async () => {
              await deleteBookList();
              const { name } = list || {};
              logEvent(AnalyticsEvent.BookListsDelete, { name, source: 'list_swipe' });
            },
          },
        ]}
      />
      <IonItem routerLink={routerLink} lines="none">
        <BookCoverStack slot="start" size={size} books={booksForList} max={3} />
        <IonLabel>
          <h2>{list.name}</h2>
          <IonNote>
            <BookCount n={booksForList.length} />
          </IonNote>
        </IonLabel>
      </IonItem>
      {deletable && (
        <IonItemOptions side="end">
          <IonItemOption color="danger" onClick={() => setShowDeleteConfirm(true)}>
            Delete
          </IonItemOption>
        </IonItemOptions>
      )}
    </IonItemSliding>
  );
};

export default BookListListItem;
