import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { isPlatform } from '@ionic/react';
import { useEffect } from 'react';

export enum Orientation {
  Portrait = 'portrait',
  PortraitPrimary = 'portrait-primary',
  PortraitSecondary = 'portrait-secondary',

  Landscape = 'landscape',
  LandscapePrimary = 'landscape-primary',
  LandscapeSecondary = 'landscape-secondary',

  Any = 'any',
}

export default function useOrientationLock(orientation: Orientation): void {
  useEffect(() => {
    if (isPlatform('capacitor')) {
      ScreenOrientation.lock(orientation as any).catch((err) => {
        console.log('[orientation] Failed to lock screen', err);
      });
    }
  }, [orientation]);
}
