import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonPage,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/react';
import React from 'react';
import './BooksFilterPage.scss';
import { useHistory, useParams } from 'react-router';

import BookCollection from '../components/ui/BookCollection';
import Header from '../components/ui/Header';
import type { BookStatus } from '../data/books';
import { statusLabel } from '../data/books';
import useBooks from '../hooks/use-books';
import { TabRoot, tabRootName, URLs } from '../urls';
import minimumTime from '../util/minimum-time';

const BooksFilterPage: React.FC = () => {
  const history = useHistory();
  const params = useParams<{
    tab: TabRoot;
    status: BookStatus;
  }>();
  const { data: allBooks, isLoading, isError, error, refetch } = useBooks();
  const filter = params.status;
  const tab = params.tab;

  const refresh = async (e: CustomEvent) => {
    await minimumTime(() => {
      refetch();
    }, 1000);
    e.detail.complete();
  };

  // TODO: Cache this code maybe?
  const books = allBooks?.filter((b) => b.status === filter);
  return (
    <IonPage id="book-by-status-page">
      <Header
        screenName="BooksByStatus"
        title={statusLabel(filter)}
        startSlot={
          <IonButtons slot="start">
            <IonBackButton defaultHref={URLs.tabReading()} text={tabRootName(tab)} />
          </IonButtons>
        }
      />

      <IonContent fullscreen={false}>
        {isLoading && <IonProgressBar type="indeterminate" slot="fixed" />}
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent />
        </IonRefresher>
        {isError && <p>Error: {error?.message}</p>}
        {books && (
          <BookCollection
            deletable
            className="ion-margin-top"
            emptyMessage="No books with this status"
            totalBooks={allBooks?.length}
            books={books}
            onClick={(book) => history.push(URLs.booksDetails(TabRoot.Library, book.id))}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default BooksFilterPage;
