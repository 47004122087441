import type { FC } from 'react';
import { star, starOutline } from 'ionicons/icons';
import { IonButton, IonGrid, IonIcon, IonRow } from '@ionic/react';

interface Props {
  rating: number; // Decimal between 0-1
  onChange: (rating: number) => void | Promise<void>;
}

const StarRating: FC<Props> = ({ rating, onChange }) => {
  const handleChange = async (rating: number) => {
    try {
      await onChange(rating);
    } catch (err) {
      console.log('[rating] Failed to set rating', err);
    }
  };

  return (
    <IonGrid className="ion-no-padding">
      <IonRow>
        {[1, 2, 3, 4, 5].map((i) => (
          <IonButton key={i} size="small" fill="solid" color="light" onClick={() => handleChange(i / 5)}>
            <IonIcon icon={rating * 5 >= i ? star : starOutline} />
          </IonButton>
        ))}
      </IonRow>
    </IonGrid>
  );
};

export default StarRating;
