import React, { useState } from 'react';
import './ListsPage.scss';
import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

import Notice from '../components/general/Notice';
import AddBookListAlert from '../components/ui/AddBookListAlert';
import AddDropdown from '../components/ui/AddDropdown';
import BookListListItem from '../components/ui/BookListListItem';
import Header from '../components/ui/Header';
import { dummyBookLists } from '../data/book-lists';
import useBookLists from '../hooks/use-book-lists';
import useBooks from '../hooks/use-books';
import { TabRoot, tabRootName, URLs } from '../urls';
import minimumTime from '../util/minimum-time';

interface Props {
  router: HTMLIonRouterOutletElement | null;
}

const ListsPage: React.FC<Props> = ({ router }) => {
  const { data: allBooks, isLoading: l1, refetch: r1, error: e1 } = useBooks();
  const { data: bookLists, isLoading: l2, refetch: r2, error: e2 } = useBookLists();
  const [showAddListAlert, setShowAddListAlert] = useState<boolean>(false);
  const isLoading = l1 || l2;
  const error = e1 || e2;
  const refetch = () => Promise.all([r1(), r2()]);

  const refresh = async (e: CustomEvent) => {
    await minimumTime(() => {
      refetch();
    }, 1000);
    e.detail.complete();
  };

  return (
    <>
      <AddBookListAlert show={showAddListAlert} onDidDismiss={() => setShowAddListAlert(false)} />
      <IonPage id="lists-page">
        <Header
          screenName="Lists"
          title={tabRootName(TabRoot.Lists)}
          endSlot={<AddDropdown showBookListItems router={router} />}
        />
        <IonContent fullscreen={false}>
          {isLoading && <IonProgressBar type="indeterminate" slot="fixed" />}
          <IonRefresher slot="fixed" onIonRefresh={refresh}>
            <IonRefresherContent />
          </IonRefresher>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">{tabRootName(TabRoot.Lists)}</IonTitle>
            </IonToolbar>
          </IonHeader>

          {error && <p>Error: {error?.message}</p>}

          {bookLists && bookLists.length > 0 && (
            <>
              <IonList className="ion-margin-top">
                {bookLists?.map((bl) => (
                  <BookListListItem
                    deletable
                    key={bl.id}
                    list={bl}
                    books={allBooks || []}
                    routerLink={URLs.booksByList(TabRoot.Lists, bl.id)}
                  />
                ))}
              </IonList>
              {bookLists?.length === 0 && <Notice className="ion-margin">You don't have any lists</Notice>}
            </>
          )}

          {bookLists?.length === 0 && (
            <Notice className="ion-margin" color="primary">
              <div className="ion-margin-bottom">
                Organize your book library even further using <strong>custom lists</strong>
              </div>

              <div className="ion-margin-vertical">
                <IonCard>
                  <IonCardHeader className="ion-text-center">
                    <IonCardSubtitle>Example Book Lists</IonCardSubtitle>
                  </IonCardHeader>
                  <IonList className="ion-margin-vertical ion-padding-bottom">
                    {dummyBookLists().map(({ list, books }) => (
                      <BookListListItem key={list.id} list={list} books={books} size="small" />
                    ))}
                  </IonList>
                </IonCard>
              </div>

              <div className="ion-margin">
                <IonButton expand="block" onClick={() => setShowAddListAlert(true)}>
                  Create a List
                </IonButton>
              </div>
            </Notice>
          )}
        </IonContent>
      </IonPage>
    </>
  );
};

export default ListsPage;
