import {
  CreateAnimation,
  IonBackButton,
  IonBadge,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSpinner,
  IonText,
  IonToolbar,
} from '@ionic/react';
import React, { useState } from 'react';
import './SubscribePage.scss';
import { useHistory } from 'react-router';

import Notice from '../components/general/Notice';
import Header from '../components/ui/Header';
import useOffering from '../hooks/use-offering';
import useOfferingName from '../hooks/use-offering-name';
import useCustomerInfo from '../hooks/use-customer-info';
import { URLs } from '../urls';

import type { PurchasesPackage } from '@awesome-cordova-plugins/purchases';
import { Purchases } from '@awesome-cordova-plugins/purchases';
import { checkmarkCircle, heartCircleOutline } from 'ionicons/icons';

const FEATURES = [
  'Create unlimited book lists',
  // 'Sort books in more ways',
  'View books in grid view',
  'Support future development',
];

const SubscribePage: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const purchaserInfo = useCustomerInfo();
  const offering = useOffering();
  const offeringName = useOfferingName();

  const handleSubscribe = (pkg: PurchasesPackage) => {
    setLoading(true);
    Purchases.purchasePackage(pkg).then(
      () => {
        history.goBack();
      },
      ({ error, userCancelled }) => {
        console.log('Failed to purchase', error, userCancelled);
        setLoading(false);
      }
    );
  };

  return (
    <IonPage id="subscribe-page">
      <Header screenName="Subscribe" title="Upgrade" startSlot={<IonBackButton defaultHref={URLs.tabSettings()} />} />
      <IonContent fullscreen={false}>
        <div className="ion-margin-vertical">
          {loading && <IonSpinner className="ion-margin" />}

          <div className="ion-margin-horizontal ion-padding-top">
            <IonText>
              <strong>{offeringName}</strong> unlocks the ability to:
            </IonText>
          </div>

          <IonList className="ion-padding-vertical">
            {FEATURES.map((f) => (
              <IonItem key={f} lines="none">
                <IonIcon icon={checkmarkCircle} slot="start" color="success" />
                <IonLabel>{f}</IonLabel>
              </IonItem>
            ))}
            <IonItem lines="none">
              <IonIcon icon={heartCircleOutline} slot="start" color="primary" />
              <IonLabel color="medium">Do even more, coming soon...</IonLabel>
            </IonItem>
          </IonList>
          {purchaserInfo && !purchaserInfo.isActive && !offering && (
            <Notice className="ion-margin" color="primary">
              Subscribing is currently only possible within the iOS and Android apps
            </Notice>
          )}
        </div>
      </IonContent>

      {purchaserInfo && !purchaserInfo.isActive && offering && (
        <CreateAnimation
          play
          delay={500}
          duration={700}
          fromTo={{
            property: 'opacity',
            fromValue: '0',
            toValue: '1',
          }}
        >
          <IonFooter>
            <IonToolbar>
              <div className="product-row">
                {offering.availablePackages.map((pkg: PurchasesPackage) => {
                  const monthly = offering?.availablePackages.find(
                    (p: PurchasesPackage) => p.packageType === 'MONTHLY'
                  );

                  let discount = null;
                  if (pkg.packageType === 'ANNUAL' && monthly) {
                    const monthlyPerYear = monthly.product.price * 12;
                    const difference = monthlyPerYear - pkg.product.price;
                    discount = Math.round((difference / monthlyPerYear) * 100);
                  }

                  return (
                    <IonCard key={pkg.identifier} onClick={() => handleSubscribe(pkg)} button disabled={loading}>
                      {discount && <IonBadge color="success">{discount}% OFF</IonBadge>}
                      <IonCardHeader>
                        <IonCardSubtitle>{pkg.product.title}</IonCardSubtitle>
                        <IonCardTitle>
                          <IonText color="primary" className="price">
                            {pkg.product.priceString}
                          </IonText>
                          <IonText className="period">
                            &nbsp;/&nbsp;{pkg.packageType === 'ANNUAL' ? 'YR' : 'MO'}
                          </IonText>
                        </IonCardTitle>
                      </IonCardHeader>
                    </IonCard>
                  );
                })}
              </div>
              <div className="ion-margin ion-text-center">
                <div>
                  <a href="https://kabook.app/privacy" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </a>
                  &nbsp;<IonText color="medium">•</IonText>&nbsp;
                  <a href="https://kabook.app/privacy" target="_blank" rel="noopener noreferrer">
                    Terms of Service
                  </a>
                </div>
              </div>
            </IonToolbar>
          </IonFooter>
        </CreateAnimation>
      )}
    </IonPage>
  );
};

export default SubscribePage;
