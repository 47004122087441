import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import type { Book } from '../data/books';
import type { SearchFilter } from '../data/search';
import { googleBookToBook, searchBooks } from '../data/search';

export default function useSearchBooks(q: string, filter: SearchFilter): UseQueryResult<Book[], Error> {
  return useQuery(
    ['books', 'search', q, filter],
    async () => {
      if (!q) {
        return [];
      }

      const result = await searchBooks(q, filter);
      if ('error' in result) {
        throw new Error(result.error.message);
      }

      const items = result.items || [];
      const allBooks = items.map(googleBookToBook);

      // HACK: Sometimes the API returns multiple of the same book (eg. "woo")
      //  This logic filters out duplicates
      const seenBooks = new Set();
      return allBooks.filter((b) => {
        if (seenBooks.has(b.gid)) {
          return false;
        } else {
          seenBooks.add(b.gid);
          return true;
        }
      });
    },
    { refetchOnWindowFocus: false }
  );
}
