import { App } from '@capacitor/app';
import { isPlatform } from '@ionic/react';

export default async function isDev(): Promise<boolean> {
  if (!isPlatform('capacitor')) {
    const prodUrl = (location.port === '443' || location.port === '');
    return !prodUrl;
  }

  const { id } = await App.getInfo();
  return process.env.NODE_ENV === 'development' ? true : id.indexOf('.dev') >= 0;
}
