import { IonAlert } from '@ionic/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { createBookList } from '../../data/book-lists';
import useCanAddLists from '../../hooks/use-can-add-lists';
import useOfferingName from '../../hooks/use-offering-name';
import { AnalyticsEvent, logEvent } from '../../services/analytics';
import { URLs } from '../../urls';

interface AddBookListAlertProps {
  show: boolean;
  onDidDismiss: () => void;
}

const AddBookListAlert: React.FC<AddBookListAlertProps> = ({ onDidDismiss, show }) => {
  const history = useHistory();
  const canAddLists = useCanAddLists();
  const offeringName = useOfferingName();
  const [forceHide, setForceHide] = useState<boolean>();

  return (
    <>
      <IonAlert
        isOpen={!forceHide && show && canAddLists}
        onDidDismiss={onDidDismiss}
        header="Create Book List"
        inputs={[
          {
            name: 'name',
            type: 'text',
            label: 'List Name',
            placeholder: 'My List',
          },
        ]}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Ok',
            handler: async ({ name }) => {
              // HACK: Right after the last list is added, canAddLists will be false, and the
              // dialog will switch to the paywall before this can be closed. We'll use
              // this variable on a timeout to make sure that doesn't happen.
              setForceHide(true);

              await createBookList({ name });
              setTimeout(() => setForceHide(false), 2000);
              logEvent(AnalyticsEvent.BookListsAdd);
            },
          },
        ]}
      />
      <IonAlert
        isOpen={!forceHide && show && !canAddLists}
        onDidPresent={() => {
          logEvent(AnalyticsEvent.BillingUpsell, { reason: 'add_list' });
        }}
        onDidDismiss={onDidDismiss}
        header="Need more lists?"
        subHeader={`The free plan is limited to 3 lists. Upgrade to ${offeringName} to add more.`}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: `What's included in ${offeringName}?`,
            handler: () => history.push(URLs.subscribe()),
          },
        ]}
      />
    </>
  );
};

export default AddBookListAlert;
