import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonNote,
  IonPage,
  IonPopover,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonText,
  IonTextarea,
} from '@ionic/react';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';

import './BookDetailsPage.scss';
import DevOnly from '../components/general/DevOnly';
import BookCover from '../components/ui/BookCover';
import BookListPicker from '../components/ui/BookListPicker';
import BookStatusPicker from '../components/ui/BookStatusPicker';
import Header from '../components/ui/Header';
import { statusColor, statusLabel, updateBook } from '../data/books';
import useBook from '../hooks/use-book';
import useDeleteBookMutation from '../hooks/use-delete-book-mutation';
import { AnalyticsEvent, logEvent } from '../services/analytics';
import type { TabRoot } from '../urls';
import { tabRootName, URLs } from '../urls';

import { createOutline, ellipsisHorizontal, ellipsisVertical, trashOutline } from 'ionicons/icons';

import minimumTime from '../util/minimum-time';
import StarRating from '../components/general/StarRating';
import BookDetailsTable from '../components/general/BookDetailsTable';
import { truncateText } from '../util/truncate-text';

let _debounceTimeout: NodeJS.Timeout;

const BookDetailsPage: React.FC = () => {
  const history = useHistory();
  const params = useParams<{ tab: TabRoot; id: string }>();
  const [showPopover, setShowPopover] = useState<Event>();
  const { data: book, isError, error, isLoading, refetch } = useBook(params.id);
  const { mutate: deleteBook } = useDeleteBookMutation(params.id);
  const [note, setNote] = useState<string>();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);

  // HACK: Set note when book loads
  if (book && note === undefined) {
    setNote(book.note);
  }

  const handleNoteChange = (note: string) => {
    setNote(note);
    clearTimeout(_debounceTimeout);
    _debounceTimeout = setTimeout(async () => updateNote(note), 1200);
  };

  const updateNote = async (note: string) => {
    clearTimeout(_debounceTimeout); // Just in case
    await updateBook(params.id, { note });
    if (note) logEvent(AnalyticsEvent.BooksChangeNotes);
  };

  const refresh = async (e: CustomEvent) => {
    await minimumTime(() => {
      refetch();
    }, 1000);
    e.detail.complete();
  };

  return (
    <IonPage id="book-details-page">
      <Header
        screenName="BookDetails"
        title="Book"
        startSlot={
          <IonButtons slot="start">
            <IonBackButton defaultHref={URLs.tabReading()} text={tabRootName(params.tab) || 'Back'} />
          </IonButtons>
        }
        endSlot={
          <IonButtons slot="end">
            <IonAlert
              header={`Really delete "${truncateText(book?.title ?? '', 30)}?"`}
              isOpen={showDeleteConfirm}
              onDidDismiss={() => {
                setShowDeleteConfirm(false);
                setShowPopover(undefined);
              }}
              buttons={[
                { text: 'Cancel', role: 'cancel' },
                {
                  text: 'Delete',
                  handler: async () => {
                    history.goBack();
                    await deleteBook();
                    const { title, status } = book || {};
                    logEvent(AnalyticsEvent.BooksDelete, { title, status, source: 'details_menu' });
                  },
                },
              ]}
            />
            <IonPopover isOpen={showPopover != null} event={showPopover} onDidDismiss={() => setShowPopover(undefined)}>
              <IonList>
                <IonItem
                  button
                  onClick={() => {
                    setShowPopover(undefined);
                    history.push(URLs.booksEdit(book ? book.id : ''));
                  }}
                  lines="none"
                  detailIcon={createOutline}
                >
                  Edit
                </IonItem>
                <IonItem button onClick={() => setShowDeleteConfirm(true)} lines="none" detailIcon={trashOutline}>
                  Delete
                </IonItem>
              </IonList>
            </IonPopover>
            <IonButton onClick={(e) => setShowPopover(e.nativeEvent)}>
              <IonIcon slot="icon-only" ios={ellipsisHorizontal} md={ellipsisVertical} />
            </IonButton>
          </IonButtons>
        }
      />
      <IonContent fullscreen={false}>
        {isLoading && <IonProgressBar type="indeterminate" slot="fixed" />}
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent />
        </IonRefresher>
        {isError && <p>Error: {error?.message}</p>}
        {book && (
          <>
            <div className="book-cover-container">
              <BookCover book={book} hideStatusLabel />
            </div>

            <div className="ion-margin ion-padding-vertical">
              <h1 className="ion-no-margin">{book.title}</h1>
              <IonNote>{book.authors.join(', ')}</IonNote>
              <br />
            </div>

            <div className="ion-margin ion-padding-bottom">
              <BookStatusPicker book={book} expand="block" />
            </div>

            {(book.status === 'finished' || book.status === 'abandoned' || book.status === 'shelved') && (
              <div className="ion-margin-horizontal ion-margin-vertical ion-padding-bottom">
                <h2>Rating</h2>
                <StarRating rating={book.rating} onChange={(rating) => updateBook(book.id, { rating })} />
              </div>
            )}

            <div className="ion-margin-horizontal ion-margin-vertical">
              <h2>Book Lists</h2>
              <BookListPicker book={book} />
            </div>

            <div className="ion-margin-horizontal ion-margin-vertical">
              <h2>Notes</h2>
              <IonItem className="ion-no-margin ion-no-padding">
                <IonTextarea
                  spellcheck
                  autoGrow
                  autocapitalize="sentences"
                  inputMode="text"
                  rows={1}
                  placeholder="My notes..."
                  value={note}
                  onIonBlur={() => updateNote(note || '')}
                  onIonChange={(e) => handleNoteChange(e.detail.value || note || '')}
                />
              </IonItem>
            </div>

            <div className="ion-margin-horizontal ion-margin-vertical">
              <h2>Details</h2>
              <BookDetailsTable book={book} />

              <h2>Description</h2>
              <p>{book.description || 'No description'}</p>

              <DevOnly>
                <h2 className="ion-margin">Timeline</h2>
                <div className="ion-margin">
                  <table style={{ width: '100%' }}>
                    <tbody>
                      {book.statusEvents.map((e) => (
                        <tr key={e.createdAt + ''}>
                          <td className="ion-padding-end">
                            <IonText color={statusColor(e.status)}>{statusLabel(e.status)}</IonText>
                          </td>
                          <td className="ion-text-right">
                            <div className="ion-text-right">{e.createdAt.toDate().toDateString()}</div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </DevOnly>
            </div>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default BookDetailsPage;
