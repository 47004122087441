import React, { useEffect, useRef, useState } from 'react';

import './AddDropdown.scss';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { IonButton, IonIcon, IonItem, IonList, IonPopover, isPlatform, useIonAlert } from '@ionic/react';
import { add, barcodeOutline, createOutline, list, search } from 'ionicons/icons';
import { useHistory } from 'react-router';

import { googleBookToBook, searchBooks, SearchFilter } from '../../data/search';
import { URLs } from '../../urls';

import AddBookListAlert from './AddBookListAlert';
import SearchModal from '../../modals/SearchModal';
import google from '../icons/Google';
import type { Book } from '../../data/books';
import SearchBookDetailsModal from '../../modals/SearchBookDetailsModal';

interface AddDropdownProps {
  router: HTMLIonRouterOutletElement | null;
  showBookListItems?: boolean;
  showBookItems?: boolean;
  forceOpen?: boolean;
  onDismiss?: () => void;
}

const AddDropdown: React.FC<AddDropdownProps> = ({
  forceOpen,
  showBookItems,
  showBookListItems,
  router,
  onDismiss,
}) => {
  const history = useHistory();
  const [showAddListAlert, setShowAddListAlert] = useState<boolean>(false);
  const [showMenuPopover, setShowMenuPopover] = useState<Event>();
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false);
  const [showBookModal, setShowBookModal] = useState<Book | null>(null);
  const buttonRef = useRef<HTMLIonButtonElement>(null);

  useEffect(() => {
    if (!forceOpen) return;
    console.log('CHANGED');
    buttonRef.current?.click();
  }, [forceOpen]);

  const handleHide = () => {
    if (onDismiss) {
      onDismiss();
    }
    setShowMenuPopover(undefined);
  };

  const handleSearch = () => {
    handleHide();
    setShowSearchModal(true);
  };

  const handleNewList = () => {
    handleHide();
    setShowAddListAlert(true);
  };

  const handleNewBook = () => {
    handleHide();
    history.push(URLs.booksCreate());
  };

  const [showAlert, dismissAlert] = useIonAlert();

  const scanBarcode = async () => {
    handleHide();

    if (!(await didUserGrantPermission())) {
      return;
    }

    const rootEl: HTMLDivElement | null = document.querySelector('#root');
    if (rootEl === null) {
      throw new Error('#root not found');
    }

    const bodyBgColor = document.body.style.backgroundColor;
    const hide = () => {
      document.body.style.backgroundColor = bodyBgColor;
      rootEl.style.display = 'block';
      document.body.removeChild(btnEl);
    };

    // START MODIFY DOM
    rootEl.style.display = 'none';
    document.body.style.backgroundColor = 'transparent';
    const btnEl = document.createElement('button');
    btnEl.innerHTML = 'Cancel';
    btnEl.style.position = 'fixed';
    btnEl.style.bottom = '0';
    btnEl.style.left = '0';
    btnEl.style.right = '0';
    btnEl.style.margin = '0';
    btnEl.style.width = '100%';
    btnEl.style.padding = 'var(--ion-padding) 0 max(var(--ion-safe-area-bottom), var(--ion-padding)) 0';
    btnEl.style.fontSize = 'var(--font-size-md)';
    btnEl.addEventListener('click', (e) => {
      e.preventDefault();
      BarcodeScanner.stopScan();
      hide();
    });
    document.body.appendChild(btnEl);
    // END MODIFY DOM

    await BarcodeScanner.hideBackground(); // make background of WebView transparent
    const barcodeData = await BarcodeScanner.startScan();

    if (!barcodeData.hasContent) {
      console.log('[barcode] No content');
      return;
    }

    hide();

    console.log('[barcode] Got content', { barcodeData });
    const results = await searchBooks(barcodeData.content ?? '', SearchFilter.Isbn, { maxResults: 1 });
    if ('error' in results || !results.items?.length) {
      await showAlert('We can\'t find that for you. Do you want to create it manually?', [
        {
          text: 'Dismiss',
          role: 'cancel',
        },
        {
          text: 'Create',
          handler: () => {
            history.push(URLs.booksCreate());
          },
        },
      ]);
      return;
    }

    setShowBookModal(googleBookToBook(results.items[0]));
  };

  if (!showBookItems && !showBookListItems) {
    return null;
  }

  return (
    <>
      <AddBookListAlert show={showAddListAlert} onDidDismiss={() => setShowAddListAlert(false)} />
      <IonPopover isOpen={!!showMenuPopover} event={showMenuPopover} onDidDismiss={handleHide}>
        <IonList>
          {showBookItems && (
            <>
              <IonItem button onClick={handleSearch} lines="none" detailIcon={search}>
                Search for Book
              </IonItem>
              <IonItem button onClick={() => handleNewBook()} lines="none" detailIcon={createOutline}>
                Create Book
              </IonItem>
              {isPlatform('capacitor') && (
                <IonItem button onClick={() => scanBarcode()} lines="none" detailIcon={barcodeOutline}>
                  Scan Barcode
                </IonItem>
              )}
            </>
          )}
          {showBookListItems && (
            <>
              <IonItem button onClick={() => handleNewList()} lines="none" detailIcon={list}>
                Add List
              </IonItem>
            </>
          )}
        </IonList>
      </IonPopover>
      <IonButton ref={buttonRef} onClick={(e) => setShowMenuPopover(e.nativeEvent)}>
        <IonIcon slot="icon-only" md={add} />
      </IonButton>
      <SearchModal router={router} isOpen={showSearchModal} onDismiss={() => setShowSearchModal(false)} />
      <SearchBookDetailsModal book={showBookModal} onDismiss={() => setShowBookModal(null)} isOpen={!!showBookModal} />
    </>
  );
};

const didUserGrantPermission = async () => {
  // check if user already granted permission
  const status = await BarcodeScanner.checkPermission({ force: false });

  if (status.granted) {
    // user granted permission
    return true;
  }

  if (status.denied) {
    // user denied permission
    return false;
  }

  if (status.asked) {
    // system requested the user for permission during this call
    // only possible when force set to true
  }

  if (status.neverAsked) {
    // user has not been requested this permission before
    // it is advised to show the user some sort of prompt
    // this way you will not waste your only chance to ask for the permission
    const c = confirm('We need your permission to use your camera to be able to scan barcodes');
    if (!c) {
      return false;
    }
  }

  if (status.restricted || status.unknown) {
    // ios only
    // probably means the permission has been denied
    return false;
  }

  // user has not denied permission
  // but the user also has not yet granted the permission
  // so request it
  const statusRequest = await BarcodeScanner.checkPermission({ force: true });

  if (statusRequest.asked) {
    // system requested the user for permission during this call
    // only possible when force set to true
  }

  if (statusRequest.granted) {
    // the user did grant the permission now
    return true;
  }

  // user did not grant the permission, so he must have declined the request
  return false;
};

export default AddDropdown;
