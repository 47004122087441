import type { FC } from 'react';
import { star, starOutline } from 'ionicons/icons';
import { IonGrid, IonIcon, IonRow } from '@ionic/react';
import './StartRatingDisplay.scss';
import classnames from 'classnames';

interface Props {
  rating: number; // Decimal between 0-1
  color?: 'primary' | 'secondary' | 'light' | 'medium' | 'dark';
  size?: 'small';
}

const StarRatingDisplay: FC<Props> = ({ rating, color, size }) => {
  return (
    <IonGrid className={classnames('ion-no-padding star-rating-display', `size-${size}`)}>
      <IonRow>
        {[1, 2, 3, 4, 5].map((i) => (
          <IonIcon key={i} color={color} icon={rating * 5 >= i ? star : starOutline} />
        ))}
      </IonRow>
    </IonGrid>
  );
};

export default StarRatingDisplay;
