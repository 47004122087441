import { MutationCache, QueryCache, QueryClient } from 'react-query';
import type { Mutation } from 'react-query/types/core/mutation';
import type { Query } from 'react-query/types/core/query';

const queryCache = new QueryCache({
  onError: (error: unknown, query: Query<unknown, unknown, unknown>) => {
    console.log('QUERY error', { error, query });
  },
});

const mutationCache = new MutationCache({
  onError: (error: unknown, variables: unknown, context: unknown, mutation: Mutation<unknown, unknown, unknown>) => {
    console.log('MUTATE error', { error, variables, context, mutation });
  },
  onSuccess: (data: unknown, variables: unknown, context: unknown, mutation: Mutation<unknown, unknown, unknown>) => {
    console.log('MUTATE success', { data, variables, context, mutation });
  },
});

const queryClient = new QueryClient({
  queryCache,
  mutationCache,
  defaultOptions: {
    queries: {
      cacheTime: Infinity, // Disable cache garbage collection so data in cache never goes stale (until the app relaunches)
      refetchOnWindowFocus: false, // Because we always push data in via DB subscriptions
    },
  },
});

export default queryClient;
