import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonItemOption,
  IonItemOptions,
  IonPage,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/react';
import React from 'react';
import './BooksFilterByListPage.scss';
import { useHistory, useParams } from 'react-router';

import BookCollection from '../components/ui/BookCollection';
import Header from '../components/ui/Header';
import { updateBookList } from '../data/book-lists';
import type { Book } from '../data/books';
import useBookList from '../hooks/use-book-list';
import useBooks from '../hooks/use-books';
import type { TabRoot } from '../urls';
import { tabRootName, URLs } from '../urls';
import minimumTime from '../util/minimum-time';

const BooksFilterByListPage: React.FC = () => {
  const history = useHistory();
  const params = useParams<{ tab: TabRoot; id: string }>();
  const { data: allBooks, isLoading: l1, error: e1, refetch: r1 } = useBooks();
  const { data: list, isLoading: l2, error: e2, refetch: r2 } = useBookList(params.id);
  const error = e1 || e2;
  const isLoading = l1 || l2;

  const refetch = async () => {
    await Promise.all([r1(), r2()]);
  };

  const books = allBooks?.filter((b) => list?.filter.bookIds?.includes(b.id)) || [];

  const tab = params.tab;
  const title = list?.name || '';

  const refresh = async (e: CustomEvent) => {
    await minimumTime(refetch, 1000);
    e.detail.complete();
  };

  const handleRemoveFromList = async (b: Book) => {
    if (!list || !list.filter.bookIds) {
      return;
    }

    list.filter.bookIds = list.filter.bookIds.filter((id) => id !== b.id);
    await updateBookList(list.id, {
      'filter.bookIds': list.filter.bookIds,
    });
  };

  // TODO: Cache this code maybe?
  return (
    <IonPage id="book-filter-by-list-page">
      <Header
        screenName="BooksByList"
        title={title}
        startSlot={
          <IonButtons slot="start">
            <IonBackButton defaultHref={URLs.tabLists()} text={tabRootName(tab) || 'Back'} />
          </IonButtons>
        }
      />

      <IonContent fullscreen={false}>
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent />
        </IonRefresher>
        {error && <p>Error: {error?.message}</p>}
        {allBooks && list && (
          <>
            {isLoading && <IonProgressBar type="indeterminate" slot="fixed" />}

            <BookCollection
              className="ion-margin-top"
              books={books}
              emptyMessage="You have not yet added any books to this list"
              deletable={(b) => (
                <IonItemOptions side="end">
                  <IonItemOption color="warning" onClick={() => handleRemoveFromList(b)}>
                    Remove
                  </IonItemOption>
                </IonItemOptions>
              )}
              onClick={(book) => history.push(URLs.booksDetails(tab, book.id))}
            />
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default BooksFilterByListPage;
