import { IonAlert, IonInfiniteScroll, IonInfiniteScrollContent, IonNote, IonSpinner } from '@ionic/react';
import type { ReactNode } from 'react';
import React, { Fragment, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import type { Book } from '../../data/books';
import { BookStatus, sortBooks, statusLabel } from '../../data/books';
import useOfferingName from '../../hooks/use-offering-name';
import useSharedPref from '../../hooks/use-shared-pref';
import { AnalyticsEvent, logEvent } from '../../services/analytics';
import { URLs } from '../../urls';
import { SharedPrefKey } from '../../util/shared-prefs';
import Notice from '../general/Notice';

import BookCount from './BookCount';
import BookGrid from './BookGrid';
import BookGridItem from './BookGridItem';
import { BookListItem } from './BookListItem';
import ButtonSelect from './ButtonSelect';
import useCanAccessPremium from '../../hooks/use-can-access-premium';
import './BookCollection.scss';
import classnames from 'classnames';

interface BookCollectionProps {
  books: Book[];
  onClick: (book: Book) => void;
  className?: string;
  emptyMessage?: string;
  showStatusFilter?: boolean;
  deletable?: boolean | ((b: Book) => ReactNode);
  totalBooks?: number;
}

const _initialFilters = Object.values(BookStatus);
const _pageSize = 100;

enum BookOrder {
  Added = '-added',
  Updated = '-updated',
  Author = '+author',
  Title = '+title',
  Rating = '-rating',
}

enum BookView {
  List = 'list',
  ListCompact = 'list_small',
  Grid = 'grid',
  GridCompact = 'grid_small',
}

const BookCollection: React.FC<BookCollectionProps> = ({
  books: allBooks,
  onClick,
  className,
  emptyMessage,
  showStatusFilter,
  deletable,
  totalBooks,
}) => {
  const history = useHistory();
  // Add 100 ms delay to these setters to give time for the filter selectors to close, since drawing all the books
  // can cause the animation to jitter
  const [statuses, setStatuses, ls] = useSharedPref<BookStatus[]>(SharedPrefKey.BooksStatusFilters, _initialFilters, 300);
  const [viewMode, setViewMode, lvm] = useSharedPref<string>(SharedPrefKey.BooksViewMode, BookView.List, 300);
  const [bookSort, setBookSort, lbs] = useSharedPref<string>(SharedPrefKey.BooksOrderBy, BookOrder.Added, 300);
  const loadingSettings = ls || lvm || lbs;

  const [showViewBooksLimitAlert, setShowViewBooksLimitAlert] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const canAccessPremium = useCanAccessPremium();
  const offeringName = useOfferingName();

  const books = useMemo<Book[]>(
    function booksMemo() {
      if (loadingSettings) return [];

      let items: Book[] = [...allBooks];
      if (showStatusFilter) {
        items = items.filter((b) => statuses.includes(b.status));
      }
      return sortBooks(bookSort, items);
    },
    [loadingSettings, allBooks.length, bookSort]
  );

  const shownBooks = books.slice(0, page * _pageSize);
  const isInfiniteDisabled = shownBooks.length >= books.length;
  const loadMoreForInfiniteScroll = (ev: any) => {
    setTimeout(() => {
      setPage((p) => p + 1);
      ev.target.complete();
    }, 300);
  };

  const inner = useMemo(
    function innerMemo() {
      if (shownBooks.length === 0) {
        // Nothing to do
        return null;
      }

      if (viewMode.includes('list')) {
        return shownBooks.map((b) => (
          <BookListItem
            key={b.id}
            book={b}
            onClick={onClick}
            deletable={typeof deletable === 'function' ? deletable(b) : deletable}
            size={viewMode.includes('small') ? 'small' : undefined}
          />
        ));
      }
      if (viewMode.includes('grid')) {
        return (
          <BookGrid>
            {shownBooks.map((b) => (
              <Fragment key={b.id}>
                <BookGridItem book={b} onClick={onClick} size={viewMode.includes('small') ? 'small' : undefined} />
              </Fragment>
            ))}
          </BookGrid>
        );
      }
    },
    [shownBooks.length, viewMode]
  );

  return (
    <div className={classnames('book-collection', className)}>
      <IonAlert
        isOpen={showViewBooksLimitAlert}
        onDidDismiss={() => setShowViewBooksLimitAlert(false)}
        header="Want more viewing options?"
        subHeader={`Upgrade to ${offeringName} to unlock more.`}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: `What's included in ${offeringName}?`,
            handler: () => history.push(URLs.subscribe()),
          },
        ]}
      />

      <div className="ion-margin-horizontal ion-padding-top">
        {showStatusFilter && (
          <ButtonSelect
            buttons
            header="Book Status"
            size="small"
            color="light"
            onComplete={(filters) => {
              logEvent(AnalyticsEvent.BooksFilterByStatus);
              setStatuses(filters);
            }}
            options={Object.values(BookStatus).map((s) => ({
              name: 'status',
              type: 'checkbox',
              label: statusLabel(s),
              value: s,
              checked: statuses.includes(s),
            }))}
          >
            {(() => {
              if (statuses.length === 1) {
                return statusLabel(statuses[0]);
              } else if (statuses.length === Object.keys(BookStatus).length) {
                return 'Any Status';
              } else if (statuses.length === 0) {
                return 'Select a Status';
              } else {
                return `Status (${statuses.length})`;
              }
            })()}
          </ButtonSelect>
        )}
        <ButtonSelect
          closeOnSelect
          header="Change Order"
          color="light"
          size="small"
          options={[BookOrder.Added, BookOrder.Updated, BookOrder.Author, BookOrder.Title, BookOrder.Rating].map(
            (s) => ({
              name: 'sort',
              type: 'radio',
              label: s.slice(1, 2).toUpperCase() + s.slice(2),
              value: s,
              checked: bookSort === s,
              handler: () => {
                setBookSort(s);
                logEvent(AnalyticsEvent.BooksSelectSort, { order: s });
              },
            })
          )}
        >
          Sort Order
        </ButtonSelect>
        <ButtonSelect
          closeOnSelect
          header="Change View"
          color="light"
          size="small"
          options={[BookView.List, BookView.Grid, BookView.ListCompact, BookView.GridCompact].map((s) => ({
            name: 'view',
            type: 'radio',
            label:
              (
                {
                  list: 'List',
                  list_small: (!canAccessPremium ? '★ ' : '') + 'Compact List',
                  grid: (!canAccessPremium ? '★ ' : '') + 'Grid',
                  grid_small: (!canAccessPremium ? '★ ' : '') + 'Compact Grid',
                } as any
              )[s] || s,
            value: s,
            checked: viewMode === s,
            handler: () => {
              if (!canAccessPremium && s !== 'list') {
                setShowViewBooksLimitAlert(true);
                logEvent(AnalyticsEvent.BillingUpsell, { reason: 'viewing' });
                return;
              }

              logEvent(AnalyticsEvent.BooksSelectView, { view: s });
              setViewMode(s);
            },
          }))}
        >
          View Mode
        </ButtonSelect>
      </div>
      <div className="ion-margin">
        {loadingSettings ? (
          <IonSpinner />
        ) : books.length === 0 ? (
          <Notice className="ion-margin-vertical">{emptyMessage}</Notice>
        ) : (
          <IonNote className="italic">
            Showing <BookCount n={books.length} total={totalBooks} />, sorted by {bookSort.slice(1)}
          </IonNote>
        )}
      </div>

      {inner}

      <IonInfiniteScroll onIonInfinite={loadMoreForInfiniteScroll} threshold="200px" disabled={isInfiniteDisabled}>
        <IonInfiniteScrollContent loadingSpinner="lines" />
      </IonInfiniteScroll>
    </div>
  );
};

export default BookCollection;
