import { App } from '@capacitor/app';
import { isPlatform } from '@ionic/react';

export default async function getAppVersion(): Promise<string> {
  if (!isPlatform('capacitor')) {
    return 'web';
  }
  const { version, build } = await App.getInfo();
  return `${version || '???'} (${build || -1})`;
}
