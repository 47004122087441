import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import type { BookList } from '../data/book-lists';
import { allBookLists } from '../data/book-lists';
import { getUid } from '../services/authentication';

import { setBookListCache } from './use-book-list';
import queryClient from '../query-client';

export default function useBookLists(): UseQueryResult<BookList[], Error> {
  const uid = getUid();
  return useQuery(['book_lists', { uid }], () => allBookLists(uid), {});
}

export function setBookListsCache(bookLists: BookList[]): void {
  const uid = getUid();
  queryClient.setQueryData(['book_lists', { uid }], bookLists);
  bookLists.map(setBookListCache); // Update singles too
}
