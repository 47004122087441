import {
  IonAlert,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonPopover,
  IonSpinner,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import React, { useState } from 'react';
import './SettingsPage.scss';

import DevOnly from '../components/general/DevOnly';
import Header from '../components/ui/Header';
import { deleteAllBooks } from '../data/books';
import useAppInfo from '../hooks/use-app-info';
import useBooks from '../hooks/use-books';
import useOfferingName from '../hooks/use-offering-name';
import useCustomerInfo from '../hooks/use-customer-info';
import useSharedPref from '../hooks/use-shared-pref';
import { AnalyticsEvent, logEvent } from '../services/analytics';
import { isAuthenticated, isUserAnonymous, logUserOut } from '../services/authentication';
import { manageSubscription } from '../services/billing';
import { URLs } from '../urls';

import {
  checkboxOutline,
  ellipsisHorizontal,
  ellipsisVertical,
  logOut,
  mailOpen,
  moon,
  personAdd,
  star,
  trashOutline,
} from 'ionicons/icons';

import { refreshDarkMode } from '../util/dark-mode';
import minimumTime from '../util/minimum-time';
import { SharedPrefKey } from '../util/shared-prefs';

import { Purchases } from '@awesome-cordova-plugins/purchases';

const SettingsPage: React.FC = () => {
  const [systemDarkMode, setSystemDarkMode] = useSharedPref(SharedPrefKey.SettingSystemDarkMode, true);
  const [darkMode, setDarkMode] = useSharedPref(SharedPrefKey.SettingDarkMode, false);
  const [sendUsage, setSendUsage] = useSharedPref(SharedPrefKey.SettingSendUsage, true);
  const [showLogoutConfirm, setShowLogoutConfirm] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<Event>();
  const { data: books } = useBooks();
  const [restoringPurchases, setRestoringPurchases] = useState<boolean>(false);
  const customerInfo = useCustomerInfo();
  const appInfo = useAppInfo();
  const offeringName = useOfferingName();

  const handleRestorePurchases = async () => {
    setRestoringPurchases(true);
    await minimumTime(async () => {
      try {
        const info = await Purchases.restorePurchases();
        if (!info.activeSubscriptions.length) {
          window.alert('No purchases found');
        } else {
          window.alert('Restored purchases');
        }
      } catch (err) {
        window.alert(`Restore Failed: ${err}`);
      }
    }, 1000);
    logEvent(AnalyticsEvent.BillingRestore);
    setRestoringPurchases(false);
  };

  const handleDarkModeChange = async (enabled: boolean) => {
    // For some reason this is called with the original value first
    if (enabled === darkMode) return;

    await setDarkMode(enabled);
    await refreshDarkMode();
    logEvent(AnalyticsEvent.SettingsDarkMode, { enabled: String(enabled) });
  };

  const handleSystemDarkModeChange = async (enabled: boolean) => {
    // For some reason this is called with the original value first
    if (enabled === systemDarkMode) return;

    await setSystemDarkMode(enabled);
    await refreshDarkMode();
    logEvent(AnalyticsEvent.SettingsSystemDarkMode, { enabled: String(enabled) });
  };

  const sendFeedback = () => {
    window.location.assign(
      'mailto:greg+kabook@schier.co' +
        `?subject=${encodeURIComponent('Kabook Question/Comment')}` +
        `&body=${encodeURIComponent('Hi Greg,\n\n')}`
    );
  };

  return (
    <>
      <IonPage>
        <Header
          screenName="Settings"
          title="Settings"
          endSlot={
            <DevOnly>
              <IonButtons slot="end">
                <IonPopover
                  isOpen={showPopover != null}
                  event={showPopover}
                  onDidDismiss={() => setShowPopover(undefined)}
                >
                  <IonList>
                    <IonItem button onClick={deleteAllBooks} lines="none" detailIcon={trashOutline}>
                      <IonText color="danger">Delete {books?.length || 0} Books</IonText>
                    </IonItem>
                    <IonItem onClick={() => setSendUsage(!sendUsage)}>
                      <IonLabel>Send Usage Statistics</IonLabel>
                      <IonIcon icon={checkboxOutline} />
                    </IonItem>
                  </IonList>
                </IonPopover>
                <IonButton onClick={(e) => setShowPopover(e.nativeEvent)}>
                  <IonIcon slot="icon-only" ios={ellipsisHorizontal} md={ellipsisVertical} />
                </IonButton>
              </IonButtons>
            </DevOnly>
          }
        />
        <IonContent fullscreen>
          <IonHeader collapse="condense" className="ion-margin-bottom">
            <IonToolbar>
              <IonTitle size="large">Settings</IonTitle>
            </IonToolbar>
          </IonHeader>

          <IonList>
            <IonItem detailIcon={star}>
              <IonToggle checked={systemDarkMode} onIonChange={(e) => handleSystemDarkModeChange(e.detail.checked)}>
                Use System Light/Dark Mode
              </IonToggle>
            </IonItem>

            {!systemDarkMode && (
              <IonItem detailIcon={moon}>
                <IonToggle checked={darkMode} onIonChange={(e) => handleDarkModeChange(e.detail.checked)}>
                  Dark Mode
                </IonToggle>
              </IonItem>
            )}
            <IonItem routerLink={URLs.importFromGoodReads()}>
              <IonLabel>Import from Goodreads</IonLabel>
            </IonItem>
            <IonItem button onClick={() => sendFeedback()} detailIcon={mailOpen}>
              <IonLabel>Send Feedback</IonLabel>
            </IonItem>

            <IonListHeader lines="inset" className="ion-margin-top">
              <IonLabel>Account</IonLabel>
              {customerInfo ? (
                <IonChip color={customerInfo.isActive ? 'primary' : 'tertiary'} className="ion-margin-end">
                  <strong>{customerInfo.isActive ? offeringName : 'Free'}</strong>
                </IonChip>
              ) : (
                <IonSpinner name="lines-small" />
              )}
            </IonListHeader>
            {customerInfo?.isActive && (
              <IonItem button onClick={() => manageSubscription(customerInfo)}>
                <IonLabel>Manage Subscription</IonLabel>
              </IonItem>
            )}
            {customerInfo && !customerInfo.isActive && (
              <IonItem button routerLink={URLs.subscribe()}>
                <IonLabel>Upgrade to Plus</IonLabel>
              </IonItem>
            )}
            {isPlatform('capacitor') && customerInfo && !customerInfo.isActive && (
              <IonItem button onClick={() => handleRestorePurchases()} detail={!restoringPurchases}>
                <IonLabel>Restore Purchases</IonLabel>
                {restoringPurchases && <IonSpinner name="lines-small" />}
              </IonItem>
            )}
            {isAuthenticated() && (
              <IonItem button onClick={() => setShowLogoutConfirm(true)} detailIcon={logOut}>
                <IonLabel>Sign Out</IonLabel>
              </IonItem>
            )}
            {isUserAnonymous() && (
              <>
                <IonItem button routerLink={URLs.welcome()} detailIcon={personAdd}>
                  <IonLabel>Sign In / Create Account</IonLabel>
                </IonItem>
              </>
            )}
          </IonList>

          <br />
          <br />
          <p className="ion-margin ion-text-center" style={{ fontSize: '0.9em' }}>
            <IonText color="medium">
              App Version
              <br />
              <strong>
                {appInfo?.version || ''} ({appInfo?.build || ''}){' '}
                <IonText color="primary">
                  <DevOnly>DEV</DevOnly>
                </IonText>
              </strong>
            </IonText>
          </p>
        </IonContent>
      </IonPage>
      <IonAlert
        header="Really sign out?"
        isOpen={showLogoutConfirm}
        buttons={[
          { text: 'Cancel', role: 'cancel', handler: () => setShowLogoutConfirm(false) },
          {
            text: 'Sign Out',
            handler: async () => {
              logEvent(AnalyticsEvent.AuthLogout);
              await logUserOut();

              // Just do a full reload to prevent having to clear
              // caches and things
              window.location.assign(URLs.root());
            },
          },
        ]}
      />
    </>
  );
};

export default SettingsPage;
