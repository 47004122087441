import { IonBackButton, IonContent, IonPage } from '@ionic/react';
import type { FC } from 'react';
import React from 'react';
import { useHistory, useParams } from 'react-router';

import EditBookForm from '../components/ui/EditBookForm';
import Header from '../components/ui/Header';
import useBook from '../hooks/use-book';
import { URLs } from '../urls';
import './BookEditPage.scss';

interface Props {
  router: HTMLIonRouterOutletElement | null;
}

const BookEditPage: FC<Props> = ({ router }) => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { data: book } = useBook(params.id);
  return (
    <IonPage id="edit-book-page">
      <Header screenName="EditBook" title="Edit Book" startSlot={<IonBackButton defaultHref={URLs.tabLibrary()} />} />
      <IonContent fullscreen={false}>
        {book && <EditBookForm book={book} onFinish={() => history.goBack()} router={router} />}
      </IonContent>
    </IonPage>
  );
};

export default BookEditPage;
