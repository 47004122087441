import type { AppInfo } from '@capacitor/app';
import { App } from '@capacitor/app';
import { isPlatform } from '@ionic/react';
import getAppVersion from './get-app-version';

export default async function getAppInfo(): Promise<AppInfo> {
  if (isPlatform('capacitor')) {
    return App.getInfo();
  }

  return {
    id: 'readrally',
    version: await getAppVersion(),
    build: 'web',
    name: 'Kabook!',
  };
}
