import { Timestamp } from 'firebase/firestore';

import { getUid } from '../services/authentication';

import type { Book } from './books';
import { BookStatus } from './books';

export interface GoogleBookVolumeInfo {
  authors?: string[];
  averageRating?: number;
  canonicalVolumeLink: string;
  categories: string[];
  contentVersion: string;
  description?: string;
  imageLinks?: { smallThumbnail: string; thumbnail: string };
  industryIdentifiers?: { type: string; identifier: string }[];
  infoLink: string;
  language: string;
  maturityRating: string;
  pageCount?: number;
  previewLink: string;
  printType: string;
  publishedDate: string;
  publisher: string;
  ratingsCount?: number;
  readingModes: { text: boolean; image: boolean };
  title: string;
}

export interface GoogleBook {
  id: string;
  etag: string;
  kind: string;
  selfLink: string;
  saleInfo: {
    country: string;
    isEbook: boolean;
    saleability: string;
  };
  volumeInfo: GoogleBookVolumeInfo;
}

interface GoogleSearchResultsSuccess {
  items: GoogleBook[];
}

interface GoogleSearchResultsError {
  error: {
    code: number;
    message: string;
    status: string;
    errors: { message: string; reason: string }[];
  };
}

export type GoogleSearchResults = GoogleSearchResultsSuccess | GoogleSearchResultsError;

export interface GoogleSearchParameters {
  q: string;
  filter?: null | 'partial' | 'full' | 'free-ebooks' | 'paid-ebooks' | 'ebooks';
  printType?: 'all' | 'books' | 'magazines';
  projection?: 'full' | 'lite';
  startIndex?: number;
  maxResults?: number;
  sorting?: 'relevance' | 'newest';

  // TODO: Partial responses: https://developers.google.com/books/docs/v1/performance#partial
  // fields: string,
}

const BASE_URL = 'https://www.googleapis.com/books/v1/volumes';

export enum SearchFilter {
  All = 'all',
  Isbn = 'isbn',
  Title = 'title',
  Author = 'author',
}

export async function searchBooks(
  q: string,
  filter: SearchFilter,
  params: Partial<GoogleSearchParameters> = {}
): Promise<GoogleSearchResults> {
  let query = q;
  switch (filter) {
    case SearchFilter.Title:
      query = `intitle:${q}`;
      break;
    case SearchFilter.Author:
      query = `inauthor:${q}`;
      break;
    case SearchFilter.Isbn:
      query = `isbn:${q}`;
      break;
    case SearchFilter.All:
      query = q;
      break;
  }

  const querystringParams: GoogleSearchParameters = {
    q: query,
    maxResults: 40,
    ...params,
  };

  const querystring = Object.entries(querystringParams)
    .filter(([key, value]) => key !== null && value !== null)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .map((v) => v.replace('%20', '+'))
    .join('&');

  const r = await fetch(`${BASE_URL}?${querystring}`);
  const results = await r.json();

  if ('error' in results) {
    console.log('[search] ERROR:', results.error);
  } else {
    console.log('[search]', querystringParams, results);
  }
  return results;
}

export function googleBookToBook(b: GoogleBook): Book {
  const { volumeInfo, id } = b;
  const { thumbnail } = volumeInfo.imageLinks || {};
  const authors = volumeInfo.authors || [];
  const industryIdentifiers = volumeInfo.industryIdentifiers || [];

  return {
    v: 10,
    id: '__UNSAVED__',
    gid: id,
    uid: getUid(),
    createdAt: Timestamp.now(),
    modifiedAt: Timestamp.now(),

    title: volumeInfo.title,
    description: volumeInfo.description || '',
    categories: volumeInfo.categories || [],
    note: '',
    status: BookStatus.Unread,
    statusEvents: [],
    authors: authors || [],
    language: volumeInfo.language,
    pageCount: volumeInfo.pageCount || -1,
    image: thumbnail?.replace('edge=curl', 'edge=none') || '',
    identifiers: industryIdentifiers,
    finishedAt: null,
    googleRating: volumeInfo.averageRating ? volumeInfo.averageRating / 5 : 0,
    googleRatingCount: volumeInfo.ratingsCount || 0,
    goodreadsRating: 0,
    publishDate: volumeInfo.publishedDate ? Timestamp.fromDate(new Date(volumeInfo.publishedDate)) : null,
    publisher: volumeInfo.publisher || '',
    rating: 0,
  };
}
