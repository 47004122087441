import type { ReactNode } from 'react';
import React from 'react';
import './BookGrid.scss';
import classnames from 'classnames';

interface BookGridProps {
  children: ReactNode,
  className?: string,
}

export const BookGrid: React.FC<BookGridProps> = ({ className, children }) => {
  return (
    <div className={classnames('book-grid', className)}>
      {children}
    </div>
  );
};

export default BookGrid;
