import { SplashScreen } from '@capacitor/splash-screen';
import { useEffect } from 'react';

export default function useSplashHide(): void {
  useEffect(() => {
    SplashScreen.hide().catch((err) => {
      console.log('Failed to hide splash screen', err);
    });
  }, []);
}
