import type { AppInfo } from '@capacitor/app';
import { useEffect, useState } from 'react';
import getAppInfo from '../util/get-app-info';

export default function useAppInfo(): AppInfo | undefined {
  const [info, setInfo] = useState<AppInfo>();

  useEffect(() => {
    getAppInfo().then(setInfo);
  }, []);

  return info;
}
