import type { PurchasesOffering } from '@awesome-cordova-plugins/purchases';
import { Purchases } from '@awesome-cordova-plugins/purchases';
import { useEffect, useState } from 'react';

export default function useOffering(): PurchasesOffering | undefined {
  const [offering, setOffering] = useState<PurchasesOffering>();

  useEffect(() => {
    Purchases.getOfferings()
      .then((offerings) => {
        setOffering(offerings.current || undefined);
      })
      .catch((err) => {
        console.log('[use-offerings] Failed to get info', err);
      });
  }, []);

  return offering;
}
