import { formatDistanceToNow } from 'date-fns';
import type { DocumentData, QueryDocumentSnapshot, QuerySnapshot, Transaction } from 'firebase/firestore';
import { arrayUnion, runTransaction, Timestamp, where } from 'firebase/firestore';
import Fuse from 'fuse.js';

import { getUid } from '../services/authentication';
import { collection, findDocs, firestore, restoreFirestoreTimestamps, getDoc, subscribeDocs } from '../services/firestore';
import Debouncer from '../util/debounce';

import type { BaseModel } from './models';
import type { GoogleBookVolumeInfo } from './search';
import { SearchFilter } from './search';

const _firestoreCollection = () => collection('books');
const _firestoreNewBook = () => getDoc(_firestoreCollection());
const _firestoreGetBook = (id: string) => getDoc(_firestoreCollection(), id);
const _firestoreAllBooks = (uid: string) => findDocs(_firestoreCollection(), where('uid', '==', uid));
const _firestoreSubAllBooks = (uid: string, fn: (snapshot: QuerySnapshot) => void) =>
  subscribeDocs(_firestoreCollection(), fn, where('uid', '==', uid));

export enum BookStatus {
  Want = 'want',
  Unread = 'unread',
  Reading = 'reading',
  Shelved = 'shelved',
  Finished = 'finished',
  Abandoned = 'abandoned',
}

type SchemaVersion = 10;

export interface BookStatusEvent {
  status: BookStatus;
  createdAt: Timestamp;
}

export interface Book extends BaseModel {
  id: '__UNSAVED__' | string;
  v: SchemaVersion;
  gid: string;
  uid: string;
  authors: string[];
  categories: string[];
  description: string;
  finishedAt: Timestamp | null;
  goodreadsRating: number;
  googleRating: number;
  googleRatingCount: number;
  identifiers: { type: string; identifier: string }[];
  image: string;
  language: string;
  note: string;
  pageCount: number;
  publishDate: Timestamp | null;
  publisher: string;
  status: BookStatus;
  statusEvents: BookStatusEvent[];
  title: string;
  rating: number; // Decimal between 0-1
}

export function statusLabel(status: BookStatus): string {
  switch (status) {
    case BookStatus.Want:
      return 'Wish List';
    case BookStatus.Unread:
      return 'Next Up';
    case BookStatus.Reading:
      return 'Reading';
    case BookStatus.Shelved:
      return 'On Hold';
    case BookStatus.Finished:
      return 'Finished';
    case BookStatus.Abandoned:
      return 'Abandoned';
  }
}

export function statusLabelShort(status: BookStatus): string {
  switch (status) {
    case BookStatus.Want:
      return 'Wish';
    case BookStatus.Unread:
      return 'Next';
    case BookStatus.Reading:
      return 'Progress';
    case BookStatus.Shelved:
      return 'Hold';
    case BookStatus.Finished:
      return 'Finished';
    case BookStatus.Abandoned:
      return 'Abandoned';
  }
}

export function statusColor(status: BookStatus): string {
  switch (status) {
    case BookStatus.Want:
      return 'secondary';
    case BookStatus.Unread:
      return 'tertiary';
    case BookStatus.Reading:
      return 'primary';
    case BookStatus.Finished:
      return 'success';
    case BookStatus.Abandoned:
      return 'danger';
    case BookStatus.Shelved:
      return 'warning';
  }
}

export function statusChangeLabel(book: Book): string {
  if (book.statusEvents.length === 0) {
    return formatDistanceToNow(book.createdAt.toDate());
  }
  return formatDistanceToNow(book.statusEvents[book.statusEvents.length - 1].createdAt.toDate());
}

export async function getBook(id: string): Promise<Book> {
  console.log('[books] Get book', id);
  const docRef = await _firestoreGetBook(id).get();

  // TODO: Handle case where book not found
  if (!docRef.exists) {
    throw new Error('Book not found');
  }

  return _migrateBook(docRef.data() as Book);
}

export async function subscribeToBooks(uid: string, callback: (books: Book[]) => void): Promise<() => void> {
  let initialResults = true;
  return new Promise((resolve) => {
    const unsubscribe = _firestoreSubAllBooks(uid, async (snapshot) => {
      // Debounce this one because there could be a lot of updates coming in
      // at once, and it triggers A LOT of expensive UI updates and sorting.
      const debouncer = new Debouncer(100);
      await debouncer.callWith(() => {
        console.log('[books] Snapshot change');

        callback(_migrateAndSortBooks(snapshot.docs));

        if (initialResults) {
          resolve(unsubscribe);
          initialResults = false;
        }
      });
    });
  });
}

export async function allBooks(uid: string): Promise<Book[]> {
  console.log('[books] Query all', uid);
  const result = await _firestoreAllBooks(uid);
  return _migrateAndSortBooks(result.docs);
}

export async function countBooks(uid: string): Promise<number> {
  console.log('[books] Count all', uid);
  const result = await _firestoreAllBooks(uid);
  return result.size;
}

export async function createBook(patch: Partial<Book>): Promise<string> {
  console.log('[books] Create');

  if (patch.id && patch.id !== '__UNSAVED__') {
    throw new Error('Book already has an id');
  }

  const ref = _firestoreNewBook();
  const book = newBook({ ...patch, id: ref.doc.id });

  // Add status event. There might be some already, so we push instead of set
  book.statusEvents.push({
    status: book.status,
    createdAt: Timestamp.now(),
  });

  await ref.set(book);
  return book.id;
}

export async function replaceBook(b: Book): Promise<void> {
  console.log('[books] Replace', b.id);

  if (b.id === '__UNSAVED__') {
    throw new Error('Cannot replace book without id');
  }

  await _firestoreGetBook(b.id).set(b);
}

export async function deleteBook(id: string): Promise<void> {
  console.log('[books] Delete', id);
  await _firestoreGetBook(id).delete();
}

export async function deleteAllBooks(): Promise<void> {
  const uid = getUid();
  if (uid !== 'KSksl0AWOYZSz2wU38NZFPfO4502') {
    return;
  }
  console.log('[books] Delete All');

  const books = await allBooks(uid);
  const BATCH_SIZE = 400;
  for (let batch = 0; batch < Math.ceil(books.length / BATCH_SIZE); batch++) {
    const batchBooks = books.slice(batch * BATCH_SIZE, batch * BATCH_SIZE + BATCH_SIZE);

    console.log('[books] Deleting batch', batch);
    await runTransaction(firestore, async (t: Transaction) => {
      for (const b of batchBooks) {
        const ref = _firestoreGetBook(b.id);
        t.delete(ref.doc);
      }
    });
  }
}

export async function updateBook(id: string, fields: Partial<Book>): Promise<void> {
  const book = await getBook(id);
  const latestStatusEvent = book.statusEvents[book.statusEvents.length - 1];
  const addStatusEvent = !latestStatusEvent || latestStatusEvent.status !== fields.status;

  // Add status event if updating status
  if (fields.status && addStatusEvent) {
    const e: BookStatusEvent = { status: fields.status, createdAt: Timestamp.now() };
    fields.statusEvents = arrayUnion(e) as any; // Ignore typescript
  }

  // Set finishedAt date if setting status
  if (fields.status && fields.status === BookStatus.Finished) {
    fields.finishedAt = Timestamp.now();
  }

  console.log('[books] Update', id, Object.keys(fields).join(', '));
  await _firestoreGetBook(id).update({
    ...fields,
    modifiedAt: Timestamp.now(),
  });
}

export function sortBooks(order: string, books: Book[]): Book[] {
  const direction = order.includes('-') ? -1 : 1;
  const orderWithoutDirection = order.replace(/[-+]/, '');

  // So sort() doesn't modify original array
  const booksCopy = [...books];

  if (orderWithoutDirection === 'author') {
    return booksCopy.sort((a, b) => {
      const authorA = a.authors[0] || '';
      const authorB = b.authors[0] || '';

      if (authorA === authorB) {
        // Sort by title if same author
        return b.title > a.title ? -direction : direction;
      }

      // Split name to only take last segment
      // Eg. David Heinemeier Hansson > Hansson
      const authorLastNameA = authorA.split(' ').slice(-1)[0] || '';
      const authorLastNameB = authorB.split(' ').slice(-1)[0] || '';

      return authorLastNameB > authorLastNameA ? -direction : direction;
    });
  }

  if (orderWithoutDirection === 'title') {
    return booksCopy.sort((a, b) => {
      return b.title > a.title ? -direction : direction;
    });
  }

  if (orderWithoutDirection === 'added') {
    return booksCopy.sort((a, b) => {
      return b.createdAt.seconds > a.createdAt.seconds ? -direction : direction;
    });
  }

  if (orderWithoutDirection === 'rating') {
    return booksCopy.sort((a, b) => {
      if (a.rating === b.rating) {
        // Sort by latest updated if ratings are the same
        return b.modifiedAt.seconds > a.modifiedAt.seconds ? -direction : direction;
      }
      return b.rating > a.rating ? -direction : direction;
    });
  }

  if (orderWithoutDirection === 'updated') {
    return booksCopy.sort((a, b) => {
      return b.modifiedAt.seconds > a.modifiedAt.seconds ? -direction : direction;
    });
  }

  return booksCopy;
}

export function fuzzySearchBooks(books: Book[], searchText: string, filter: SearchFilter, accuracy?: 'strict'): Book[] {
  let keys;
  switch (filter) {
    case SearchFilter.Author:
      keys = ['authors'];
      break;
    case SearchFilter.Isbn:
      keys = ['identifiers'];
      break;
    case SearchFilter.Title:
      keys = ['title'];
      break;
    default:
      keys = ['title', 'authors'];
      break;
  }

  return new Fuse(books, {
    keys,
    threshold: accuracy === 'strict' ? 0.1 : 0.4,
  })
    .search(searchText)
    .map((r) => r.item);
}

function _migrateAndSortBooks(docs: QueryDocumentSnapshot<DocumentData>[]): Book[] {
  const books = docs.map((d) => _migrateBook(d.data() as Book));
  return books.sort((a, b) => {
    const aDate = a.statusEvents[a.statusEvents.length - 1]?.createdAt || a.createdAt;
    const bDate = b.statusEvents[b.statusEvents.length - 1]?.createdAt || b.createdAt;
    return bDate.seconds - aDate.seconds;
  });
}

function _migrateBook(b: Book, dirty = false): Book {
  const schemaVersion = b.v as number;

  if (schemaVersion === 2) {
    return _migrateBook({ ...b, v: 3 as SchemaVersion, note: '' }, true);
  } else if (schemaVersion === 3) {
    return _migrateBook({ ...b, v: 4 as SchemaVersion, status: b.status.toLowerCase() as BookStatus }, true);
  } else if (schemaVersion === 4) {
    return _migrateBook({ ...b, v: 5 as SchemaVersion, modifiedAt: b.createdAt }, true);
  } else if (schemaVersion === 5) {
    return _migrateBook({ ...b, v: 6 as SchemaVersion, statusEvents: [] }, true);
  } else if (schemaVersion === 6) {
    // Dummy migration for testing
    return _migrateBook({ ...b, v: 7 as SchemaVersion }, true);
  } else if (schemaVersion === 7) {
    let finishedAt: Timestamp | null = null;
    for (const e of b.statusEvents) {
      if (e.status === BookStatus.Finished) {
        finishedAt = e.createdAt;
        break;
      }
    }

    // Doesn't have a _source?
    if (!(b as any)._source) {
      delete (b as any)._source;
      return _migrateBook(
        {
          ...b,
          v: 8 as SchemaVersion,
          finishedAt,
          publisher: '',
          publishDate: null,
          googleRating: 0,
          googleRatingCount: 0,
        },
        true
      );
    }

    // If _source, delete it and pull fields into main model
    const v = (b as any)._source.volumeInfo as GoogleBookVolumeInfo;
    delete (b as any)._source;
    return _migrateBook(
      {
        ...b,
        v: 8 as SchemaVersion,
        finishedAt,
        publisher: v.publisher || '',
        publishDate: v.publishedDate ? Timestamp.fromDate(new Date(v.publishedDate)) : null,
        googleRating: v.averageRating ? v.averageRating / 5 : 0,
        googleRatingCount: v.ratingsCount || 0,
      },
      true
    );
  } else if (schemaVersion === 8) {
    return _migrateBook({ ...b, v: 9 as SchemaVersion, categories: [] }, true);
  } else if (schemaVersion === 9) {
    return _migrateBook({ ...b, rating: 0, v: 10 as SchemaVersion }, true);
  }

  if (dirty) {
    // Save migration (in background so it doesn't block anything)
    console.log('[books] Updating after migration', b.id, '-->', b.v);
    replaceBook(b as Book).catch((err) => console.log('[books] Migrate failed to save', err));
  }

  return restoreFirestoreTimestamps(b);
}

export function newBook(patch: Partial<Book>): Book {
  return {
    id: '__UNSAVED__',
    image: '',
    v: 10,
    createdAt: Timestamp.now(),
    modifiedAt: Timestamp.now(),
    gid: '',
    uid: getUid(),
    authors: [],
    categories: [],
    description: '',
    finishedAt: null,
    googleRating: 0,
    googleRatingCount: 0,
    goodreadsRating: 0,
    identifiers: [],
    language: '',
    note: '',
    pageCount: 0,
    publishDate: null,
    publisher: '',
    status: BookStatus.Want,
    statusEvents: [],
    title: '',
    rating: 0,
    ...patch,
  };
}
