import type { User as FirebaseUser } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';

import {
  currentFirebaseUser,
  getFirebaseUser,
  getUserDisplayName,
  isUserAnonymous,
  onAuthStateChanged,
} from '../services/authentication';
import { collection, restoreFirestoreTimestamps, getDoc } from '../services/firestore';
import getAppPlatform from '../util/get-app-platform';
import getAppVersion from '../util/get-app-version';

import { countBookLists } from './book-lists';
import { countBooks } from './books';
import type { BaseModel } from './models';

const _firestoreCollection = () => collection('users');
const _firestoreGetUser = (id: string) => getDoc(_firestoreCollection(), id);

let _hasUpdatedLaunches = false;

type SchemaVersion = 6;

export interface User extends BaseModel {
  v: SchemaVersion;
  admin: boolean;
  displayName: string;
  lastLaunchedAt: Timestamp;
  appPlatform: string;
  numAppLaunches: number;
  numBooks: number;
  numBookLists: number;
  isAnonymous: boolean;
}

export async function getCurrentUser(): Promise<User> {
  const firebaseUser = await getFirebaseUser();
  return getUser(firebaseUser);
}

export async function getUser(firebaseUser: FirebaseUser | null = null): Promise<User> {
  const currentUser: FirebaseUser | null = firebaseUser || currentFirebaseUser();
  if (!currentUser) {
    throw new Error('User not authenticated');
  }

  const doc = _firestoreGetUser(currentUser.uid);
  const result = await doc.get();

  // If user object does not yet exist, create one.
  if (!result.exists()) {
    const user: User = {
      v: 6,
      id: currentUser.uid,
      displayName: currentUser.displayName || 'n/a',
      createdAt: Timestamp.now(),
      modifiedAt: Timestamp.now(),
      lastLaunchedAt: Timestamp.now(),
      isAnonymous: currentUser.isAnonymous,
      numAppLaunches: 1,
      numBooks: 0,
      numBookLists: 0,
      admin: false,
      appPlatform: getAppPlatform(),
    };
    console.log('[auth] Creating user', user);
    await doc.set(user);
    return user;
  }

  const user = _migrateUser(result.data() as User);

  let numAppLaunches = user.numAppLaunches;
  if (!_hasUpdatedLaunches) {
    numAppLaunches++;
    _hasUpdatedLaunches = true;
  }

  // Update stats in background
  doc
    .update({
      modifiedAt: Timestamp.now(),
      lastLaunchedAt: Timestamp.now(),
      numAppLaunches: numAppLaunches,
      numBooks: await countBooks(user.id),
      numBookLists: await countBookLists(user.id),
      isAnonymous: currentUser.isAnonymous,
      appPlatform: getAppPlatform(),
      appVersion: await getAppVersion(),
    })
    .catch((err) => console.log('[users] Failed to update stats', err));

  console.log('[auth] Existing user', user.id);
  return user;
}

async function replaceUser(u: User): Promise<void> {
  console.log('[users] Replace', u.id);
  await _firestoreGetUser(u.id).set(u);
}

export function subscribeToUserChange(callback: (user: User | null) => void): void {
  onAuthStateChanged(async () => {
    const currentUser = await getFirebaseUser();
    if (!currentUser) {
      return;
    }
    const user = await getUser(currentUser);
    callback(user);
  });
}

function _migrateUser(u: User, dirty = false): User {
  const schemaVersion = (u.v || 1) as number;

  if (schemaVersion === 2) {
    return _migrateUser(
      {
        ...u,
        v: 3 as SchemaVersion,
        admin: false,
        displayName: getUserDisplayName(),
        createdAt: u.createdAt || Timestamp.now(),
        modifiedAt: u.createdAt || Timestamp.now(),
        lastLaunchedAt: u.createdAt || Timestamp.now(),
        appPlatform: getAppPlatform(),
        numAppLaunches: u.numAppLaunches || 0,
        numBooks: u.numBooks || 0,
      },
      true
    );
  } else if (schemaVersion === 3) {
    return _migrateUser({ ...u, v: 4 as SchemaVersion, isAnonymous: isUserAnonymous() }, true);
  } else if (schemaVersion === 4) {
    // HACK: Migrate wish-list status to a BookList. Do it here because it's
    // the only place we can reliably, without doing twice.
    //
    // _createWishList(u.id).catch(err => console.log(err));
    // NOTE: Ended up not doing this in the end, but keeping this here for now
    return _migrateUser({ ...u, v: 5 as SchemaVersion }, true);
  } else if (schemaVersion === 5) {
    return _migrateUser({ ...u, v: 6, numBookLists: 0 }, true);
  }

  if (dirty) {
    // Save migration (in background so that it doesn't block anything)
    console.log('[books] Updating after migration', u.id, '-->', u.v);
    replaceUser(u as User).catch((err) => console.log('[users] Migrate failed to save', err));
  }

  return restoreFirestoreTimestamps(u);
}
