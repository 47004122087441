import React from 'react';

import BookCover from './BookCover';

import './BookGridItem.scss';
import type { Book } from '../../data/books';

import classnames from 'classnames';
import StarRatingDisplay from '../general/StarRatingDisplay';

interface BookGridItemProps {
  book: Book;
  onClick: (book: Book) => any;
  size?: 'small' | 'tiny';
}

export const BookGridItem: React.FC<BookGridItemProps> = ({ size, book, onClick }) => {
  return (
    <div className={classnames('book-grid-item', `size-${size}`)} onClick={() => onClick(book)} data-id={book.id} data-gid={book.gid}>
      <div>
        <BookCover book={book} />
        <h2>{book.title}</h2>
        <h3>{book.authors.join(', ')}</h3>
        {book.rating > 0 && size !== 'tiny' && (
          <StarRatingDisplay
            rating={book.rating}
            color="medium"
            size={size === 'small' ? 'small' : undefined}
          />
        )}
      </div>
    </div>
  );
};

export default BookGridItem;
