import type { CSSProperties, ReactNode } from 'react';
import React, { useMemo } from 'react';

import './BookCover.scss';
import type { Book } from '../../data/books';
import { BookStatus, statusColor, statusLabel } from '../../data/books';
import CheckMark from '../icons/Checkmark';

import classnames from 'classnames';
import useCachedImageDataUri from '../../hooks/use-cached-image-data-uri';

interface BookCoverProps {
  book: Book | null;
  slot?: string;
  hideStatusLabel?: boolean;
  hideStatus?: boolean;
  onClick?: () => any;
  style?: CSSProperties;
  className?: string;
}

const icons: { [status: string]: ReactNode } = {
  [BookStatus.Finished]: <CheckMark />,
  [BookStatus.Abandoned]: null,
  [BookStatus.Unread]: null,
  [BookStatus.Reading]: null,
  [BookStatus.Shelved]: null,
};

export const BookCover: React.FC<BookCoverProps> = ({
  hideStatusLabel,
  hideStatus,
  book,
  slot,
  onClick,
  style,
  className,
}) => {
  const bookId = book?.id || Math.random() + '';
  const bookImage = book?.image || '';
  const bookStatus = book?.status || '';

  // Use secure URLs
  const uri = useCachedImageDataUri(bookImage.replace(/^http:\/\//, 'https://').replace('edge=curl', 'edge=none'));

  const icon = bookId === '__UNSAVED__' ? null : icons[bookStatus];
  const color = (o: any) => `rgba(var(--ion-color-${bookStatus ? statusColor(bookStatus) : 'medium'}-rgb), ${o})`;
  const boxShadow = hideStatus ? `inset 0 0 10px ${color(0.1)}` : undefined;
  const backgroundImage = uri ? `url('${uri}')` : undefined;
  const backgroundColor = bookStatus ? color(0.1) : undefined;
  const memoStyle = useMemo(
    () => ({
      backgroundColor,
      backgroundImage,
      boxShadow,
      ...style,
    }),
    [style, backgroundImage, backgroundColor, boxShadow]
  );

  return (
    <div
      aria-hidden
      slot={slot}
      style={memoStyle}
      className={classnames('book-cover', className)}
      data-status={bookStatus}
      onClick={onClick}
    >
      {!hideStatus && (
        <>
          {icon}
          <div className="border-overlay" style={{ borderColor: color(1), boxShadow: `1px 1px 3px ${color(0.5)}` }} />
          {!hideStatusLabel && (
            <div className="status-overlay" style={{ backgroundColor: color(1) }}>
              {!hideStatusLabel && (bookStatus ? statusLabel(bookStatus) : '')}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BookCover;
