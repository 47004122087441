import { getPref, SharedPrefKey } from './shared-prefs';
import { StatusBar, Style } from '@capacitor/status-bar';
import { isPlatform } from '@ionic/react';

export async function initDarkMode(): Promise<void> {
  console.log('[index] Initializing dark mode');
  await refreshDarkMode();

  // Listen for changes to the prefers-color-scheme media query
  mediaQuery().addEventListener('change', () => refreshDarkMode());
}

export async function refreshDarkMode(): Promise<void> {
  const useSystemDarkMode = await getPref(SharedPrefKey.SettingSystemDarkMode, true);
  const darkMode = await getPref(SharedPrefKey.SettingDarkMode, false);

  // Dark/light mode logic // Use matchMedia to check the user preference
  await _toggleDarkMode(mediaQuery().matches, useSystemDarkMode, darkMode);
}

// Add or remove the "dark" class based on if the media query matches
async function _toggleDarkMode(systemIsDark: boolean, useSystemDarkMode: boolean, darkMode: boolean) {
  const meta = document.querySelector('meta[name="color-scheme"]'); // This is in public/index.html

  let isActuallyDark = false;
  if (useSystemDarkMode && systemIsDark) {
    isActuallyDark = true;
  } else if (!useSystemDarkMode && darkMode) {
    isActuallyDark = true;
  }

  if (isActuallyDark) {
    meta?.setAttribute('content', 'dark');
    document.body.classList.toggle('dark', true);
  } else {
    meta?.setAttribute('content', 'light');
    document.body.classList.toggle('dark', false);
  }

  try {
    await StatusBar.setStyle({ style: isActuallyDark ? Style.Dark : Style.Light });
    if (isPlatform('android')) await StatusBar.setBackgroundColor({ color: isActuallyDark ? '#000000' : '#FFFFFF' });
  } catch (err) {
    // Likely an unsupported platform
  }
}

function mediaQuery(): MediaQueryList {
  return window.matchMedia('(prefers-color-scheme: dark)');
}
