import { IonApp, IonRouterOutlet, isPlatform, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React, { useRef } from 'react';
import { Redirect, Route } from 'react-router-dom';
import useOrientationLock, { Orientation } from './hooks/use-orientation-lock';
import useSplashHide from './hooks/use-splash-hide';
import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import './theme/variables.scss';
import './App.scss';
import BookCreatePage from './pages/BookCreatePage';
import BookEditPage from './pages/BookEditPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import GoodReadsImportPage from './pages/GoodReadsImportPage';
import LoginPage from './pages/LoginPage';
import MainTabs from './pages/MainTabs';
import SignUpPage from './pages/SignUpPage';
import SubscribePage from './pages/SubscribePage';
import WelcomePage from './pages/WelcomePage';
import { URLs } from './urls';
import BackButton from './components/general/BackButton';
import useFirebaseUser from './hooks/use-firebase-user';

setupIonicReact({
  // Force ios theme on desktop (because it's much nicer)
  mode: isPlatform('desktop') ? 'ios' : undefined,
});

const App: React.FC = () => {
  useSplashHide();
  useOrientationLock(Orientation.PortraitPrimary);
  const routerOutletRef = useRef<HTMLIonRouterOutletElement>(null);
  const { isLoading: userIsLoading, data: user } = useFirebaseUser();
  if (userIsLoading) {
    return null;
  }

  return (
    <IonApp className="app-wrapper">
      <IonReactRouter>
        <BackButton>
          <IonRouterOutlet id="main" ref={routerOutletRef}>
            {/* Isolated book pages */}
            <Route exact path={URLs.booksCreate()}>
              <BookCreatePage router={routerOutletRef.current}/>
            </Route>
            <Route exact path={URLs.booksEdit(':id')}>
              <BookEditPage router={routerOutletRef.current}/>
            </Route>
            <Route exact path={URLs.importFromGoodReads()}>
              <GoodReadsImportPage />
            </Route>

            {/* Authentication */}
            <Route exact path={URLs.welcome()}>
              <WelcomePage />
            </Route>
            <Route exact path={URLs.login()}>
              <LoginPage />
            </Route>
            <Route exact path={URLs.signup()}>
              <SignUpPage />
            </Route>
            <Route exact path={URLs.forgotPassword()}>
              <ForgotPasswordPage />
            </Route>

            {/* Billing */}
            <Route exact path={URLs.subscribe()}>
              <SubscribePage />
            </Route>

            {/* NOTE: Blank route causes issues on first-launch of iOS app */}
            {/* TODO: Implement 404 page, maybe? */}
            <Route exact path={URLs.root()}>
              <Redirect to={user ? URLs.tabReading() : URLs.welcome()} />
            </Route>
          </IonRouterOutlet>

          <Route path={URLs.tabs()}>
            <MainTabs />
          </Route>
        </BackButton>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
