import { IonRouterOutlet } from '@ionic/react';
import React, { useRef } from 'react';
import { Redirect, Route } from 'react-router-dom';

import TabBar from '../components/ui/TabBar';
import { URLs } from '../urls';

import AllBooksPage from './AllBooksPage';
import BookDetailsPage from './BookDetailsPage';
import BooksFilterByListPage from './BooksFilterByListPage';
import BooksFilterPage from './BooksFilterPage';
import LibraryPage from './LibraryPage';
import ListsPage from './ListsPage';
import ReadingPage from './ReadingPage';
import './MainTabs.scss';
import SettingsPage from './SettingsPage';

const MainTabs: React.FC = () => {
  const routerOutletRef = useRef<HTMLIonRouterOutletElement>(null);
  return (
    <TabBar>
      <IonRouterOutlet ref={routerOutletRef}>
        <Route exact path={URLs.tabs()}>
          <Redirect to={URLs.tabReading()} />
        </Route>

        <Route exact path={URLs.tabReading()}>
          <ReadingPage router={routerOutletRef.current} />
        </Route>
        <Route exact path={URLs.tabLibrary()}>
          <LibraryPage router={routerOutletRef.current} />
        </Route>
        <Route exact path={URLs.tabSettings()}>
          <SettingsPage />
        </Route>
        <Route exact path={URLs.tabLists()}>
          <ListsPage router={routerOutletRef.current} />
        </Route>

        {/* Book Pages */}
        <Route exact path={URLs.booksAll(':tab')}>
          <AllBooksPage router={routerOutletRef.current} />
        </Route>
        <Route exact path={URLs.booksByStatus(':tab', ':status')}>
          <BooksFilterPage />
        </Route>
        <Route exact path={URLs.booksByList(':tab', ':id')}>
          <BooksFilterByListPage />
        </Route>
        <Route exact path={URLs.booksDetails(':tab', ':id')}>
          <BookDetailsPage />
        </Route>
      </IonRouterOutlet>
    </TabBar>
  );
};

export default MainTabs;
