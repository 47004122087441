import React from 'react';

const Person: React.FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 1020 735" version="1.1" style={{
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinejoin: 'round',
      strokeMiterlimit: 2,
    }}>
      <ellipse cx={502} cy="701.696" rx={259} ry="32.5" style={{ fill: 'rgb(63,61,86)' }}/>
      <g transform="matrix(1,0,0,1,-90,-82.9019)">
        <path d="M1030.12,506.711L930,554.098L1018.62,487.836C1004.21,465.356 988.033,444.065 970.231,424.168L536,532.098L928.233,382.475C910.31,366.66 891.275,352.152 871.276,339.06L209.609,445.638C139.372,533.469 101.06,642.636 101.006,755.098L1096.99,755.098C1096.95,667.867 1073.88,582.173 1030.12,506.71Z" style={{
          fill: 'rgb(242,242,242)',
          fillRule: 'nonzero',
        }}/>
      </g>
      <g transform="matrix(1,0,0,1,-90,-82.9019)">
        <path d="M817.123,308.293C749.193,275.161 674.579,257.991 599,258.098C445.17,258.098 307.639,327.851 216.29,437.447C372.716,403.172 645.067,343.841 817.123,308.293Z" style={{
          fill: 'rgb(242,242,242)',
          fillRule: 'nonzero',
        }}/>
      </g>
      <circle cx="825.08" cy="258.36" r="14.92" style={{ fill: 'rgb(137,54,233)', fillOpacity: 0.4 }}/>
      <circle cx="797.466" cy="265.486" r="10.466" style={{ fill: 'rgb(137,54,233)', fillOpacity: 0.4 }}/>
      <circle cx="118.752" cy="297.898" r="31.248" style={{ fill: 'rgb(137,54,233)', fillOpacity: 0.4 }}/>
      <circle cx="60.92" cy="312.822" r="21.92" style={{ fill: 'rgb(137,54,233)', fillOpacity: 0.4 }}/>
      <rect x={0} y="671.196" width={1020} height={2} style={{ fill: 'rgb(47,46,65)' }}/>
      <g transform="matrix(1,0,0,1,-90,-82.9019)">
        <path d="M994.441,754.098L915,636.098C915,636.098 1035.31,678.098 1021.65,754.098L994.441,754.098Z" style={{
          fill: 'rgb(63,61,86)',
          fillRule: 'nonzero',
        }}/>
      </g>
      <g transform="matrix(1,0,0,1,-90,-82.9019)">
        <path d="M997.559,754.098L1077,636.098C1077,636.098 956.693,678.098 970.346,754.098L997.559,754.098Z" style={{
          fill: 'rgb(137,54,233)',
          fillRule: 'nonzero',
        }}/>
      </g>
      <g transform="matrix(1,0,0,1,-90,-82.9019)">
        <path d="M298,215.673C298,209.176 288.736,203.909 277.309,203.909C277.083,203.909 276.859,203.915 276.634,203.919C273.187,193.352 256.891,185.349 237.305,185.349C220.642,185.349 206.36,191.142 200.345,199.379C195.902,198.203 191.323,197.617 186.727,197.635C169.205,197.635 155,205.711 155,215.673C155,224.722 166.722,232.213 182.004,233.51C193.108,235.541 210.454,236.847 229.949,236.847C263.045,236.847 287.262,230.534 287.87,225.788C293.934,223.737 298,219.976 298,215.673Z" style={{
          fill: 'rgb(242,242,242)',
          fillRule: 'nonzero',
        }}/>
      </g>
      <g transform="matrix(1,0,0,1,-90,-82.9019)">
        <path d="M798,220.673C798,214.176 788.736,208.909 777.309,208.909C777.083,208.909 776.859,208.915 776.634,208.919C773.187,198.352 756.891,190.349 737.305,190.349C720.642,190.349 706.36,196.142 700.345,204.379C695.902,203.203 691.323,202.617 686.727,202.635C669.205,202.635 655,210.711 655,220.673C655,229.722 666.722,237.213 682.004,238.51C693.108,240.541 710.454,241.847 729.949,241.847C763.045,241.847 787.262,235.534 787.87,230.788C793.934,228.737 798,224.976 798,220.673Z" style={{
          fill: 'rgb(242,242,242)',
          fillRule: 'nonzero',
        }}/>
      </g>
      <g transform="matrix(1,0,0,1,-90,-82.9019)">
        <path d="M1077,136.128C1077,124.724 1060.74,115.48 1040.68,115.48C1040.29,115.48 1039.89,115.49 1039.5,115.497C1033.45,96.949 1004.84,82.902 970.466,82.902C941.217,82.902 916.149,93.071 905.592,107.528C897.793,105.464 889.756,104.435 881.688,104.468C850.932,104.468 826,118.642 826,136.128C826,152.011 846.575,165.161 873.398,167.437C892.889,171.001 923.335,173.294 957.553,173.294C1015.65,173.294 1058.15,162.213 1059.22,153.883C1069.86,150.283 1077,143.681 1077,136.128Z" style={{
          fill: 'rgb(242,242,242)',
          fillRule: 'nonzero',
        }}/>
      </g>
      <g id="person">
        <g transform="matrix(1,0,0,1,-90,-82.9019)">
          <path d="M660.17,372.809C639.485,337.859 598.562,336.23 598.562,336.23C598.562,336.23 558.686,331.13 533.105,384.361C509.261,433.975 476.355,481.88 527.807,493.494L537.101,464.568L542.856,495.648C550.182,496.174 557.531,496.3 564.871,496.024C619.972,494.245 672.448,496.544 670.759,476.772C668.512,450.487 680.073,406.439 660.17,372.809Z" style={{
            fill: 'rgb(47,46,65)',
            fillRule: 'nonzero',
          }}/>
        </g>
        <g transform="matrix(1,0,0,1,-90,-82.9019)">
          <path d="M524.869,678.888C524.262,682.732 523.38,686.528 522.231,690.246C520.936,693.886 518.938,697.252 517.754,700.929C513.982,712.651 519.212,725.72 527.564,734.767C534.807,742.581 544.158,748.134 554.486,750.753C562.021,752.648 569.856,752.921 577.621,753.184C599.124,753.913 621.18,754.566 641.555,747.655C647.992,745.418 654.21,742.594 660.129,739.217C661.998,738.326 663.645,737.03 664.949,735.422C666.503,733.209 666.702,730.34 666.688,727.637C666.643,718.521 664.795,709.452 665.233,700.347C665.476,695.304 666.42,690.264 665.956,685.236C664.641,673.564 656.084,663.92 644.652,661.224C638.872,659.983 632.877,660.659 626.992,661.225C611.717,662.693 596.377,663.379 581.033,663.28C565.354,663.18 549.772,660.787 534.172,660.537C529.558,660.463 529.652,661.885 528.183,666.35C526.83,670.46 525.723,674.646 524.869,678.888Z" style={{
            fill: 'rgb(47,46,65)',
            fillRule: 'nonzero',
          }}/>
        </g>
        <g transform="matrix(1,0,0,1,-90,-82.9019)">
          <path d="M569.364,453.802C568.61,457.747 567.796,461.837 565.425,465.079C562.697,468.809 558.25,470.94 555.316,474.51C550.899,479.883 550.645,487.444 550.627,494.4C550.61,500.807 550.74,507.642 554.314,512.96C556.633,516.412 560.161,518.832 563.61,521.156C577.01,530.187 591.49,539.38 607.647,539.678C614.341,539.801 621.519,538.072 625.924,533.03C627.825,530.661 629.321,527.993 630.349,525.135C634.48,514.952 636.984,504.182 637.77,493.221C638.137,488.064 638.102,482.756 636.357,477.889C634.394,472.417 630.425,467.915 627.803,462.726C624.702,456.557 623.568,449.584 624.553,442.751C624.684,442.269 624.644,441.758 624.439,441.303C624.002,440.81 623.359,440.549 622.702,440.599L590.666,438.293C587.682,438.144 584.711,437.804 581.771,437.275C580.044,436.924 575.483,434.471 573.836,435.123C570.788,436.331 569.988,450.531 569.364,453.802Z" style={{
            fill: 'rgb(255,184,184)',
            fillRule: 'nonzero',
          }}/>
        </g>
        <g transform="matrix(1,0,0,1,-90,-82.9019)">
          <path d="M569.364,453.802C568.61,457.747 567.796,461.837 565.425,465.079C562.697,468.809 558.25,470.94 555.316,474.51C550.899,479.883 550.645,487.444 550.627,494.4C550.61,500.807 550.74,507.642 554.314,512.96C556.633,516.412 560.161,518.832 563.61,521.156C577.01,530.187 591.49,539.38 607.647,539.678C614.341,539.801 621.519,538.072 625.924,533.03C627.825,530.661 629.321,527.993 630.349,525.135C634.48,514.952 636.984,504.182 637.77,493.221C638.137,488.064 638.102,482.756 636.357,477.889C634.394,472.417 630.425,467.915 627.803,462.726C624.702,456.557 623.568,449.584 624.553,442.751C624.684,442.269 624.644,441.758 624.439,441.303C624.002,440.81 623.359,440.549 622.702,440.599L590.666,438.293C587.682,438.144 584.711,437.804 581.771,437.275C580.044,436.924 575.483,434.471 573.836,435.123C570.788,436.331 569.988,450.531 569.364,453.802Z" style={{
            fillOpacity: 0.1,
            fillRule: 'nonzero',
          }}/>
        </g>
        <circle cx={510} cy="328.196" r={47} style={{ fill: 'rgb(255,184,184)' }}/>
        <g transform="matrix(1,0,0,1,-90,-82.9019)">
          <path d="M575.956,516.367C578.405,518.927 581.001,521.462 584.211,522.963C587.479,524.314 590.989,524.982 594.524,524.927C602.996,525.165 612.181,523.613 618.074,517.522C625.672,509.67 625.421,497.007 631.376,487.847C631.856,487.016 632.511,486.3 633.295,485.748C634.294,485.204 635.423,484.945 636.559,484.998C644.08,484.927 650.631,489.805 656.544,494.454C659.193,496.536 661.907,498.694 663.578,501.62C664.747,503.901 665.548,506.353 665.951,508.884C668.885,523.207 669.835,537.852 670.778,552.442C671.299,560.501 671.82,568.568 671.781,576.643C671.654,603.248 665.47,629.409 659.32,655.294C658.528,658.63 657.444,662.358 654.411,663.955C653.058,664.563 651.606,664.918 650.126,665.001C632.513,667.069 614.863,669.14 597.131,669.414C590.211,669.52 583.294,669.353 576.38,668.911C575.189,668.895 574.009,668.69 572.884,668.302C571.357,667.568 570.044,666.454 569.073,665.065C559.481,652.979 558.915,636.269 558.977,620.838C559.126,583.113 560.474,545.418 563.02,507.754C563.23,504.645 563.276,500.869 561.036,498.424C565.07,504.881 570.68,510.852 575.956,516.367Z" style={{
            fill: 'rgb(208,205,225)',
            fillRule: 'nonzero',
          }}/>
        </g>
        <g transform="matrix(1,0,0,1,-90,-82.9019)">
          <path d="M566,462.098C564.413,464.972 552.709,472.107 550.45,474.488C537.826,487.795 514.786,487.295 503.241,501.547C499.179,506.562 497.068,512.862 495.516,519.126C493.321,527.985 492.149,537.646 495.826,545.999C499.365,554.037 506.814,559.594 511.871,566.774C517.424,574.657 519.921,584.229 522.313,593.57C524.08,600.467 525.861,607.535 525.173,614.621C524.391,622.678 520.492,630.056 518.19,637.817C515.888,645.577 515.491,654.821 520.813,660.92C524.724,665.4 530.857,667.177 536.72,668.176C544.229,669.304 551.801,669.963 559.391,670.15C566.093,670.446 573.48,670.503 578.632,666.207C582.698,662.817 584.464,657.316 584.693,652.028C584.922,646.739 583.822,641.498 583.132,636.25C582.418,630.81 582.143,625.322 581.914,619.839C580.883,595.241 580.742,570.626 581.492,545.996C581.648,540.871 581.837,535.683 580.744,530.673C579.307,524.084 575.746,518.196 572.775,512.142C566.877,500.126 564.088,488.453 565,475.098C565.9,461.916 571.649,451.873 566,462.098Z" style={{
            fill: 'rgb(137,54,233)',
            fillRule: 'nonzero',
          }}/>
        </g>
        <g transform="matrix(1,0,0,1,-90,-82.9019)">
          <path d="M632,463.098C634.418,465.583 642.634,466.402 645.71,468.003C648.603,469.339 651.622,470.385 654.722,471.123C665.941,474.36 676.613,479.258 686.382,485.654C688.411,486.841 690.206,488.391 691.676,490.227C693.397,492.621 694.021,495.608 694.605,498.497C696.111,505.957 697.624,513.507 697.286,521.111C696.927,529.181 694.496,536.998 692.084,544.708L683.072,573.517C676.346,595.021 669.577,616.737 667.684,639.189C666.637,651.632 667.103,664.157 669.071,676.488C669.269,677.728 669.435,679.149 668.616,680.101C668.023,680.687 667.241,681.047 666.41,681.116C662.061,681.796 657.851,679.578 653.911,677.616C641.867,671.622 629.013,667.417 615.756,665.132C620.936,657.645 624.934,649.405 627.607,640.702C629.125,635.175 630.32,629.565 631.187,623.9L635.379,599.655C637.085,590.793 638.285,581.842 638.975,572.844C639.292,569.467 639.095,566.062 638.392,562.744C637.426,559.587 636.143,556.535 634.563,553.636C627.655,539.266 624.413,522.866 627.131,507.155C628.321,500.279 630.633,493.394 629.669,486.483C628.742,479.838 629.705,474.674 628,468.098C621,441.098 627.451,458.423 632,463.098Z" style={{
            fill: 'rgb(137,54,233)',
            fillRule: 'nonzero',
          }}/>
        </g>
        <g transform="matrix(1,0,0,1,-90,-82.9019)">
          <path d="M503.121,524.268C499.972,530.804 494.706,536.372 492.914,543.402C492.217,546.688 491.918,550.045 492.024,553.403C491.981,558.462 491.954,563.624 493.508,568.44C494.508,571.54 496.135,574.399 497.307,577.438C498.941,581.685 499.67,586.226 499.445,590.77C499.128,597.065 496.99,603.177 496.822,609.478C496.56,619.387 501.143,628.703 505.615,637.549C508.759,629.764 516.354,624.64 524.75,624.64C528.587,624.64 532.349,625.71 535.611,627.729C536.791,626.64 536.917,624.85 536.963,623.245C537.293,611.749 537.612,600.252 537.919,588.755C538.404,570.974 538.865,552.956 534.774,535.645C532.928,527.829 530.054,520.044 524.786,513.982C522.013,510.79 512.596,502.382 508.043,505.958C505.995,507.566 506.474,511.943 506.149,514.16C505.66,517.664 504.639,521.072 503.121,524.268Z" style={{
            fill: 'rgb(137,54,233)',
            fillRule: 'nonzero',
          }}/>
        </g>
        <g transform="matrix(1,0,0,1,-90,-82.9019)">
          <path d="M698.206,505.434C699.696,510.073 699.785,515.029 699.815,519.901C699.875,529.839 699.744,539.776 699.42,549.712C699.254,554.798 699.025,559.955 697.483,564.804C696.466,568.003 694.897,571.002 693.846,574.19C690.698,583.743 692.418,594.104 692.688,604.159C692.879,607.413 692.601,610.678 691.863,613.853C690.202,620.086 685.737,625.123 682.621,630.771C679.662,636.135 677.876,642.148 674.341,647.152C670.807,652.156 664.688,656.121 658.757,654.585C654.264,653.421 651.071,649.443 648.862,645.361C644.044,636.385 642.395,626.041 644.184,616.012C646.402,603.809 653.57,592.983 656.155,580.852C659.705,564.193 654.31,547.003 654.47,529.971C654.397,527.301 654.759,524.637 655.542,522.084C656.898,518.194 659.811,515.087 662.501,511.967C665.863,508.067 668.987,503.969 671.854,499.692C673.78,496.82 676.396,489.432 679.811,488.817C687.435,487.446 696.315,499.544 698.206,505.434Z" style={{
            fill: 'rgb(137,54,233)',
            fillRule: 'nonzero',
          }}/>
        </g>
        <g transform="matrix(1,0,0,1,-90,-82.9019)">
          <path d="M463.56,671.479C459.344,670.42 455,669.958 450.656,670.107C433.683,671.046 420.225,685.268 420.225,702.266C420.225,704.215 420.402,706.159 420.754,708.076C421.806,712.947 423.527,717.649 425.867,722.049C429.289,728.994 433.394,735.924 439.73,740.374C443.436,742.978 447.725,744.601 451.964,746.198L485.333,758.769C490.242,760.618 495.151,762.467 500.109,764.182C525.015,772.791 550.981,777.953 577.286,779.525C585.298,780.002 593.432,780.135 601.249,778.311C603.104,777.878 605.082,777.233 606.156,775.66C606.792,774.551 607.167,773.313 607.253,772.038L609.306,757.663C609.595,755.639 609.873,753.509 609.131,751.604C608.191,749.188 605.833,747.668 603.585,746.377C581.647,733.777 556.033,726.826 537.568,709.533C533.221,705.462 529.583,699.008 524.301,696.243C518.312,693.107 511.259,691.317 505.068,688.428C491.534,682.11 478.228,674.851 463.56,671.479Z" style={{
            fill: 'rgb(47,46,65)',
            fillRule: 'nonzero',
          }}/>
        </g>
        <g transform="matrix(1,0,0,1,-90,-82.9019)">
          <path d="M496.874,690.858C508.669,694.554 558.465,720.158 567,729.098C565.856,729.51 527.039,707.757 525.874,707.407C515.287,704.23 504.64,701.031 494.586,696.381C492.563,695.445 481.445,691.048 481.949,688.496C482.517,685.616 494.78,690.202 496.874,690.858Z" style={{
            fillOpacity: 0.1,
            fillRule: 'nonzero',
          }}/>
        </g>
        <g transform="matrix(1,0,0,1,-90,-82.9019)">
          <path d="M644.017,786.98C644.686,787.029 645.351,786.834 645.887,786.43C646.362,785.82 646.572,785.045 646.471,784.278C646.453,777.751 646.435,771.223 646.416,764.695C640.785,762.012 634.486,761.152 628.303,760.325L599.454,756.469C600.117,756.558 594.944,768.895 595.737,770.542C597.135,773.445 607.82,776.017 610.835,777.388C621.318,782.153 632.211,787.391 644.017,786.98Z" style={{
            fill: 'rgb(255,184,184)',
            fillRule: 'nonzero',
          }}/>
        </g>
        <g transform="matrix(1,0,0,1,-90,-82.9019)">
          <path d="M553.505,764.627C548.058,765.495 541.979,766.262 538.451,770.502C533.978,775.879 535.882,783.894 538.016,790.555C538.333,791.903 538.97,793.154 539.872,794.204C541.328,795.641 543.56,795.852 545.601,795.981C550.569,796.295 555.855,796.542 560.195,794.105C562.161,792.806 564.054,791.399 565.865,789.891C569.065,787.762 572.583,786.153 576.288,785.126C583.098,782.984 590.109,781.539 597.211,780.814C600.01,780.686 602.78,780.198 605.453,779.361C608.072,778.375 610.497,776.418 611.258,773.725C612.264,770.166 610.195,766.538 608.211,763.417C605.74,759.529 603.122,755.435 599.438,752.573C594.062,748.398 591.403,751.603 585.989,754.275C575.74,759.333 564.79,762.823 553.505,764.627Z" style={{
            fill: 'rgb(255,184,184)',
            fillRule: 'nonzero',
          }}/>
        </g>
        <g transform="matrix(1,0,0,1,-90,-82.9019)">
          <path d="M539.271,769.534L504.685,759.311C500.479,758.068 496.201,756.814 491.814,756.817C487.428,756.82 482.839,758.259 479.995,761.597C477.313,764.745 476.545,769.047 475.876,773.127L474.446,781.857C473.719,786.293 472.991,790.815 473.646,795.262C474.3,799.71 476.57,804.153 480.516,806.306C485.302,808.917 491.168,807.642 496.51,806.549C507.299,804.344 518.273,803.167 529.285,803.035C533.424,802.985 537.766,803.037 541.424,801.098C545.773,798.794 548.374,793.921 548.821,789.02C549.502,781.563 547.017,771.823 539.271,769.534Z" style={{
            fill: 'rgb(47,46,65)',
            fillRule: 'nonzero',
          }}/>
        </g>
        <g transform="matrix(1,0,0,1,-90,-82.9019)">
          <path d="M657.612,760.625C662.096,759.84 666.053,757.291 670.349,755.787C676.396,753.67 682.949,753.67 689.356,753.694C691.708,753.702 694.213,753.755 696.171,755.058C698.993,756.937 699.716,760.697 700.196,764.053L703.091,784.303C703.636,788.112 704.177,792.022 703.328,795.775C702.478,799.528 699.92,803.145 696.197,804.113C691.674,805.289 687.241,802.4 682.97,800.502C672.298,795.759 659.891,796.971 648.763,793.426C646.697,792.768 644.485,791.762 643.643,789.765C643.262,788.64 643.127,787.447 643.245,786.266C643.435,779.757 642.062,770.869 644.022,764.648C645.784,759.054 652.608,760.625 657.612,760.625Z" style={{
            fill: 'rgb(47,46,65)',
            fillRule: 'nonzero',
          }}/>
        </g>
        <g transform="matrix(1,0,0,1,-90,-82.9019)">
          <path d="M730.803,663.329C737.714,661.596 744.878,661.106 751.96,661.883C757.616,662.506 763.444,664.08 767.573,667.995C772.6,672.76 774.215,680.129 774.349,687.054C774.57,700.843 769.994,714.289 761.407,725.081C759.31,727.811 756.816,730.212 754.008,732.204C750.979,734.098 747.751,735.654 744.382,736.843L669.654,766.318C649.995,774.072 630.266,781.848 609.76,786.96C609.127,787.266 608.434,787.425 607.731,787.425C605.902,787.425 604.234,786.349 603.479,784.683C596.932,775.331 591.439,765.283 587.101,754.723C586.806,754.362 586.644,753.91 586.644,753.444C586.644,752.59 587.187,751.823 587.993,751.54L640.849,721.171C644.635,718.995 648.463,716.789 651.607,713.758C653.891,711.545 655.762,708.94 657.13,706.069C658.226,703.78 658.328,698.38 659.727,696.689C661.097,695.033 665.785,694.9 667.949,694.249C671.002,693.314 673.992,692.184 676.901,690.865C688.724,685.576 699.252,677.891 710.587,671.697C716.944,668.063 723.737,665.251 730.803,663.329Z" style={{
            fill: 'rgb(47,46,65)',
            fillRule: 'nonzero',
          }}/>
        </g>
        <g transform="matrix(1,0,0,1,-90,-82.9019)">
          <path d="M700.839,686.008C691.758,692.829 680,699.098 671,706.098C667.928,708.487 616.724,737.275 616,741.098C624.396,742.513 677.883,705.095 685.536,701.361C693.188,697.628 699.972,692.362 706.694,687.136C708.407,685.804 715.667,681.8 710.749,680.312C707.979,679.474 702.884,684.645 700.839,686.008Z" style={{
            fillOpacity: 0.1,
            fillRule: 'nonzero',
          }}/>
        </g>
        <path d="M561.025,286.538L520.595,265.361L464.764,274.024L453.212,325.043L481.967,323.937L490,305.193L490,323.628L503.268,323.117L510.969,293.277L515.782,325.043L562.95,324.08L561.025,286.538Z" style={{
          fill: 'rgb(47,46,65)',
          fillRule: 'nonzero',
        }}/>
        <rect x={465} y="391.196" width={87} height={105} style={{ fill: 'rgb(63,61,86)' }}/>
        <g transform="matrix(1,0,0,1,-90,-82.9019)">
          <path d="M513.699,656.721C515.696,659.703 518.037,662.62 521.245,664.228C525.388,666.121 530.182,665.992 534.218,663.881C538.198,661.747 541.457,658.479 543.58,654.494C547.974,646.735 548.884,637.451 548.336,628.551C547.788,619.651 545.887,610.887 545.046,602.01C544.435,595.557 544.385,589.066 544.336,582.584C544.184,581.221 544.47,579.845 545.152,578.656C546.125,577.327 547.907,576.96 549.486,576.491C558.354,573.856 564.241,565.544 568.461,557.312C571.278,551.815 573.729,545.806 573.185,539.653C572.642,533.499 568.219,527.301 562.074,526.673C557.008,526.155 552.33,529.373 548.675,532.92C539.458,541.921 533.531,553.767 531.852,566.54C531.05,572.797 531.28,579.223 529.578,585.297C525.945,598.266 514.576,606.217 508.911,617.887C502.668,630.745 506.114,645.343 513.699,656.721Z" style={{
            fill: 'rgb(255,184,184)',
            fillRule: 'nonzero',
          }}/>
        </g>
        <g transform="matrix(1,0,0,1,-90,-82.9019)">
          <path d="M680.992,607.175C678.381,613.249 673.421,618.015 667.247,620.381C663.449,621.907 659.256,622.164 655.299,621.113C651.324,620.01 648.084,617.112 646.546,613.284C645.941,611.38 645.609,609.4 645.56,607.403C644.992,598.658 645.212,589.646 648.388,581.478C649.614,578.327 651.266,575.34 652.233,572.101C653.183,568.36 653.669,564.516 653.68,560.656L654.724,536.794C654.897,535.062 654.777,533.313 654.37,531.62C653.328,528.167 650.101,525.923 647.14,523.864C639.99,518.892 632.924,513.089 629.459,505.098C628.268,502.693 627.842,499.98 628.237,497.325C629.214,492.586 633.996,489.519 638.722,488.485C640.368,488.126 642.047,487.944 643.732,487.944C655.09,487.944 664.897,496.195 666.84,507.385C667.308,510.078 667.301,512.829 667.575,515.548C668.156,521.317 669.987,526.878 671.132,532.561C673.954,546.561 672.492,561.032 675.283,575.03C677.616,586.733 686.414,594.369 680.992,607.175Z" style={{
            fill: 'rgb(255,184,184)',
            fillRule: 'nonzero',
          }}/>
        </g>
      </g>
      <g transform="matrix(1,0,0,1,-90,-82.9019)">
        <path d="M142.631,753.118L140.631,753.079C141.404,730.841 143.777,708.688 147.73,686.791C156.379,639.911 170.76,609.122 190.474,595.28L191.622,596.916C145.57,629.25 142.654,751.882 142.631,753.118Z" style={{
          fill: 'rgb(47,46,65)',
          fillRule: 'nonzero',
        }}/>
      </g>
      <g transform="matrix(1,0,0,1,-90,-82.9019)">
        <path d="M167.631,752.639L165.631,752.6C165.674,750.385 166.924,698.186 187.474,683.758L188.622,685.395C168.901,699.241 167.641,752.107 167.631,752.639Z" style={{
          fill: 'rgb(47,46,65)',
          fillRule: 'nonzero',
        }}/>
      </g>
      <circle cx={113} cy="504.196" r={10} style={{ fill: 'rgb(137,54,233)' }}/>
      <circle cx="108.048" cy="590.196" r={10} style={{ fill: 'rgb(137,54,233)' }}/>
      <g transform="matrix(1,0,0,1,-90,-82.9019)">
        <path d="M162.97,603.093C164.849,615.097 159.951,625.833 159.951,625.833C159.951,625.833 152.005,617.108 150.126,605.103C148.247,593.099 153.145,582.363 153.145,582.363C153.145,582.363 161.09,591.089 162.97,603.093Z" style={{
          fill: 'rgb(47,46,65)',
          fillRule: 'nonzero',
        }}/>
      </g>
      <g transform="matrix(1,0,0,1,-90,-82.9019)">
        <path d="M195.657,632.246C184.164,636.189 172.738,633.236 172.738,633.236C172.738,633.236 179.946,623.892 191.439,619.95C202.932,616.008 214.358,618.96 214.358,618.96C214.358,618.96 207.15,628.304 195.657,632.246Z" style={{
          fill: 'rgb(47,46,65)',
          fillRule: 'nonzero',
        }}/>
      </g>
      <g transform="matrix(1,0,0,1,-90,-82.9019)">
        <path d="M196.026,708.408C190.825,710.041 185.285,710.28 179.962,709.101C186.854,700.327 198.307,696.398 209.134,699.095C205.656,703.293 201.135,706.505 196.026,708.408Z" style={{
          fill: 'rgb(47,46,65)',
          fillRule: 'nonzero',
        }}/>
      </g>
    </svg>
  );
};

export default Person;
