import React from 'react';
import './BookCount.scss';
import classnames from 'classnames';

interface BookCountProps {
  n: number,
  total?: number,
  size?: 'small',
  className?: string,
  slot?: string,
}

const BookCount: React.FC<BookCountProps> = ({ n, total, size, className, slot }) => {
  const suffix = total === 1 ? 'book' : 'books';
  return (
    <span slot={slot} className={classnames('book-count', className, { small: size === 'small' })}>
      {n}{typeof total === 'number' ? ` / ${total}` : ''} {suffix}
    </span>
  );
};

export default BookCount;
