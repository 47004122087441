import {
  IonBackButton,
  IonButton,
  IonContent,
  IonFooter,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSpinner,
  IonText,
} from '@ionic/react';
import type { SyntheticEvent } from 'react';
import React, { useState } from 'react';

import './LoginPage.scss';
import { Link } from 'react-router-dom';

import Header from '../components/ui/Header';
import { AnalyticsEvent, logEvent } from '../services/analytics';
import { login } from '../services/authentication';
import { URLs } from '../urls';

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const isFormValid = email && password;

  const handleFormSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMessage('');

    if (!isFormValid) {
      return;
    }

    setIsSubmitting(true);
    const errorMessage = await login(email, password);

    setIsSubmitting(false);
    if (errorMessage) {
      setErrorMessage(errorMessage);
    } else {
      logEvent(AnalyticsEvent.AuthLogin, { provider: 'password' });
      window.location.replace(URLs.tabReading());
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <IonPage id="login-page">
        <Header screenName="Login" startSlot={<IonBackButton defaultHref={URLs.welcome()} text="Welcome" />} />

        <IonContent fullscreen className="ion-padding-bottom">
          <div className="ion-margin ion-padding-vertical">
            <h1>Welcome back</h1>
            <IonText color="medium">Sign in with the email and password you used to create your account.</IonText>
          </div>

          {errorMessage && (
            <div className="ion-margin">
              <IonText color="danger">{errorMessage}</IonText>
            </div>
          )}

          <IonList className="ion-padding-horizontal">
            <IonItem className="ion-no-padding ion-margin-vertical">
              <IonLabel position="stacked">Email Address</IonLabel>
              <IonInput
                required
                name="email"
                placeholder="name@example.com"
                type="email"
                value={email}
                onIonChange={(e) => setEmail(e.detail.value ?? email)}
              />
            </IonItem>

            <IonItem className="ion-no-padding ion-margin-vertical">
              <IonLabel position="stacked">Password</IonLabel>
              <IonInput
                required
                name="password"
                type="password"
                placeholder="Password"
                value={password}
                onIonChange={(e) => setPassword(e.detail.value ?? password)}
                minlength={7}
              />
            </IonItem>
            <Link to={URLs.forgotPassword()} className="ion-padding-vertical ion-margin-bottom">
              Forgot Password?
            </Link>
            <div className="ion-hide">
              <input type="submit" />
            </div>
          </IonList>
        </IonContent>
        <IonFooter className="ion-padding-horizontal ion-no-border" translucent={false}>
          <IonButton expand="block" type="submit" disabled={!isFormValid || isSubmitting}>
            {isSubmitting ? <IonSpinner /> : 'Sign In'}
          </IonButton>
        </IonFooter>
      </IonPage>
    </form>
  );
};

export default LoginPage;
