import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonProgressBar,
  IonSelect,
  IonSelectOption,
  useIonViewWillEnter,
} from '@ionic/react';
import { getDownloadURL, ref, uploadString } from 'firebase/storage';
import type { SyntheticEvent } from 'react';
import React, { useRef, useState } from 'react';

import type { Book } from '../../data/books';
import { BookStatus, createBook, newBook, statusLabel, updateBook } from '../../data/books';
import { getUid } from '../../services/authentication';
import { firebaseStorage } from '../../services/storage';
import type { ResizeImageResult } from '../../util/resize-img';
import resizeImage from '../../util/resize-img';

import BookCover from './BookCover';
import BookCoverEditModal from '../../modals/BookCoverEditModal';
import { Camera, CameraResultType } from '@capacitor/camera';

interface EditBookProps {
  book: Book;
  onFinish: (bookId: string) => void;
  router: HTMLIonRouterOutletElement | null;
}

const EditBookForm: React.FC<EditBookProps> = ({ book, onFinish, router }) => {
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [cropModalReady, setCropModalReady] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [croppedCoverImageUri, setCroppedCoverImageUri] = useState<string>();
  const [selectedCoverImageResult, setSelectedCoverImageResult] = useState<ResizeImageResult>();
  const [formKey, setFormKey] = useState<string>();
  const formRef = useRef<HTMLFormElement>(null);
  // Form values
  const [iStatus, setIStatus] = useState<BookStatus>(book.status);
  const [iTitle, setITitle] = useState<string>(book.title);
  const [iAuthor, setIAuthor] = useState<string>(book.authors[0] || '');
  const [iPageCount, setIPageCount] = useState<number>(book.pageCount);
  const [iImage, setIImage] = useState<string | null>(book.image);

  const labelType = 'stacked';
  const itemClassName = 'ion-margin-end ion-margin-top';

  // HACK: Clear form values on page load by setting a random key on it
  useIonViewWillEnter(() => {
    setFormKey(`form:${Math.random()}`);
  });

  const handleFormSubmit = async (e?: SyntheticEvent<HTMLFormElement>) => {
    e?.preventDefault();

    const patch = {
      title: iTitle,
      authors: [iAuthor],
      pageCount: iPageCount,
      status: iStatus,
    };

    if (!iTitle) {
      alert("Title is required");
      return;
    }

    setSaving(true);

    try {
      let bookId = book.id;
      if (bookId === '__UNSAVED__') {
        bookId = await createBook(newBook(patch));
      } else {
        await updateBook(book.id, patch);
      }

      // Update cover image if it's changed
      if (croppedCoverImageUri) {
        const key = `covers/${getUid().slice(0, 7)}/${bookId}.${new Date().toISOString()}`;
        const o = ref(firebaseStorage, key);
        const smallerImg = await resizeImage(croppedCoverImageUri, 500 * 0.6, 500);
        await uploadString(o, smallerImg.uri, 'data_url');
        const image = await getDownloadURL(o);
        await updateBook(bookId, { image });
      }

      onFinish(bookId);
    } catch (err) {
      console.log('[edit_book] Failed to save book', err);
    }

    setSaving(false);
  };

  const handlePickImage = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.DataUrl,
      saveToGallery: false,
      correctOrientation: true,
    });

    // NOTE: There's no way to catch cancellation here in Ionic right now. Once there is, we
    //  can correctly show loading spinner.
    if (image.dataUrl) {
      const result = await resizeImage(image.dataUrl, 700 * 0.6, 700);
      setSelectedCoverImageResult(result);
      setShowCropModal(true);
    }
  };

  return (
    <>
      {saving && <IonProgressBar type="indeterminate" slot="fixed" />}
      <form key={formKey} ref={formRef} onSubmit={handleFormSubmit} className="ion-margin-top ion-padding-bottom">
        <IonItem className={itemClassName} lines="none">
          <div className="ion-padding-end">
            <BookCover
              className="ion-margin-vertical"
              style={{ height: 140, width: 140 * 0.65, marginLeft: 5 }}
              book={newBook({ image: iImage ? iImage : '', status: iStatus })}
            />
          </div>
          <IonButton onClick={() => handlePickImage()} color="tertiary" size="small" fill="outline">
            Select Cover
          </IonButton>
        </IonItem>
        <IonItem className={itemClassName}>
          <IonLabel position={labelType}>Title</IonLabel>
          <IonInput
            required
            type="text"
            autocapitalize="words"
            placeholder="Enter book title"
            onIonChange={(e) => setITitle(e.detail.value ?? '')}
            value={iTitle}
          />
        </IonItem>
        <IonItem className={itemClassName}>
          <IonLabel position={labelType}>Author</IonLabel>
          <IonInput
            type="text"
            autocapitalize="words"
            placeholder="Enter author name"
            onIonChange={(e) => setIAuthor(e.detail.value ?? iAuthor)}
            value={iAuthor}
          />
        </IonItem>
        <IonItem className={itemClassName}>
          <IonLabel position={labelType}>Page Count</IonLabel>
          <IonInput
            type="number"
            inputmode="numeric"
            placeholder="Enter number of pages"
            onIonChange={(e) => setIPageCount(parseInt(e.detail.value ?? '0'))}
            value={iPageCount}
          />
        </IonItem>
        <IonItem className={itemClassName}>
          <IonLabel position={labelType}>Status</IonLabel>
          <IonSelect value={iStatus} onIonChange={(e) => setIStatus(e.detail.value)}>
            {Object.values(BookStatus).map((s) => (
              <IonSelectOption key={s} value={s}>
                {statusLabel(s)}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        <div className="ion-margin ion-padding-top">
          <IonButton expand="block" disabled={saving} onClick={() => handleFormSubmit()}>
            {book.id === '__UNSAVED__' ? 'Create' : 'Save'} Book
          </IonButton>
        </div>
      </form>
      <IonModal
        isOpen={showCropModal && !!selectedCoverImageResult}
        presentingElement={router ?? undefined}
        onDidPresent={() => setCropModalReady(true)}
        onDidDismiss={() => {
          setShowCropModal(false);
          setCropModalReady(false);
        }}
      >
        {selectedCoverImageResult ? (
          <BookCoverEditModal
            ready={cropModalReady}
            img={selectedCoverImageResult}
            onCancel={() => setShowCropModal(false)}
            onDone={(uri: string) => {
              setCroppedCoverImageUri(uri);
              setIImage(uri);
              setShowCropModal(false);
            }}
          />
        ) : (
          'No image was'
        )}
      </IonModal>
    </>
  );
};

export default EditBookForm;
