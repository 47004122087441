import { IonAlert, IonButton } from '@ionic/react';
import type { ReactNode} from 'react';
import React, { useState } from 'react';
import './ButtonSelect.scss';
import classnames from 'classnames';

interface ButtonSelectProps {
  header: string,
  children: ReactNode,
  options: {
    name: string,
    label: string,
    type: 'text' | 'radio' | 'checkbox',
    value?: any,
    checked?: boolean,
    handler?: () => any,
  }[],
  fill?: 'outline',
  className?: string,
  buttons?: boolean,
  closeOnSelect?: boolean,
  size?: 'small' | 'large' | 'default',
  color?: string,
  onComplete?: (values: any) => any,
  expand?: 'block',
  okLabel?: string,
}

const ButtonSelect: React.FC<ButtonSelectProps> = ({
  closeOnSelect,
  className,
  buttons,
  children,
  header,
  size,
  color,
  options,
  onComplete,
  expand,
  fill,
  okLabel,
}) => {
  const [ open, setOpen ] = useState<boolean>(false);
  return (
    <>
      <div className={classnames('button-select', className, { open: open })} onClick={e => e.stopPropagation()} style={{
        display: expand === 'block' ? 'block' : 'inline-block',
      }}>
        <IonButton size={size} color={color} onClick={() => setOpen(true)} expand={expand} fill={fill}>
          {children}
          <div className="select-icon" slot="end">
            <div className="select-icon-inner"/>
          </div>
        </IonButton>
      </div>

      <IonAlert
        isOpen={open}
        onDidDismiss={() => setOpen(false)}
        header={header}
        inputs={options.map(o => ({
          ...o,
          handler: async () => {
            if (closeOnSelect) {
              // Close after a slight delay to let the user have the visual
              // feedback of the option switching before we close it
              setTimeout(() => setOpen(false), 200);
            }
            if (o.handler) await o.handler();
          },
        }))}
        cssClass="button-select-alert"
        buttons={buttons ? [
          { text: 'Cancel', role: 'cancel' },
          { text: okLabel || 'Ok', handler: onComplete },
        ] : undefined}
      />
    </>
  );
};

export default ButtonSelect;
