import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import type { BookList } from '../data/book-lists';
import { getBookList } from '../data/book-lists';
import queryClient from '../query-client';

export default function useBookList(id: string): UseQueryResult<BookList, Error> {
  return useQuery(['book_lists', id], () => getBookList(id), {});
}

export function setBookListCache(bookList: BookList): void {
  queryClient.setQueryData(['book_lists', bookList.id], bookList);
}
