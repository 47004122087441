import { IonIcon, IonLabel, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';

import { book, library, list, settings } from 'ionicons/icons';
import type { ReactNode } from 'react';
import React from 'react';

import { TabRoot, tabRootName, URLs } from '../../urls';
import './TabBar.scss';

interface Props {
  children: ReactNode;
}

const TabBar: React.FC<Props> = ({ children }) => {
  return (
    <IonTabs className="tab-bar">
      {/* Tab bar */}
      <IonTabBar slot="bottom" translucent={false}>
        <IonTabButton tab="home" href={URLs.tabReading()}>
          <IonIcon icon={book} />
          <IonLabel>{tabRootName(TabRoot.Reading)}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="books" href={URLs.tabLibrary()}>
          <IonIcon icon={library} />
          <IonLabel>{tabRootName(TabRoot.Library)}</IonLabel>
        </IonTabButton>
        {/*<IonTabButton className="fabby-tab">*/}
        {/*  <div className="wrapper">*/}
        {/*    <div className="background-circle"/>*/}
        {/*    <div className="background-mask"/>*/}
        {/*    <IonFab>*/}
        {/*      <IonFabButton routerLink={URLs.search()}>*/}
        {/*        <IonIcon icon={add}/>*/}
        {/*      </IonFabButton>*/}
        {/*    </IonFab>*/}
        {/*  </div>*/}
        {/*</IonTabButton>*/}
        <IonTabButton tab="lists" href={URLs.tabLists()}>
          <IonIcon icon={list} />
          <IonLabel>{tabRootName(TabRoot.Lists)}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="settings" href={URLs.tabSettings()}>
          <IonIcon icon={settings} />
          <IonLabel>{tabRootName(TabRoot.Settings)}</IonLabel>
        </IonTabButton>
      </IonTabBar>

      {children}
    </IonTabs>
  );
};

export default TabBar;
