import { isPlatform } from '@ionic/react';
import { useEffect, useState } from 'react';

import { getCustomerInfo, getSubscriberWeb } from '../services/billing';

export interface CustomerInfoSlim {
  managementURL: string | null;
  isActive: boolean;
  store: string;
}

export default function useCustomerInfo(): CustomerInfoSlim | undefined {
  const [info, setInfo] = useState<CustomerInfoSlim>();
  useEffect(() => {
    if (!isPlatform('capacitor')) {
      getSubscriberWeb()
        .then(setInfo)
        .catch((err) => console.error('[use-purchaser-info] Error', err));
    } else {
      getCustomerInfo()
        .then(setInfo)
        .catch((err) => console.error('[use-purchaser-info] Error', err));
      window.addEventListener('onPurchaserInfoUpdated', async (info: any) => {
        setInfo(await getCustomerInfo(info));
      });
    }
  }, []);

  return info;
}
