import {
  IonBackButton,
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSpinner,
  IonText,
} from '@ionic/react';
import type { SyntheticEvent } from 'react';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import './ForgotPasswordPage.scss';
import Header from '../components/ui/Header';
import { AnalyticsEvent, logEvent } from '../services/analytics';
import { resetPassword } from '../services/authentication';
import { URLs } from '../urls';
import minimumTime from '../util/minimum-time';

const ForgotPasswordPage: React.FC = () => {
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const isFormValid = !!email;

  const handleFormSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMessage('');

    if (!isFormValid) {
      return;
    }

    let message;
    setIsSubmitting(true);
    await minimumTime(async () => {
      message = await resetPassword(email);
    }, 1000);

    setIsSubmitting(false);
    if (message) {
      setErrorMessage(message);
    } else {
      logEvent(AnalyticsEvent.AuthResetPassword);
      setSubmitted(true);
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <IonPage id="login-page">
        <Header screenName="ForgotPassword" startSlot={<IonBackButton defaultHref={URLs.login()} text="Sign in" />} />

        <IonContent fullscreen={false}>
          {submitted ? (
            <>
              <div className="ion-margin ion-padding-vertical">
                <h1>Check Your Email</h1>
                <IonText color="medium">
                  Instructions sent to <code>{email || 'your inbox'}</code>. Once you're done, you can sign in.
                </IonText>
              </div>
              <div className="ion-padding-horizontal">
                <IonButton
                  expand="block"
                  type="button"
                  disabled={!isFormValid || isSubmitting}
                  onClick={() => history.goBack()}
                >
                  Back to Login
                </IonButton>
              </div>
            </>
          ) : (
            <>
              <div className="ion-margin ion-padding-vertical">
                <h1>Reset Password</h1>
                <IonText color="medium">Enter the email associated with your account to reset your password.</IonText>
              </div>

              {errorMessage && (
                <div className="ion-margin">
                  <IonText color="danger">{errorMessage}</IonText>
                </div>
              )}

              <IonList className="ion-padding-horizontal">
                <IonItem className="ion-no-padding ion-margin-vertical">
                  <IonLabel position="stacked">Email Address</IonLabel>
                  <IonInput
                    required
                    name="email"
                    placeholder="name@example.com"
                    type="email"
                    value={email}
                    onIonChange={(e) => setEmail(e.detail.value ?? email)}
                  />
                </IonItem>
              </IonList>

              <div className="ion-padding-horizontal ion-padding-top">
                <IonButton expand="block" type="submit" disabled={!isFormValid || isSubmitting}>
                  {isSubmitting ? <IonSpinner /> : 'Reset Password'}
                </IonButton>
              </div>
            </>
          )}
        </IonContent>
      </IonPage>
    </form>
  );
};

export default ForgotPasswordPage;
