import { IonItem, IonList } from '@ionic/react';
import React from 'react';
import './HomeBooksSection.scss';
import { Link } from 'react-router-dom';

import type { Book } from '../../data/books';

import BookGridItem from './BookGridItem';
import BookListItem from './BookListItem';

import classnames from 'classnames';

export interface HomeBooksSectionProps {
  books: Book[];
  onBookClick: (book: Book) => any,
  to?: string,
  maxItems?: number;
  startIndex?: number;
  title?: string;
  size?: 'small' | 'tiny';
  layout?: 'list' | 'scroll-x';
  className?: string,
}

const HomeBooksSection: React.FC<HomeBooksSectionProps> = ({
  onBookClick,
  to,
  size,
  layout,
  maxItems,
  startIndex,
  books,
  title,
  className,
}) => {
  startIndex = startIndex || 0;
  maxItems = maxItems || 99;

  // Don't show anything if no books
  if (books.length <= startIndex) {
    return null;
  }

  if (layout === 'scroll-x') {
    return (
      <div className={classnames('home-books-section', `size-${size}`, className)}>
        {title && (
          <IonItem className="ion-margin-bottom heading">
            <h2 className="ion-no-margin">{title}</h2>
            {to && (
              <Link slot="end" to={to} style={{ textDecoration: 'none' }}>
                See All
              </Link>
            )}
          </IonItem>
        )}
        <div className="horizontal">
          {books.slice(startIndex, startIndex + maxItems).map(b => (
            <BookGridItem key={b.id} book={b} onClick={onBookClick} size={size}/>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={classnames('home-books-section', `size-${size}`, className)}>
      {title && (
        <IonItem className="ion-margin-bottom heading">
          <h2 className="ion-no-margin">{title}</h2>
          {to && (
            <Link slot="end" to={to} style={{ textDecoration: 'none' }}>
              See All
            </Link>
          )}
        </IonItem>
      )}
      <IonList lines="none">
        {books.slice(startIndex, startIndex + maxItems).map(b => (
          <BookListItem deletable key={b.id} book={b} size={size} onClick={onBookClick}/>
        ))}
      </IonList>
    </div>
  );
};

export default HomeBooksSection;
